#picklists-panel.contact-relations-table-v2 {
  height: calc(100vh - 395px) !important;
  border-radius: 8px;
  padding-top: 2.5rem;
}

.import-relations-title {
  margin: 0.8rem 0;
  font-size: 1.5em;
  font-weight: bold;
}

.attribute-main {
  background-color: var(--white);
  flex-grow: 1;
  border-radius: .3rem .3em 8px 8px !important;
  line-height: 1.2;
  position: relative;
  height: calc(100% - 67px);

  .info-header {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
    padding-left: 10px;
  }

  .info-text {
    font-size: 15px;
    color: var(--grayText);
  }

  &__actions {
    margin-top: 15px;
    display: flex;

    .tooltip-container {
      display: flex;
      align-items: center;
    }
  }

  .selection {
    height: auto !important;
  }

  &__dropdown {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 10px;
  }

}

.swal-content-left {
  text-align: left !important;
}
.imported-relation-title {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.table-text {
  font-size: 13.5px !important;
}
.button-text {
  font-size: 14px !important;
  margin-bottom: 1rem !important;
}

.main-picklist-div.picklist_wrapper {
  height: 488px !important;
  overflow: auto !important;
}