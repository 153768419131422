.new-filter-box-main-container {
  display: flex;
  //flex-direction: row-reverse;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  font-family: 'Inter';
  color: #181818;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;

  .new-filters {
    min-height: 4rem;
    width: 100%;
    max-width: 100%;
    min-width: 88%;
    display: flex;
    flex-flow: column;
    border: 1px solid #dddbda;
    padding: 16px 16px 0px 16px;
    border-radius: 0px 8px 8px 0px;
    height: 100%;
  }

  .filters-groups-container {
    min-height: 14rem;
    width: 100%;
    max-width: 100%;
    min-width: 88%;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    margin-bottom: 2.5rem;

    .filter-logic {
      display: flex;
      align-items: center;
      border-radius: 8px 0px 0px 8px;
      width: 60px;
      font-size: 14px;
      border: 1px solid #dddbda;

      div {
        overflow: hidden;

        .filter-operator {
          height: 33px;
          font-size: 14px;
          line-height: 21px;
          font-family: var(--primaryFont);
          color: #495d7a;
          border: none;
          background: none;
          padding: 6px 12px 6px 12px;
          border-radius: 5px;
        }

        .filter-operator:focus {
          box-shadow: none;
        }
      }

      .slds-select_container {
        width: 100%;
      }
    }

    .filter-line-group {
      display: flex;
      flex-direction: row-reverse;
    }

    .has-no-children .filter-box-main-container .filters {
      height: unset;
    }

    .has-no-children {
      height: unset;
    }
  }

  // Where to drop information line
  .filter-drop {
    line-height: 2rem;
    padding: 1rem;
    color: var(--grayBorder);
    border: .1rem dashed transparent;
    transition: .3s all !important;
    flex: 1 1 auto;
  }

  .add-filter-manually-button {
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    color: #0176D3;
  }
}

// Transparent border for onDragEnter
.filter-container-dropzone,
.filterline-dropzone {
  border: 2px dashed transparent;
  // transition: .3s all !important;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
}

// Color border for onDragEnter
.bordernow {
  border: 2px dashed var(--primaryRed);
}