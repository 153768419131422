.available-fields-v2 {
  grid-column: 1/6;
  padding-left: 7px !important;
  font-family: "SF Pro", Arial, sans-serif !important;
  padding-right: 5px !important;

  &.false,
  &.mt-2-per {
    width: 100%;
    grid-row: 1;
    top: 0.5%;
    }


  &.mt-2-per {
    height: fit-content;
  }

  &.true{
    .upgrade-badge-icon{
      z-index: 9;
      bottom: auto;
      margin-top: -20px;
      display: block;
      left: 7em;
    }
  }

  .filter-set-btn{
    .slds-popover_tooltip{
      position: absolute;
      max-width: 6.5rem;
      width: max-content;
      left: 3.5rem !important;

      .slds-popover__body {
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }
  }

  .make-space-for-scroll {
    margin-bottom: 0 !important;

    &.disabled-cv{
      cursor: pointer;
      .tooltip-container{
        pointer-events: none;
        display: none;
      }
    }
  }

  .available-extensions-header{
    display: flex;
    .section-title {
      white-space: nowrap;
      padding: .8rem 0;
      border-radius: .2rem;
      color: #495D7A;
      font-size: 15px;
      font-family: Inter;
      font-weight: 700;
      line-height: 24px;
      .guidance-tip-container{
        display: inline-block;
        position: absolute;
        top: 0;
        margin: 0;
      }
    }

    .unavailable-feature-tooltip{
      display: inline-block;
      margin-left: 5px;
      margin-top: 15px;
      position: relative;
    }
  }

  .section-title-v2 {
    white-space: nowrap;
    padding: .8rem 0;
    border-radius: .2rem;
    color: #495D7A;
    font-size: 15px;
    font-family: "SF Pro", Arial, sans-serif !important;
    font-weight: 700;
    line-height: 24px;

    .guidance-tip-container{
      display: inline-block;
      position: absolute;
      top: 0;
      margin: 0;
    }
  }

  &_input_wrapper {
    display: flex;
    border: 1px solid var(--grayBorder);
    border-bottom: none;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-width: thin;
    overflow: hidden;

    .disabled-overlay-cv{
      background: var(--disabledGrayOverlay);
      position: absolute;
      width: calc(100% - 10px);
      height: 36px;
    }

    input {
      width: 100%;
      min-width: 0;
      padding: .2rem .2rem .2rem 1rem;
      outline: none;
      border: none;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;

      &::placeholder {
        color: var(--blackOpacity50);
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: .7rem .8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: .25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  &_available_collections {
    overflow-x: hidden;
    min-height: 15rem;
    max-height: 15rem;
  }
}

.disabled {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: flex-start;
  position: -webkit-sticky;
  top: 0;
  cursor: default!important;
}

.h-17rem {
  height: 17.2rem!important;
}

.filter-sets-tab {
  display: flex;
}

.mr-4px {
  margin-right: 4px;
}

.filter-search-v2 {
  width: 98% !important;
}

.button-group-tde-text {
  font-family: "SF Pro", Arial, sans-serif !important;
}

.filters-group-buttons {
  margin-right: 50px !important;
}

.available-filters-container-v2 {
  margin-top: 10px !important;
  margin-left: -3px !important;
}
.related-field-container {
  height: auto !important;
}

