.prio-dedup-mode {
    .slds-form-element__legend{
        margin-bottom: .4rem;
    }
    .slds-form-element__control {
        .slds-radio_button-group {
            display: flex;
            span {
                flex: 1;
                label {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    p {
        font-size: .9rem;
        margin-top: .2rem;
    }

    .mode-container {
        display: flex;
        align-items: center;
        flex-flow: row;
    }
}

.blue-tab {
    background-color: #0176d3;
    color: var(--slds-g-color-neutral-base-100, #fff);
}