.selectedTF-info-v2 {
  width: 100%;
  margin-top: 1rem;
  padding-left: 9.5px;
  padding-right: 16px;
  padding-top: 5px;

  .box-STF-icons {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;

    .automap {
      margin-right: .2rem;
    }

    & * {
      button {
        border-radius: .25rem;
      }
    }

    .slds-dropdown_actions {
      position: absolute;
      display: none;

      &.active {
        display: block;
        top: 2rem;
      }
    }
  }

  .des-lib-select-tool {
    width: 30%;
    display: flex;

    .option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    .dropdown-icon {
      margin-left: 10px;
    }
  }

  .selection-targetde-dropdown{
    .slds-dropdown__item{
      .slds-truncate{
        text-overflow: clip;
      }
      .select-target-de-dropdown-option-wrapper{
        display: inline;
        width: 100%;

        &.disabled-option{
          cursor: not-allowed;
        }

        .des-tooltip{
          display: contents;

          .slds-tooltip-trigger{
            display: inline-flex;
            width: 100%;
            margin-left: 2px;
            margin-top: 2px;
          }
          .slds-popover{
            position: absolute;
            right: inherit;
            left: 4.8rem !important;
            max-width: 9rem;
            width: max-content;

            .slds-popover__body {
              word-wrap: break-word;
              overflow-wrap: break-word;
              hyphens: auto;
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .des-tooltip {
    position: absolute;
    z-index: 1;
    margin-left: 1rem;
  }

  .toast-position {
    position: relative !important;
    transform-origin: top;
  }

  .toast-mapped {
    position: relative;
    z-index: 1;
    z-index: 999;
    opacity: 1;
    transform: translate(0%, 0%) rotateX(0deg);
    transition: .3s transfrom, .5s;
  }

  .toast-none {
    opacity: 0;
    transform: translate(0%, -100%) rotateX(180deg);
    z-index: -999;
  }

  .slds-button_icon-inverse {
    transform: translate(1rem, -0.4rem) !important;
    width: 3.5rem;
  }

  .slds-icon-utility-success {
    margin: 0 0.75rem 0 0;
    align-self: center;
  }

  .slds-notify_toast {
    height: 2rem;
    padding: 0;
  }
}

.selectedTF-field_names {
  display: flex;
  justify-content: space-between;
  padding: .8rem 0;
  color: var(--black);
  border-radius: .2rem;
  margin: .2rem;
  margin-top: 1rem;
  font-weight: 600;
  transform: translateX(1rem);

  div {
    width: 50%;
  }

  div:nth-child(2) {
    transform: translateX(1rem);
  }
}

.selectedTF-fields-v2 {

  .target-collection-row {
    display: flex;
    margin: .6rem;
    justify-content: center;
    align-items: center;
    border-radius: .25rem;



    .trash {
      color: var(--primaryRed);
    }

    .target-collection-dropzone {
      width: 107%;
      padding: .6rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      transition: .3s all;
      position: relative;

      .empty-for-dropzone {
        width: 100%;
        height: 102%;
        position: absolute;
        left: 0px;
        top: 2px;
        background: transparent;
        height: 2.5rem;
      }

      .drag-field-v2 {
        background-color: var(--white);
        width: 100%;
        position: absolute;
        top: -5.5px;
        left: -6px;
        height: 112%;
        width: 101%;
        display: flex;
        cursor: default;
        justify-content: flex-start;
        border-radius: 6px;
        border: 1px #DDDBDA solid;

        &:hover {
          background-color: var(--white) !important;
          color: var(--blackLabel);
        }

        &_wrapper {
          height: 123%;
          padding-left: .5rem;
          transform: translateY(-.2rem);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: default;

          button {
            margin-left: 1rem;
          }
        }

        div {
          .field-label {
            padding-left: .5rem;
            line-height: 2rem;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #495D7A;
            font-size: 14px;
            font-family: "SF Pro", Arial, sans-serif !important;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }

      .drag-field-v3 {
        background-color: var(--white);
        width: 100%;
        position: absolute;
        top: -5.5px;
        left: -6px;
        height: 90%;
        width: 100%;
        display: flex;
        cursor: default;
        justify-content: flex-start;
        border-radius: 6px;
        border: 1px #DDDBDA solid;

        &:hover {
          background-color: var(--white) !important;
          color: var(--blackLabel);
        }

        &_wrapper {
          height: 123%;
          padding-left: .5rem;
          transform: translateY(-.2rem);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: default;

          button {
            margin-left: 1rem;
          }
        }

        div {
          .field-label {
            padding-left: .5rem;
            line-height: 2rem;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #495D7A;
            font-size: 14px;
            font-family: "SF Pro", Arial, sans-serif !important;
            font-weight: 500;
            line-height: 24px;
            margin-top: -5px;
          }
        }
      }
    }

    .target-collection-field-v2 {
      width: 100%;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 33px;
      border: 1px solid var(--lightGrayColdBorder);
      background-color: var(--white);
      margin-top: -8px;
      border-radius: .25rem;
      background: #F4F7FB;
      border-radius: 6px;
      border: 1px #DDDBDA solid;
      color: #495D7A;
      font-size: 14px;
      font-family: "SF Pro", Arial, sans-serif !important;
      font-weight: 500;
      line-height: 19.50px;
      word-wrap: break-word;

      .span-target-de-field-name {
        flex: 1;
      }

      .span-field-info-button {
        margin-right: 1px;

        &.with-2-icons {
          margin-right: 8px;
        }

        span {
          margin-right: .3rem;
          transform: translateY(-.09rem);
          cursor: pointer;

          svg {
            &:hover {
              color: var(--lightOrangeBorder);
            }
          }
        }

        i {
          margin-right: .3rem;

          &.primary-key-icon {
            color: var(--yellowOrangePrimaryKey);
            cursor: default;
          }

          &.encrypted-lock-icon {
            color: var(--primaryOrange);
            cursor: default;
          }

          &.edit-info-icon {
            &:hover {
              color: var(--primaryOrange);
            }
          }
        }
      }

      .status-warning {
        color: var(--primaryOrange);
        cursor: pointer;
      }

      .status-error {
        color: var(--primaryRed);
        cursor: pointer;
      }
    }
  }
}

// new one
.target-de-fields-dropzone {
  height: 5.5rem;
  color: var(--grayBorder);
}

.edit-target-field-icon {
  display: inline;
  margin-left: 7px;
  margin-right: 7px;
}

.target-de-update-fields {
  width: 24rem !important;
  padding-top: 3px;
}

.delete-matched-fields {
  width: 3.8rem;
}

.col-md-11 {
  margin-top: 8px;
}

.disable-div {
  pointer-events: none;
  opacity: 0.4;
}

// swal content
.swalContent {
  display: flex;
  align-items: center;
}

// field-info-row
.field-info-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  min-height: 28px;

  &-left {
    width: 9rem;
    text-align: left;
    margin-top: .26rem;
  }

  &-right {
    width: 11rem;
    text-align: left;
    margin-top: .26rem;

    .slds-form-element {
      .slds-form-element__control {
        input {
          width: 11rem;
        }
      }
    }
  }
}

.overwrite-warning {
  border-radius: .5rem;
  padding: 1.2rem;
  background-color: #c4e9f7;
  margin: 1rem;
  font-size: 15px;

  .input-date-looks-like-LDS {
    background-color: var(--white);
    border: 1px solid var(--lightGrayBorder);
    border-radius: 0.25rem;
    width: 100%;
    -webkit-transition: border 0.1s linear, background-color 0.1s linear;
    transition: border 0.1s linear, background-color 0.1s linear;
    display: inline-block;
    padding: 0 .1rem 0 0.75rem;

    &:active,
    &:focus {
      outline: 0;
      border-color: var(--focusBlue);
      background-color: var(--white);
      -webkit-box-shadow: 0 0 3px var(--primaryBlue);
      box-shadow: 0 0 3px var(--primaryBlue);
    }
  }
}

.disable-data-action-radio-container {
  opacity: 0.4;
}

.warning-data-action-v2 {
  transform: translate(1rem, 1rem);
  margin: -0.1rem 0 0.5rem -3px;
  width: calc(100% - 2rem + 3px);
  padding: 10px;
  background: #F4F7FB;
  border-radius: 7px;
  margin-bottom: 1rem !important;

  .change-data-action {
    margin-left: 2px;
    color: var(--darkenBlue) !important;
    font-size: 14px;
    font-family: "SF Pro", Arial, sans-serif !important;
    font-weight: 400;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.indication-message {
  transform: translate(1rem, 1rem);
  justify-content: center;
  margin: -0.1rem 0 0.5rem 0;
  width: calc(100% - 1.75rem);
}

.target-de-group-button {
  margin-right: 2px;
  margin-left: 4px;
}

.pointer-event-none {
  pointer-events: none;
}

.targetDE-automap-confirm {

  &::after {
    width: 16px !important;
    height: 16px !important;
    margin-left: 0.4rem !important;
    margin-top: 0.3rem !important;
    border: 3px solid var(--white) !important;
    border-right-color: transparent !important;
  }
}

.automap-spinner {
  position: absolute !important;
  left: 1rem;
  top: 0;

  .slds-spinner__dot-b,
  .slds-spinner__dot-a {
    transform: rotate(60deg);
    transform-origin: 50% 0%;
  }
}

#map-button {
  padding-left: 2.2rem;
  height: 2.25rem !important;
  line-height: 1.875rem;
}

.mapping-loader {
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  width: -moz-fit-content !important;
  height: 0em !important;
  margin: 0em !important;
  -webkit-animation: none !important;
  animation: none !important;
  border-width: 0 !important;
}

.targetDE-automap-cancel {

  &:disabled {
    background: var(--disabledGray) !important;
    border-color: var(--disabledGray) !important;
    color: var(--white) !important;
  }
}

.des-lib-toolbar {
  margin-right: 30px;
  width: 5rem;
}

.des-lib-target-de-fields {
    background: white;
    border-radius: 11px;
    border: 1px #DDDBDA solid;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    display: "flex";
    flex-direction: column;
    height: 520px;
    margin: 10px;
    flex: 1 1 auto;
    position: relative;
}

.des-lib-target-de-fields-container {
  height: 436px;
  border-radius: 7px;
  overflow: auto;
  border: 1px #DDDBDA solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: "inline-flex";
  margin: 10px;
  flex: 1;
  overflow-y: auto;
}

.des-lib-target-de-fields {
  .header-container {
    background: #F4F7FB;
    border: 1px #DDDBDA solid;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: "inline-flex"
  }
}

.des-lib-target-fields-container {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.des-lib-target-fields-wrapper {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
}

.des-lib-target-field {
  flex: 1 1 0;
  height: 41px;
  padding: 12px 16px;
  background: #F4F7FB;
  border: 1px solid #DDDBDA;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  border-top: none;
  border-left: none;
  border-right: none;
}

.des-lib-target-field-text {
  color: #495D7A;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
  line-height: 16.80px;
  word-wrap: break-word;
}

.des-lib-tde-arrow-right {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: -0.3rem;
}

.des-lib-tde-aliases {
  color: #747474;
  font-size: 13px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  margin-left: 5px;
}

.des-lib-tde-delete-icon {
  width: 50px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -0.5rem;
}

.v2-des-lib-tde-delete-icon {
  width: 54px;
  margin-left: 41px;
  cursor: pointer;
  margin-top: -0.5rem;
}

.des-lib-tde-delete-icon-v2 {
  width: 19px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -0.5rem;
  margin-right: unset;
}

.des-lib-tde-line {
  margin: 0.2rem 0;
}

.des-lib-tde-des {
  color: #495D7A;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 500;
  line-height: 19.50px;
  word-wrap: break-word;
}

.des-lib-tde-warning-bold-text {
  color: #495D7A;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 600;
  line-height: 22.50px;
  word-wrap: break-word;
}

.des-lib-tde-warning-text {
  color: #495D7A;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
  line-height: 22.50px;
  word-wrap: break-word;
}

.des-lib-target-field-footer {
  flex: 1 1 0;
  height: 41px;
  padding: 16px 16px;
  background: #F4F7FB;
  display: flex;
  border: 1px solid #DDDBDA;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-top: none;
  position: sticky; /* Make footer stick at the bottom */
  bottom: 0;
  margin-top: auto;
}

.des-lib-target-field-footer-text {
  // Add field
  color: #005FB2;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
  word-wrap: break-word
}

.des-lib-target-field-footer-text-next {
  // Add field
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
  word-wrap: break-word
}

.selectedTF-fields-v2 .target-collection-row .target-collection-dropzone .empty-for-dropzone {
  width: 100%;
  height: 102%;
  position: absolute;
  left: 0px;
  top: 2px;
  background: transparent;
  height: 2rem;
  border-radius: 7px;
}

.des-lib-edit-icon {
  margin-right: 10px;
}

.des-lib-tde-key-icon-v2 {
  margin-left: -1rem !important;
}

.remove-mappings-button {
  color: gray;
  margin-left: 0.3rem;
  border: none;
  background-color: transparent;
  outline: none;
  transform: translateY(0.03rem);

  :hover {
    color: var(--darkRed) !important;
  }
}

.link-to-target-de-v2 {
  margin-left: 6px;

  button {
    height: 33.018px
  }
}

.new-tde-fields {
  margin-left: 4px !important;
  margin-right: 10px !important;
}

.edit-delete-icons {
  display: flex !important;
  margin-left: -20px;
  justify-content: space-between;
}
.des-button-space {
  margin-right: 43px !important;
}
