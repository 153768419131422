.overview {
  margin-right: var(--rightbarWidth); // Needed for the rightnav

  .toast-none {
    opacity: 0;
    transform: translate(-50%, -101%) rotateX(180deg);
    z-index: -999;
    display: none;
  }

  &_container {
    width: 98.3%;
    margin: 0 auto;
    background-color: var(--white);
    border-radius: .3rem;
    position: relative;
    padding-top: .1rem;

    .make-table-scroll {
        height: calc(100vh - 125px);
        overflow: hidden;
        overflow-y: scroll;
        border-bottom: 1px solid var(--lightGrayBorder);

        table {
            border-radius: .3rem;
        }
    }
}

  &_selection_wrapper {
    display: flex;
    width: 98.3%;
    margin: 0 auto;
    background-color: var(--white);
    border-radius: 0.3rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);

    .overview_selection_folders {
      position: relative;
    }
    .overlay{
      background: rgba(0, 0, 0, .75);
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .selection-list {
      margin: 0.7rem 0.6rem;
      margin-bottom: 0;
      border-radius: 0.3rem;
      flex: 1;
      position: relative;
      overflow-y: scroll;
      overflow-x: auto;
    }
  }

  &_selection_wrapper_newDesign {
    display: flex;
    height: calc(100vh - 80.5px);
    width: 98.3%;
    margin: 0 auto;
    background-color: var(--white);
    position: relative;
    overflow: hidden;

    .overview_selection_folders {
      position: relative;
    }
    .overlay{
      background: rgba(0, 0, 0, .75);
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .selection-list {
      margin: 0.7rem 0.6rem;
      margin-bottom: 0;
      border-radius: 0.3rem;
      flex: 1;
      position: relative;
      overflow-y: scroll;
      overflow-x: auto;
    }
  }
}

.submenu {
  position: absolute;
  z-index: 787897;
  display: none;
  padding: 0.3rem;
  background-color: var(--white);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid gray;
  border-radius: 0.2rem;
  height: 0rem;
  transition: 0s;
  overflow: hidden;

  &.active {
    display: flex;
    z-index: 4;
  }

  &.down {
    height: 5rem;
    transition: 0.3s;
  }

  &_folder {
    border: none;
    outline: none;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--lightGrayBorder);
    }

    &.disabled {
      opacity: 0.3;
      background-color: var(--white);
    }
  }
}
