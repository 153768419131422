.selection-folders-wrapper-v2 {
  display: flex;
  flex-direction: column;
  margin: 0.7rem;
  overflow: auto;
  margin-bottom: 0;

  &.in-waterfall {
    height: calc(100vh - 4rem);
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid var(--lightGrayBorder);

    .folder-settings-wrapper>p {
      color: #333;
      padding: 0;
    }
  }

  .mainFolder {
    margin-left: 2px;
  }

  .folders-title-container {
    padding-left: 0.5rem;
    margin-top: 7px;

    .folders-title {
      color: #333;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }

  .overview_folders_wrapper {
    width: 20rem;
    margin-bottom: 0;
    transition: 0.5s all;
    border-right: 1px solid var(--lightGrayBorder);
    flex: 1 1 auto;
    display: flex;

    &.selection-folders-disabled{
      display: block;
    }

    .folder-loading-spinner {
      position: relative;
      top: 50%;
    }

    &.hide {
      border: none;
      width: 0rem;
      overflow-x: hidden;
      pointer-events: none;
    }

    &.blur {
      opacity: 0.3;
      background-color: rgba(185, 185, 185, 0.397);
    }

    .noIdFolderArchive {
      display: flex;
      padding-left: 0.3rem;
      align-items: center;
      line-height: 1.1rem;
      height: 2rem;
      cursor: pointer;
      justify-content: flex-start;
      border: 1px solid transparent;
      margin: 0 0.3rem;

      span {
        margin-right: 0.5rem;
      }

      span {
        margin-right: 0.5rem;
      }

      p {
        font-weight: 400;
        white-space: nowrap;
        transition: 0.3s all;

        &.blur {
          opacity: 0.3;
        }
      }
    }

    .surrounding_folders {
      display: flex;
      flex-direction: column;
      overflow: auto !important;

      &.full-height {
        height: 100%;
      }

      &.disabled-folders {
        padding-bottom: 2em;

        .tooltip-container {
          position: absolute;
          z-index: 99;
          top: 7.4px;
          right: 9.2em;

          .slds-popover {
            position: relative;
            left: auto !important;
          }

          .slds-button_icon {
            position: absolute;
            z-index: 999;
            right: 0em;
          }
        }

        .overlay-wrap {
          background: var(--disabledGrayOverlay);
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: not-allowed;
          border: 1px solid rgba(200, 200, 200, 0.5);
        }
      }

      .upgrade-badge-icon {
        top: 3px;
        left: 9em;
        bottom: 0;
        z-index: 9;
      }

      .disabled-feature-message{
        display: flex;
        justify-content: flex-end;

        .des-tooltip {
          z-index: 1;
        }
        .slds-tooltip-trigger{
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 0.3rem;
          margin-top: 0.2rem;
        }

        .slds-popover_tooltip{
          position: absolute;
          max-width: 6.5rem;
          width: max-content;
          top: 1.5rem !important;
          left: 13.3rem !important;

          .slds-popover__body {
            word-wrap: break-word;
            overflow-wrap: break-word;
            hyphens: auto;
          }
        }
      }

      position: relative;
      padding: 0 0 0.1rem 0;
      overflow: hidden;

      .staticFolder {
        display: flex;
        align-items: center;
        width: 16rem;
        margin: 0;
        border: 1px solid transparent;
        padding: 0.05rem;
        transform: translateX(-0.3rem);
        position: relative;

        &.disabledFolder {
          pointer-events: none;
          opacity: 0.3;
        }

        &.border_folder {
          border: 1px solid var(--darkGray);
          background-color: var(--lightGrayBorder);
        }

        &.active {
          background-color: var(--lightSoftBlue);
        }


        i {
          width: 1.3rem;
          padding-left: 0.3rem;
        }

        p.folder_element {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          height: 1.5rem;
          padding: 0 0.23rem;
          margin: 0 0 0 0.5rem;
          box-sizing: border-box;
          width: 100%;
          white-space: nowrap;

          &.blur {
            opacity: 0.3;
          }
        }
      }

      .noIdFolder {
        display: flex;
        padding-left: 0.3rem;
        align-items: center;
        line-height: 1.1rem;
        height: 2rem;
        cursor: pointer;
        justify-content: flex-start;
        border: 1px solid transparent;
        margin: 0 0.3rem;

        span {
          margin-right: 0.5rem;
        }

        p {
          font-weight: 400;
          white-space: nowrap;
          transition: 0.3s all;

          &.blur {
            opacity: 0.3;
          }
        }
      }

      .all-selections-folder {
        display: flex;
        padding-left: 0.1rem;
        width: 100%;

        .mainFolderText {
          width: 100%;
        }
      }

      .noIdFolder {
        width: 100%;
      }

      .favorites-folders {
        padding-left: 0.8rem;
      }

      .created_folders {
        display: none;
        margin: 0rem 0.05rem 0 0.05rem;
        width: 18rem;

        &.disabled-folders {
          .overview_folders_container-v2 {
            display: none;
          }
        }

        &.open {
          display: flex;
          flex-direction: column;
        }

        .noIdFolder {
          padding-left: 1.2rem;

          span {
            width: 1.2rem;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }

        .noIdFolder {
          padding-left: 1.2rem;

          span {
            width: 1.2rem;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .mark_all {
      width: 99%;
      box-shadow: -2px 0 0 var(--primaryBlue);
      background-color: var(--veryLightBlue);
    }
  }

  &.hide-folders>.folder-settings-wrapper {
    opacity: 0;
    width: 0.3rem;
    transform: translateX(-100%);
  }

  .folder-settings-wrapper {
    width: 20rem;
    opacity: 1;
    transform: translateX(0%);
    transition: 0.5s ease;
  }

  .overview_folders_wrapper-hide {
    position: absolute;
    top: 50%;
    transform: translate(4rem, -50%);
    left: 16.3rem;
    width: 0.3rem;
    border-radius: 0.3rem;
    height: 8rem;
    background-color: var(--lightGrayBorder);
    transition: 0.5s all;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 35%;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-width: 3px 5px 3px 0;
      border-color: transparent var(--darkGrayHeader) transparent transparent;
      position: absolute;
    }

    &:hover {
      background-color: var(--gray);
    }

    &:hover>.penguin_for_hide_show_folders {
      opacity: 1;
    }

    &.hide {
      left: -2.87rem;

      &::before {
        transform: translate(-50%, -50%) rotateY(180deg);
      }
    }

    .penguin_for_hide_show_folders {
      min-width: 2rem;
      height: 2rem;
      transition: 0.3s all;
      pointer-events: none;
      opacity: 0;
      z-index: 999999999 !important;
      position: absolute;
      left: -2rem;
      bottom: 3rem;
      transform: rotate(90deg);

      &.rotate_penguin {
        left: 0.5rem;
        transform: rotate(-90deg);
      }
    }
  }

  .feature-folder-upgrade {
    padding: 0.5rem;
    font-size: 13px;
    line-height: initial;
  }
}

.border_folder {
  border: 1px solid var(--darkGray);
  margin-left: -1.2px;
  background-color: var(--lightGrayBorder);
  border-radius: 0.3rem;
}

.uncategorized-and-archived {
  padding-left: 12px;
}