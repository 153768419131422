.data-extension-v2 {
    height: 96vh;

    &_wrapper {
        display: flex;
        height: 100%;

        .tabs {
            display: flex;
            flex-flow: column;
            border-right: 1.5px solid #DDDBDA !important;
            height: 100.2%;
        }

        .tabs-filters {
            animation: tabs-in-filters 0.5s linear forwards;
            animation-delay: 0.5s;
        }

        .tabs-filers-show-immediately {
            display: unset;
        }
    }

    .expanded {
        animation: make-it-smaller 0.5s linear forwards;
        animation-delay: 0.5s;
    }
    

    .expanded-immediately {
        height: 0%;
    }

    .collapse-expand-filters-hide {
        animation: collapse-expand-filters-hide-animation 0.5s ease-in forwards;
        animation-delay: 0s;
    }

    .collapse-expand-filters-hide-immediately {
        visibility: hidden;
        display: none;
    }

    @keyframes collapse-expand-filters-hide-animation {
        to {
            visibility: hidden;
            display: none;
        }
    }

    .collapse-expand-filters-show {
        animation: collapse-expand-filters-show-animation 1.54s ease-in forwards;
        animation-delay: 0s;
    }

    @keyframes collapse-expand-filters-show-animation {
        from {
            visibility: hidden;
            display: none;
            position: relative;
        }

        to {
            visibility: visible;
            display: unset;
        }
    }

    .collapse-expand-filters {
        width: 110px;
        margin-left: 21.7rem;
        margin-top: -9rem;
        position: absolute;

        .collapse-expand-button {
            display: flex;
            align-items: center;
            height: 32px;
            width: 104px;
            font-size: 15px;
            border: none;
            background: none;
            border-radius: 5px;
            cursor: pointer;
            padding: 8px 16px 8px 16px;
            border: 1px solid #DDDBDA;
            background-color: #FFFFFF;
            color: #495D7A;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px;
            margin-left: 1rem;
            z-index: 9;
        }
    }

    .hide {
        height: 0%;
    }
}

.new-selection-v2 {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.selection>.data-extension {
    margin: 0 auto;
    width: 98.3%;
    border-radius: 0.3rem !important;
    background-color: var(--white);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    height: calc(100vh - 80.5px);
    overflow-y: auto;
    padding: 15px;
}

#basic-mode-dropdown {
    flex: 1;
}

.data-extension-single {
    width: 98.2%;
    margin: 0 auto;
    background: var(--white);
    padding: 0.4rem 1.1rem;
    border-radius: .3rem;
    min-height: calc(100vh - 80.5px);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
}

.data-sets-dropdown-container {
    height: auto !important;

    .label-container {
        margin-bottom: 5px;
        font-weight: bold;
    }

    .selection {
        height: auto !important;
    }

    .data-sets-dropdown {
        width: 100% !important;
        min-width: 7rem !important;
        border-radius: 0.25rem;

        div.text,
        input {
            padding: 0px 1rem 0 0px;
            width: 100% !important;
        }

        .menu {
            max-height: 10rem;
        }
    }
}

.selectionSourceTypeRadioButton {
    margin-bottom: 1rem;
    margin-right: 2.5rem;
    float: left;

    .radio-label {
        font-weight: Bold;
    }

    .slds-radio_faux {
        margin-right: 0.25rem;
    }
}


@keyframes make-it-smaller {
    from {
        height: 100%;
    }

    to {
        height: 0%;
    }
}

@keyframes tabs-in-filters {
    to {
        display: unset;
    }
}

.new-selection-v2-filter {
    animation: new-selection-v2-animation 0.5s linear forwards;
    animation-delay: 0.5s;
}

@keyframes new-selection-v2-animation {
    from {
        height: 100%;
    }

    to {
        height: 0%;
    }
}

.collapse-expand-icon {
    margin-right: 8px;
}

.nav-position {
    margin-top: -1px !important;
}

@keyframes data-extension-filters-expanded-animation {
    to {
        display: flex;
        flex-direction: column;
    }
}

.data-extension-filters-expanded {
    animation: data-extension-filters-expanded-animation 0.5s linear forwards;
    animation-delay: 0.5s;
}