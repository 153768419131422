.send-relationship-modal {
    width: 80em;
}

.send-relationship-main-container {
    min-height: 25em;

    .send-relationship-toggle-container {
        display: flex;
    }

    .selected-send-relationship-extension-dropdown {
        width: 100% !important;
        max-height: 3rem !important;
        line-height: .27rem !important;
        margin: 0 !important;
        min-height: 0px !important;
        height: 2rem !important;
        padding: .5rem !important;
      
        input {
          padding: 0 !important;
          margin: 0 !important;
          line-height: 1.9rem !important;
          padding-left: 1rem !important;
          height: 100% !important;
          width: 25rem !important;
        }
      
        div.text {
          width: 25rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1rem;
          height: 1.2rem;
        }
      
        i {
          padding: .5rem !important;
        }
      
        .item {
          height: 2rem !important;
          line-height: .3rem !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
      
          span {
            white-space: nowrap !important;
          }
        }
        .selected.item {
          font-weight: 400 !important;
        }
      }

    .default-send-relationship-note {
      margin-top: 3em;
    }

    .toggle-send-relationship {
        margin-left: 2.2rem;
    }
    
    .mb-0px {
        margin-bottom: 0px !important;
    }

    .set-relationship-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .default-relationship {
            width: 9em;
        }
    }

    .some-margin {
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }

    .strong {
        font-weight: bold;
    }
}

.send-relationship-spinner-container {
    min-height: 25em;
    padding-top: 12.5em;
}
.admin-settings-titles {
  font-size: 15px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  color: #333333 !important;
  text-align: left !important;
}

.admin-settings-paragraph {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #222 !important;
}
.admin-settings-small-text {
  font-size: 12px !important;
  color: #222 !important;
}
