.swal2-waterfall-selection-title {
  font-size: 1.10rem !important;
}

.selection-icon-wrapper {
  position: relative;
}

.selection-info {
  .open-selection-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    border-radius: 50%;
    background-color: var(--lightGrayIconBackground);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--lightGrayIconBackgroundHover);
      transform: scale(1.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .open-selection-btn {
      transition: transform 0.3s ease;
    }
  }
}
