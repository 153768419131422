.selected-filters-container-v2 {
    background-color: #F4F7FB !important;
    width: calc(100% - 5rem) !important;
    padding: 1rem !important;
    border-left: 1px solid #D3D3D3;
    overflow: auto;
    margin-top: -42.87px;

    .header-container {
        margin-bottom: 16px;

        .collapse-expand-button-container {
            .collapse-expand-button {
                display: flex;
                align-items: center;
                height: 32px;
                width: 104px;
                font-size: 15px;
                border: none;
                background: none;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 16px 8px 16px;
                border: 1px solid #DDDBDA;
                background-color: #FFFFFF;
                color: #495D7A;
                box-shadow: rgb(0 0 0 / 5%) 0px 2px 2px;

                .collapse-expand-icon {
                    margin-right: 8px;
                }
            }
        }
    }

    .height-95 {
        height: 95%;
        display: flex;
        flex-flow: column;
    }

    .height-100 {
        height: 100%;
    }
}

.selected-filters-container-v2-no-margin-top {
    margin-top: 0;
}

.swal2-select{
    width: 100%;
    // color: var(--grayText);
    border-radius: 3px;
    border-color: var(--grayBorder);
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    font-size: 15.75px !important;
}
