.prio-dedup_container-v2 {
  width: 566px !important;

  .prio-dedup-title {
    position: absolute;
    margin-top: 1.8rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .prio-dedup-body {
    height: 519px;
    flex-shrink: 0;
  }

  #advanced-prio-dedup-add-rule-btn {
    margin: 1rem 0;
  }

  .back-to-target-definition {
    position: absolute;
    left: 10%;
    margin-top: 1.8rem;

    i {
      margin-right: .3rem;
    }
  }

  .prio-dedup-onoff {
    width: 16rem;
    align-self: flex-start;
  }

  .slds-progress {
    margin-right: 1rem;
    margin-left: 2rem;

    .slds-progress__list {
      .slds-progress__item {
        position: relative;

        .tooltip-step-1 {
          position: absolute;
          top: -3.5rem;
          left: -1rem;
          width: 5.8rem;
        }

        .tooltip-step-2 {
          position: absolute;
          top: -3.93rem;
          left: -1rem;
          width: 5.8rem;
        }
      }
    }

    .slds-progress-bar {
      span {
        transition: 1s all;
      }
    }
  }

  .explanation-text {
    margin-top: 0.3rem;
    font-size: 0.9rem;
    margin-bottom: 1.3rem;
    color: #747474;
    color: var(--color-text-subtle, #747474);
    font-feature-settings: "clig" off, "liga" off;
    line-height: 24px;
  }

  .labels {
    font-style: normal;
    line-height: 20px;
    margin-bottom: .4rem;
  }

  .prio-dedup-page-1 {
    margin-bottom: 1rem;
    padding: 0 0.25rem;

    .deduplication-field-container {
      margin-bottom: 1rem;
    }
  }

  .prio-dedup-page-2 {
    padding: 0 0.25rem;

    .prio-dedup-element {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .define-sort-box {
      border: 1px solid var(--grayBorder);
      padding: 0.5rem;
      border-radius: 0.3rem;
    }

    .sort-way-box {
      legend {
        margin-bottom: 0.4rem;
      }

      p {
        margin-top: 0.3rem;
        font-size: 0.9rem;
        line-height: .9rem;


        span {
          font-weight: 700;
          margin-left: 0.25rem;
        }
      }
    }
  }

  .prioritization-logic-container {
    .slds-form-element__legend {
      margin-bottom: .4rem;
      width: 100%;
    }

    .prioritization-logic {
      padding: 0 0.25rem;
    }
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .prio-dedup-grayed-out {
    opacity: 0.4;
    pointer-events: none;
  }

  .slds-progress__marker {
    width: 1rem;
    height: 1rem;
  }

  .slds-button__icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .cancel-save-prio-dedup {
    display: flex;

    .item-button {
      border-radius: 50% !important;
    }
  }

  .add-rule-btn-container {
    margin-top: 1rem;

    .add-rule-btn {
      display: inline-flex;
      height: 34px;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
    }
  }

  .first-step,
  .second-step {
    border-radius: 50% !important;
  }

  .first-step {
    margin-left: -1.2rem;
  }
}

.mb-05rem {
  margin-bottom: 0.5rem;
}

.plus-icon {
  margin-right: 8px;
}

.bold-text {
  font-weight: bold;
}

.hide-prio-dedup-modal {
  visibility: hidden;
}