.display-flex {
    display: flex;
}

.mt-5px {
  margin-top: 5px;
}

.ml-4px {
  margin-left: 4px;
}

.ml-10px {
  margin-left: 10px;
}

.filter-set-name-input {
    margin-top: 2rem;
}

.field-container {
  height: 3.5rem;
}

.filter-set-name-label, .select-filter-set-source-label, .filter-set-description-label {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
}

.filter-set-name-label, .filter-set-description-label
{
  margin-bottom: 0.5rem;
}

.filter-set-container {
  .relation-panel-container {
    .relation_wrapper {
      min-height: 0 !important;
    }
  }

  .tooltip-container{
    font-weight: normal;
  }
}

.field-content {
  min-height: 0rem !important;
}

.filter-sets-header {
  &-title {
      font-size: 1.5rem;
      font-weight: 700;
  }

  .label {
      font-weight: 700;
      padding-bottom: 0.5rem;
      color: var(--primaryDarkGray);
  }

}

.filter-set-source {
  display: flex;
  .radio-label{
    font-size: 1rem;
    margin-top: 0.12em;
  }
}

.radio-container {
  display: flex;
  margin-right: 4em;
}

.filter-set-description-input {
  margin-bottom: 2em;
}

.edit-selected-filters-btn {
  padding-bottom: 1rem;
}

span.filter-set-description-label-category {
  margin-bottom: 0.1rem;
}

.field-container.margin-top.category {
  margin-bottom: 2rem !important;
}

