.lmo {
  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;

    .closeModal {
      line-height: 1rem;
      top: .5rem;
      right: .5rem;
      position: absolute;
      font-size: 2.7rem;
      border: none;
      cursor: pointer;
      font-weight: 100;
    }
  }
}
.custom-modal__header  {
  z-index: 99 !important;
}
