.waterfall-selection_new {
    height: 100vh;
    margin-right: var(--newRightbarWidth); // Needed for the rightnav
    overflow-y: auto;
    min-width: 1100px;
    overflow-x: auto;

    &--expanded{
        width: calc(100% - var(--newRightbarWidthExpanded));
    }
    display: flex;
    flex-direction: column;

    button[type="button"]:disabled {
        // Target the spinner inside a disabled button and set its color to white
        .slds-spinner {
          &::after, &::before {
            background-color: var(--white) !important;
          }

          .slds-spinner__dot-a::before,
          .slds-spinner__dot-a::after,
          .slds-spinner__dot-b::before,
          .slds-spinner__dot-b::after {
            background-color: var(--white) !important;
          }
        }
    }
}
