.extraInfo {
    margin: 10px 0px;
    text-align: center;
    background-color: var(--white);
    border-radius: .3rem;
    padding: 5px 0px;
}

.new-usersTable {
    height: calc(100% - 67px);
    border-radius: .3rem;
    padding-top: 2.5rem;

    .slds-cell-fixed {
        padding-top: 0.7rem;
    }

    & > div {
        height: 100%;
    }

    td {
        padding: 0.2 0;
        line-height: 2rem;

        &:first-child > div {
            margin-left: .75rem;
        }
    }

    td:nth-child(4) {
        padding-left: 0.5rem;
    }

    th {
        div {
           margin-left: 1rem;
       }

       &:nth-child(1) > div {
           margin-left: 1.2rem;
       }
    }

    .slds-hint-parent {
        &.users_row {
            .fullName {
                color: var(--darkBlue);
                cursor: pointer;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
    
                .cell-full-name-text {
                    text-overflow:ellipsis;
                    margin-left: 4px;
                }
    
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
            .user-icon-in-the-table {
                margin-top: -0.2rem;
                height: 16px;
                width: 16px !important;
            }
            td:nth-child(1) {
                z-index: 4;
            }
    
            td:nth-child(2) {
                z-index: 3;
            }
    
            td:nth-child(3) {
                padding-left: 10.5rem;
                z-index: 2;
            }
    
            td:nth-child(4) {
                padding-left: 5.5rem;
                z-index: 1;
            }
    
            td:nth-child(5) {
                padding-left: 0.5rem;
            }
    }
    }    
}
.new-userPanel {

    th:nth-child(3) {
        padding-left: 10.5rem;
    }

    th:nth-child(4) {
        padding-left: 5.5rem;
    }

    .slds-cell-fixed {
        padding-top: 0.7rem;

        span {
          cursor: pointer;
          margin-left: .4rem;
          margin-right: .4rem;
          svg {
            margin-left: -.1rem;
          }
        }
    }
}

.new-user-licenses {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h6:nth-child(1) {
        margin-bottom: 0.5rem;
    }

    h6 {
        span {
            color: var(--primaryBlue);
        }
    }
}

.user-active-status-sticker {
    color: #FFFFFF;
    background-color: #4BCA81;
    width: 70px;
    height: 20px;
    text-align: center;
    border-radius: 12px;
    font-size: 13px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem !important;
}

.user-inactive-status-sticker {
    color: #495D7A;
    background-color: #E8EEF6;
    width: 80px;
    height: 20px !important;
    text-align: center;
    border-radius: 12px;
    font-size: 13px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem !important;
}

.admin-yes-status-sticker {
    color: #495D7A;
    background-color: #E8EEF6;
    width: 50px;
    height: 20px;
    text-align: center;
    border-radius: 12px;
    font-size: 13px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem !important;
}
.users-td-style {
    font-size: 13px !important;
    color: #222;
}
