.display-flex {
    display: flex;
}

.ml-4px {
    margin-left: 4px;
}

.data-set-name-input {
    margin-top: 2rem;
}

.data-set-name-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.data-set-container {
  .relation-panel-container {
    .relation_wrapper {
      min-height: unset;
      margin-bottom: 120px !important;
    }
  }
}


#data-set-panel{
  .data-extensions-container{
    .tooltip-container{
      bottom: 1px;
      font-weight: normal;
    }
  }
  .section-title-data-sets{
    font-size: 0.875rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
