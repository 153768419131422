#filterSets-panel {
    height: calc(100vh - 190px);
    border-radius: .3rem;
    padding-top: 2.5rem;
  
    .slds-cell-fixed {
        padding-top: 0.7rem;
  
        span {
          cursor: pointer;
          margin-left: .4rem;
          margin-right: .4rem;
          svg {
            margin-left: -.1rem;
          }
        }
    }

    .filtersets-actions {
        padding-left: 0.6rem;
    }
  
    & > div {
        height: 100%;
    }
  
    td {
        padding: 0.2 0;
        line-height: 2rem;
  
        &:first-child > div {
            margin-left: .75rem;
        }
  
        &:nth-child(1) {
            max-width: 8rem;
        }
  
        &:nth-child(2) {
            padding-left: 0.5rem;
            max-width: 6rem;
        }
  
        &:nth-child(3) {
            max-width: 6rem;
        }
  
        &:nth-child(4) {
            max-width: 5rem;
        }
  
        &:nth-child(5) {
            max-width: 4rem;
            padding-right: 1rem;
        }
  
        &:nth-child(6) {
            padding-right: 3rem;
            max-width: 4rem;
        }
  
        &:nth-child(7) {
            max-width: 4rem;
            margin-left: 6rem;
        }
    }
  
    th {
        div {
            margin-left: 1rem;
        }
  
        &:nth-child(1) > div {
            margin-left: 1.2rem;
        }
  
        &:nth-child(1) {
            max-width: 8rem;
        }
  
        &:nth-child(2) {
            max-width: 6rem;
        }
  
        &:nth-child(3) {
            max-width: 6rem;
        }
  
        &:nth-child(4) {
            max-width: 5rem;
        }
  
        &:nth-child(5) {
            max-width: 4rem;
        }
  
        &:nth-child(6) {
            max-width: 4rem;
        }
  
        &:nth-child(7) {
            max-width: 4rem;
            margin-left: 6rem;
        }
    }
  }
  