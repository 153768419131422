.selection-container-header {
    padding-left: 8px;
    padding-right: 8px;
    background: white;
    border-bottom: 1px #DDDBDA solid;
    justify-content: center;
    align-items: center;
    display: inline-flex
}

.selection-container-new {
    border: 1px solid var(--lightGrayBorder);
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    height: calc(100vh - 6.3rem);
    margin-top: -2.0767rem;
    overflow: auto;
    background: #EAF0F8;
    border-left: unset;
    border-right: unset;

    .create-new-selection {
        display: flex;
        justify-content: end;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-right: 0.5rem;
    }

    .selections {
        min-height: 4rem;
        width: 100%;
        max-width: 100%;
        min-width: 88%;
        border: 2px dashed transparent;
    }

    .selection-drop {
        transition: .3s all !important;
        border: var(--transparentBorder);
    }

    &[data-rbd-scroll-container-context-id] {
        .selection-dropzone{
            border: var(--dashedBorder);
        }
    }

    .selection-dropzone {
        border: 1px solid transparent;
        padding: 0.5rem;

        &.isDragging {
            border: var(--dashedBorder);
        }

        ~div {
            display: none;
        }
    }
}

.selection-container-new-isArchived {
    flex: 1 1 auto;
    height: unset !important;
}