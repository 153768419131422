.new-filter-line {
  padding: .5rem;
  margin: 0rem;
  border-bottom: none;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 1rem;
  width: 100% !important;

  .filter-criteria-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 39px;

    .slds-select {
      height: 36.99px;
    }
  }

  .is-picklist-option-box {
    height: auto !important;
  }

  .virtual-list-container{
    width: 100%;
  }

  .virtualized-dropdown {
    margin-top: 0;
  }

  .compare-fields-filter-input-container {
    .compare-fields-filter-picklist {
      margin-top: 1px;
      height: 37px;
    }
  }

  &_should-be-flex-end-aligned {
    align-items: flex-start;

    .filter-line-criteria-dropdown-container {
      margin-top: 8px;
    }

    .icons-container {
      margin-top: 4px !important;
    }

    .compare-fields-filter-toggle {
      align-self: flex-start !important;
      margin-top: 4px;
    }

    .input-picklist-toggle {
      margin-top: 4px;
    }

    .new-filter-line_drag-icon {
      margin-top: 12px;
    }
  }

  .filter-value-col {
    .slds-form-element {
      height: 31px !important;
    }

    .slds-input {
      margin-top: 1px;
      height: 29px;
    }
  }

  .collection-alias {
    line-height: 19px;
  }

  .target-collection-dropzone {
    width: 100%;
  }

  .subquery-dropzone {
    display: initial;
  }

  #target-DE-dropdown {
    width: 100% !important;
  }

  .react-tagsinput-input {
    height: 37px;
  }

  &_filter-name {
    height: 29px;
    margin-right: 8px;
    margin-top: 0.09rem;
  }

  .filter-or-filter-set-separator {
    margin-right: 8px;
    color: #495D7A;
  }

  &_drag-icon {
    cursor: grab;
    margin-right: 8px;
    min-width: 16px;
  }

  &_where-text {
    width: 42px;
    margin-right: 8px;
    font-size: 13px;
    color: #495D7A;
  }

  // Formula button
  .formula-button {
    margin-left: .3rem;
    width: 2rem;
    height: 1.8rem;
    position: relative;

    img {
      position: absolute;
    }
  }

  .formula-button-selected {
    background-color: var(--greenActive);

    &:hover {
      background-color: var(--primaryGreen);
    }
  }

  .timezone-icon-container {
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 4;

    .timezone-icon {
      fill: #005fb2;
      height: 1.4rem;
      margin-top: 3px !important;
    }

    .timezone-icon-active {
      fill: #4BCA81;
      height: 1.4rem;
      margin-top: 3px !important;
    }
  }

  .datepicker-container {
    width: 100%;

    .first-child {
      width: 100%;
      display: flex;
    }
  }

  .timezone-text {
    display: inline-block;
    font-size: 0.9rem;
    margin-top: 5px;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .transformed {
    transform: translateX(-.4rem);
  }

  .between-datepicker-container-2 {
    display: flex;
  }

  // Remove filter button
  .remove-filterline {
    padding: 0 0.5rem;
    display: flex;
    align-self: center;

    &.in-relation {
      margin-top: 0.4rem;
    }

    &.multiselect {
      align-self: flex-start;
    }

    .remove-line {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      background: none;
    }
  }

  // Date filter is-element
  .new-filter-line-text {
    font-size: 1em !important;
    padding: 6px 0;
    text-align: center;
  }

  .new-filter-line_where-text {
    width: 60px;
  }

  .new-filter-line-and-text {
    margin: 0;
    margin-right: 10% !important;
    padding: 7px 0;
  }

  .new-filter-line-is-text {
    margin-right: 10px;
    display: none !important;
  }

  .align_self-flex_start {
    display: flex;
    align-self: flex-start;
  }

  .align_self-baseline {
    display: flex;
    align-self: baseline;
  }

  .align_self-center {
    display: flex;
    align-self: center;
  }

  .tooltip-span-container {
    position: relative;
    top: -10px;
    right: 0;
  }

  .full-sized-div {
    width: 100% !important;
  }

  .new-filter-line-date-start-text {
    padding-left: 5px;
  }

  // Criteria select
  .filter-criteria-col {
    padding-right: 5px;
    display: flex;
    height: 2rem;

    &.render-merge-icon {
      width: 21.7%;
      transform: translateX(-.01rem);
    }

    select {
      width: 100%;
      border: 1px solid var(--lightGrayColdBorder);
      padding: 0rem 0.75rem;
      border-radius: .25rem;
      line-height: 1.5rem;
    }

    .slds-form-element:nth-child(1) {
      min-width: 44%;
    }

    .slds-form-element {
      width: 100%;
    }
  }

  .filter-value {
    width: 100%;
  }

  //Field where you type your filters
  .filter-value-col {
    min-height: 2rem;
    max-height: max-content;
    display: flex;
    align-items: center;
    position: relative;

    &.render-merge-icon {
      width: 58.8%;
    }

    &.isSubQuery {

      .react-datepicker-popper {
        transform: translate3d(0rem, 2.1rem, 0px) !important;
      }

      .react-datepicker__triangle {
        margin-left: 50% !important;
      }
    }

    .slds-form-element_ {
      width: 100% !important;
      margin-bottom: 0px !important;
    }

    select.filter-value {
      line-height: 1.5rem;
    }

    input {
      padding-right: 0;
    }

    .compare-fields-filter-toggle {
      display: flex;
      cursor: pointer;
      height: 28px !important;

      button {
        width: 2rem;
        margin-right: .3rem;
        padding: 1px 9px;
      }
    }

    .compare-fields-filter-input-container {
      display: flex;
      width: 100%;

      .slds-form-element:nth-child(1) {
        margin-right: .3rem;
      }

      .slds-form-element {
        .slds-form-element__control {
          .slds-select_container {
            select {
              line-height: 1.3rem !important;
              color: #495d7a;
            }
          }
        }
      }
    }

    .dynamic-date-type-container {
      margin: 0 1rem 0 0;

      .dynamic-date-calendar-label,
      .dynamic-date-relative-label {
        label {
          margin-top: 0;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 50%;
          font-family: "SF Pro", Arial, sans-serif !important;
          color: #495d7a;

          .slds-form-element__label {
            margin: 0 0.5rem;
            padding: 0;
          }

          .radio-label {
            vertical-align: baseline;
          }

          span {
            white-space: nowrap;
          }

          span:nth-child(2) {
            transform: translateY(-.1rem);
          }

          input {
            width: 1rem;
            height: 1.5rem !important;
          }
        }
      }
    }
  }

  .draggableDiv__without-subQuery-filter-icon {
    // width: calc(49.5%) !important;
    // margin-right: 2.7rem;
  }

  .draggableDiv__with-subQuery-filter-icon {
    .slds-form-element {
      padding-right: 0.2rem;
    }
  }

  .draggableDiv__isSubQuery {
    margin-right: 0;
  }

  // Date picker input
  .react-datepicker-wrapper {
    width: -webkit-fill-available;
    max-width: 77%;
    display: flex !important;
    height: 2rem;
  }

  .react-datepicker__input-container {
    width: -webkit-fill-available;
    display: flex !important;

    input {
      border: none;
      border-radius: .25rem;
      border: 1px solid var(--lightGrayBorder);
      padding-left: .5rem;
      height: 29px !important;
      font-family: var(--primaryFont);
      font-size: 13px;
      color: #495d7a;
    }
  }

  // Date picker DATE
  .react-datepicker-popper {
    left: -22px;

    div {
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__header {
            font-family: "SF Pro", Arial, sans-serif !important;
            background-color: var(--white);
            font-size: .7rem;
            border-bottom: none;

            .react-datepicker__current-month {
              font-size: .75rem;
              font-weight: 400;
            }

            .react-datepicker__day-names {
              .react-datepicker__day-name {
                margin: .7rem 0.33rem 0.23rem .33rem;
                color: var(--primaryDarkGray);
              }
            }
          }

          .react-datepicker__month {
            margin-top: 0;

            .react-datepicker__week {
              .react-datepicker__day {
                margin: 0.23rem .33rem;
                font-family: "SF Pro", Arial, sans-serif !important;
                border-radius: 50%;

                &.react-datepicker__day--selected {
                  background-color: var(--primaryBlue) !important;
                  color: var(--white);
                }

                &.react-datepicker__day--today {
                  background-color: var(--lightGreyedOut);
                  font-weight: 400;
                }
              }

              .react-datepicker__day--outside-month {
                opacity: .3 !important;
              }
            }
          }
        }

        .react-datepicker__input-time-container {
          font-family: "SF Pro", Arial, sans-serif !important;
        }
      }
    }
  }

  .subquery-dropzone {
    box-shadow: inset 0 0 8px var(--blackBoxShadow);
    width: max-content;
    background-color: var(--dropzoneLightGrayBackground);
    display: flex;
    justify-content: center;
    border-radius: .25rem;
    transition: .3s all;
  }

  .forceWidth {
    width: 54.9% !important;
    min-width: 54.9%;
  }

  .subquery-dropzone:nth-child(2) {
    margin: 0;
  }

  .subquery-dragged-field {
    background-color: var(--white);
    box-shadow: none;
    display: flex;
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--lightGrayColdBorder);
    border-radius: .25rem;
    height: 30px !important;
    padding-top: 2px;
    font-size: 13.5px !important;
    font-family: "SF Pro", Arial, sans-serif !important;
    color: #495d7a;

    .remove-field-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    p {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2rem;
    }
  }


  .remove-field-target-de {
    border: none;
    padding: 0;
    background: none;
    margin-right: .8rem;
    text-align: right !important;
    display: flex;
    margin-left: .5rem;
  }

  .subquery-p {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2.8px;
    width: 100%;
    font-size: .7rem;
    line-height: 2rem;
    border: .1rem dashed transparent;
    transition: .3s all;

    &.greenBackground {
      background-color: var(--greenActive);
      color: var(--white);
    }
  }


  .subquery-field {
    margin-left: .3rem;
  }

  select.subquery-field {
    margin-top: 1px;
    height: 30px;
    line-height: 1.5rem;
    font-size: 13.5px !important;
    font-family: "SF Pro", Arial, sans-serif !important;
    color: #495d7a;
  }

  .align-items-center {
    align-items: center;
  }

  // Move filters icon
  .icons-container {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-left: auto;

    .upgrade-badge-icon {
      position: relative;
    }

    &.render-merge-icon {
      transform: translateX(-1rem);
    }

    .drag-and-drop {
      margin-top: 0.2rem;
      width: 1.6rem;
      height: 1.6rem;
      margin-left: .3rem;

      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }

    .add-logic-link {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 50%;
      margin-left: 0.5rem;
      cursor: pointer;
      background-color: var(--primaryLightGray);
      color: var(--grayBorder);
    }
  }

  .subquery-filter {
    margin-left: .6rem !important;
    width: 2rem;
    height: 28px !important;

    &_green {
      background: var(--greenActive);
      border-color: var(--greenActive);

      &:hover,
      &:focus {
        background: var(--primaryGreen);
        border-color: var(--primaryGreen);
      }
    }
  }

  .dynamic-date-parameters-container {
    display: flex;
    width: 100%;
    padding: 0;

    input {
      margin-right: .3rem;
      transform: translateX(-.4rem);
    }

    .slds-form-element:nth-child(2) {
      margin-right: .3rem;
    }
  }

  .dynamic-date-date-value {
    text-align: center;
  }

  .dynamic-date-filter-interval {
    height: 30px;
    padding: 2px;
    font-size: 13px !important;
    color: #495d7a;
    font-family: var(--primaryFont);
  }

  .dynamic-date-date-value-start {
    height: 30px;
    padding: 2px;
    font-size: 13px !important;
    color: #495d7a;
    font-family: var(--primaryFont);
  }

  .filter-value-small-container {
    display: flex;
    flex-flow: row;
    width: -webkit-fill-available;
    width: -moz-available;

    .slds-form-element {
      input {
        width: 100%;
      }
    }

    .empty-space {
      display: block;
      width: 15px;
    }
  }

  .filter-value-small {
    width: 50%;
    margin-right: 10px;
  }

  .filter-merge-candidate {
    background-color: var(--primaryLightGray);

    .add-logic-link {
      background-color: var(--lightOrangeBorder);
      color: var(--white);
    }
  }

  .formula-class {
    display: flex;
    margin: .65rem 0;
    text-align: justify;

    .formula-radio {
      min-width: 1.7rem;
      min-height: 1.1rem;
      transform: translateY(.07rem);
    }

    label {
      padding-left: .6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p:nth-child(1) {
        font-weight: 700;
      }
    }
  }

  .new-multiselect-picklist-container {
    display: flex;

    .multiselect-picklist-div {
      flex: 1;
      margin-right: 5px;
      max-width: 100%;
    }

    .input-picklist-toggle {
      display: flex;
      cursor: pointer;
      height: calc(1.875rem + (1px * 2));

      button {
        width: 2rem;
        margin-right: .3rem;
        padding: 1px 9px;
      }
    }
  }

  .inResultsFormulaFilterLine-container {
    .filterline-container.single_filterline {
      margin-left: 4em;
    }
  }

  .toggle-formula-container-button {
    border: none;
    padding: 0;
    background: none;
    margin-left: .6rem;
    font-size: 1.6em;
    display: flex;
  }

}

.new-filter-line .filter-value-col .dynamic-date-type-container .dynamic-date-calendar-label label .radio-label, .new-filter-line .filter-value-col .dynamic-date-type-container .dynamic-date-relative-label label .radio-label {
  font-size: 13.5px !important; 
  color: #495d7a;
}

.new-filter-line .forceWidth-v2 {
  width: 100% !important;
}
input.slds-input.test-value.filter-value {
  color: #495d7a;
  font-family: var(--primaryFont);
  font-size: 13.5px !important;
}
