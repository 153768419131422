.tab-container-v2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  border-width: 1px;
  border-color: rgb(201, 201, 201) !important;
  border-radius: .25rem;
  border-style: solid;

  .tab-v2 {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: var(--primaryBlue);
    padding: .6em;
    width: 10.5rem;
    text-align: center;

    &.disabled-overlay{
      background-color: var(--disabledOverlayOrange) !important;
      border-right: 1px solid var(--grayBorder) !important;
      span{
        opacity: 0.5;
      }
    }
  }

  .tab-v2:hover {
    background: var(--lightGrayHover);
    color: var(--darkBlueHover);
    border-color: var(--lightGrayHover);
  }

  .active-v2 {
    background: var(--primaryBlue);
    color: white;
    border-color: var(--primaryBlue);

      .slds-button__icon {
        fill: white;
      }
  }

  .active-v2:hover {
    background: var(--darkBlueHover);
    color: white;
    border-color: var(--darkBlueHover);
  }

  .disabled-v2 {
    cursor: default;
    border-color: var(--disabledGray);
    background: var(--disabledGray);
    color: black !important;
  }

  .upgrade-badge-icon {
    position: relative;
    top: -5px;
    right: 25px;
  }
}
