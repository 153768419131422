#copy-selection-modal-dialog {

    .slds-modal__container {
        width: 35rem;

        &.waterfall {
            width: 28rem;

            .slds-modal__content {
                min-height: 8rem !important;

                .selection-copy-name-wrapper {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 1.5rem 3rem 0rem 3rem;
                    background-color:var(--white);

                    input {
                        margin-top: 0.5rem;
                        margin-left: 0;
                        line-height: 3rem;
                    }
                }
            }
        }
    }

    #content {
        min-height: 15rem;
    }

    .modal-container {
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 3rem 0.5rem 3rem;
    }

    legend {
        margin-bottom: 5px;
    }

    fieldset {
        margin-bottom: 5px;
    }

    .copy-to-container {
        margin-bottom: 10px;

        select {
            line-height: 1.2;
        }

        .disabled-overlay{
            .slds-radio__label{
              background-color: var(--disabledOverlayOrange);
              display: grid;
              padding: 5px 5px 5px 0;
              .radio-label{
                opacity: 0.6;
              }
            }

            &.new-design{
                .slds-radio__label{
                    background-color: var(--disabledGrayOverlay);
                    cursor: not-allowed;
                }
            }

        }
        .tooltip-container{
            position: absolute;
            top: 24px;
            right: 17em;
        }
        .upgrade-badge-icon {
            top: 20px;
            left: 10.5em;
            bottom: 0;
        }
    }

    .selection-copy-name-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        position:relative;
        background-color: var(--modalGrayBackground);
        padding: 0.5rem 3rem;

        span {
            font-weight: 600;
        }

        input {
            margin-left: 1rem;
        }

        .slds-required {
            margin: 0;
            left: -0.5rem;
            position: absolute;
        }
    }

    .target-DE {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.1rem;

        #toggle-button-label {
            width: fit-content;
        }

        .title-target-DE {
            margin-left: 1rem;
        }

        .checkbox-label {
            font-weight: bold;
        }

        .disabled-text {
            color:var(--darkGrayButtonDisabled);
        }
    }
    .location-wrapper-section {

        .location-title {
            font-weight: 600;
        }

        .location-container {
            display:flex;
            align-items: center;
            margin-top: 0.5rem;

            i {
                font-size: 18px;
                margin-right: 0.5rem;
                width: 1.3rem;
            }
        }

        .change-location-title {
            margin-left: 1rem;
            color: var(--primaryBlue);
            cursor: pointer;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .no-target-DE-text {
        text-align: justify;
        line-height: 1.5;
        color: var(--darkGrayButtonDisabled);
    }

    #nameCopyError-1 {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        height: 1.3rem;
        font-size: 0.9rem;
    }

    .copy-to-bu__warnings {
        margin: 8px 0;
        border-radius: 4px;
        border: 1px solid var(--darkGrayButtonDisabled);
        padding: 6px;
        font-size: 12px;

        ul {
            list-style: circle;
            margin-left: 16px;
            margin-top: 4px;
            font-size: 10px;
        }
    }
}

.popup-targetDE {
    width: 26rem !important;
    left: 0 !important;
}

.component-for-copy-selection {

    .preview-loader-container {
        margin-top: 0.3rem;
        position: relative;
    }
}
