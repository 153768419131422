.target-definition-v2 {
  background-color: var(--white);
  margin: 0rem auto;
  width: 100%;
  position: relative;
  height: 79vh;
  flex: 1 1 auto;

  .active-tab {
    background-color: #F4F7FB;
  }

  .disabled-tab {
    background: var(--lightGreyedOut);
    opacity: .5;
    pointer-events: none;
  }

  .des-source-container {
    height: 40px;
    padding: 8px 16px;
    border-radius: 5px;
    gap: 10px;
    position: relative;
    align-items: center;
    margin-top: 10px;
    margin-right: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .des-source-text {
    font-family: var(--primaryFont);
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #181818;
    cursor: pointer;
  }

  .sticky-content {
    position: sticky;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  .slds-tabs_scoped {
    height: 100%;

    .slds-tabs_scoped__nav {
      border-radius: 0;
      border-left: none;
      border-right: none;
      overflow-x: auto;
      overflow-y: hidden;

      .slds-tabs_scoped__item {
        max-width: 19.4rem;

        &.disable-tab {
          opacity: 0.5;
          background: var(--lightGreyedOut);
          pointer-events: none;
        }

        a {
          border-right: 1px solid var(--lightGrayBorder);

          p {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .slds-is-active {
        opacity: 1 !important;

        a {
          button {
            background-color: var(--white);
          }
        }
      }
    }

    // single
    .AF-wrapper-single {
      margin: 0 1rem 0 0rem;
      padding: 0.4rem 1.1rem;
      border-radius: 0.3rem;
      display: flex;
      justify-content: space-between;

      .TD-wrapper-single {
        grid-column: 6/25;
        width: 98.6%;
      }
    }

    .slds-tabs_scoped__content {
      display: none;
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: none;
      padding-right: 0;
      height: 100%;
      padding-top: unset;
      padding-bottom: 0;

      &.active {
        display: block;

        .AF-wrapper {
          display: flex;
          height: 100%;

          .TD-wrapper-v2 {
            grid-column: 6/25;
            width: 98.6%;
            background: #F4F7FB; // transform: translateX(1rem); // causing an error
            position: relative;

            .TD-wrapper-v2-container {
              height: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: column;

              .des-selection-header {
                height: 96.5%;
                overflow: auto;
                display: flex;
              }
            }
          }
        }
      }
    }

    .guidance-parent-target-tabs {
      .guidance-tip-container {
        position: absolute;
        top: 2.5em;
        left: 6.6em;
      }
    }
  }

  .target-de-warning {
    z-index: 9;
  }
}

.TD-wrapper-v2 {
  .slds-builder-toolbar {
    border-left: unset;
    margin-top: 1rem;

    .slds-builder-toolbar__actions {
      display: unset;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: unset;

      .selection_criteriaNavigation {
        left: 37.3%
      }
    }
  }

  .slds-is-selected,
  .slds-is-selected:hover {
    color: var(--slds-g-color-neutral-base-50, #747474);
    border-color: var(--slds-g-color-border-base-4, #c9c9c9);
    background-color: var(--slds-c-button-color-background, var(--sds-c-button-color-background, transparent));

    .slds-button__icon {
      fill: currentColor;
    }
  }

  .slds-is-selected:hover {
    .slds-button__icon {
      fill: darkblue
    }
  }
}

.button-group-tde {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.button-group-tde-text {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--primaryFont);
}

.des-available-fields-v2.mt-2-per.sticky-content {
  border-right: 1.5px solid #DDDBDA !important;
  display: flex;
  flex-flow: column;
}

.tab-pane {
  padding-left: 0;
}

.des-line-tde {
  margin-top: 20px !important;
  width: 100%;
}

.slds-builder-toolbar {
  position: unset;
  width: unset;

  &__actions {
    width: 100%;
    display: block;
  }
}

.des-target-de-main-container {
  margin: 10px;
  background-color: white !important;
  height: 100%;
  overflow: auto;

  .des-target-de-creation-container {
    height: 100%;

    .target-DE {
      height: 100%;
    }
  }

  &.hidden-target-de {
    display: none;
  }

}

.editing-target-de-container {
  padding-bottom: 8.5rem !important;

  .editing-target-de {
    margin: unset;
    background-color: unset !important;
    height: unset;
    overflow: unset;

    .target-de-container-v2 {
      margin-left: 1rem;

      .target-de-main {
        width: 100%;
        justify-content: unset;
        align-items: center;
        position: relative;
        text-align: -webkit-center;
        height: 100%;
        display: flex;
        padding: unset;
        margin-top: 9px;
      }
    }
  }
}

.editing-new-target-de {
  background-color: unset !important;
  height: unset;
  overflow: unset;

  .target-de-main {
    justify-content: unset !important;
    display: flex !important;
    padding: unset !important;
  }

  .target-de-container-v2 {
    margin-left: unset;
  }
}

.padding-relation {
  padding-bottom: 10.5rem !important
}

.padding-no-relation {
  padding-bottom: 8.5rem !important;
}

.nav-position-v2 {
  margin-top: -1rem;
}