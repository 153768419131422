#selection-modal-name {
  overflow: auto;
  background: #F4F7FB;

  .slds-modal__container {
    width: 35rem;
  }

  .slds-modal__header{
    box-shadow:
      0 -4px 6px -1px rgba(0, 0, 0, 0.1),
      4px 0 6px -1px rgba(0, 0, 0, 0.1),
      -4px 0 6px -1px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .slds-modal__content{
    box-shadow:
      4px 0 6px -1px rgba(0, 0, 0, 0.1),
      -4px 0 6px -1px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .slds-modal__footer{
    box-shadow:
      4px 0 6px -1px rgba(0, 0, 0, 0.1),
      -4px 0 6px -1px rgba(0, 0, 0, 0.1),
      0 4px 6px -1px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  #nameCopyError-1 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 1.3rem;
    font-size: 0.9rem;

  }
}
