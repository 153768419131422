#auditLogs-panel {
  height: calc(100vh - 500px);
  border-radius: .3rem;
  padding-top: 2.5rem;

  .slds-cell-fixed {
      padding-top: 0.7rem;

      span {
        cursor: pointer;
        margin-left: .4rem;
        margin-right: .4rem;
        svg {
          margin-left: -.1rem;
        }
      }
  }

  & > div {
      height: 100%;
  }

  td {
      padding: 0.2 0;
      line-height: 2rem;

      &:first-child > div {
          margin-left: .75rem;
      }

      &:nth-child(1) {
          max-width: 8rem;
      }

      &:nth-child(2) {
          max-width: 6rem;
      }

      &:nth-child(3) {
          max-width: 6rem;
      }

      &:nth-child(3) > div {
        padding: 0;
        margin-left: 10px;
    }

  }

  th {
       div {
          margin-left: 1rem;
      }

      &:nth-child(1) > div {
          margin-left: 1.2rem;
      }

      &:nth-child(3) > div {
        margin-left: 11.25rem;
    }

      &:nth-child(1) {
          max-width: 8rem;
      }

      &:nth-child(2) {
          max-width: 6rem;
      }

      &:nth-child(3) {
          max-width: 6rem;
      }

  }
}

.audit-logs-v2 {
  background-color: var(--white);
  flex-grow: 1 !important;
  border-radius: .4rem .4em .4em .4em !important;
  line-height: 1.2 !important;
  padding: 1rem !important;
  height: calc(100% - 67px);

  .slds-spinner {
    position: relative;
  }

  .bold-text {
    font-weight: bold;
  }

  &__title {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    .info-header {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
      padding-left: 10px;
    }

    .info-text-v2 {
      font-size: 14px;
      padding: 0 0 0.5rem 0;
      color: var(--grayText);
      line-height: normal;
    }

    .settings-title-v2 {
      margin: 0.8rem 0;
      font-size: 15px;
      font-weight: 700;
      color: #333 !important;
      margin-top: -15px !important;
    }
  }

  &__actions {
    &_header {
      span {
        font-size: 16px;
        font-weight: bold;
      }

      margin-bottom: 1em;
      display: inline-block;
    }

    .date-range {
      display: flex;
      gap: 10px;

      .range-title-v2 {
        font-size: 14px;
        margin-bottom: 0.5em;
        color: var(--darkGrayText);
      }

      .custom-calendar-header {
        display: flex;
        justify-content: space-between;
        padding: 0.2em 0.6em;

        &__month {
          flex: 2 1 auto;
          display: flex;
          justify-content: space-between;

          select {
            border: none;
            appearance: none;
            font-size: 13px;
          }

          button {
            border: none;
            background-color: transparent;
          }

          button:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }

        &__year {
          flex: 1 1 auto;

          select {
            border-color: var(--lightGrayBorder);
            border-radius: 3px;
            font-size: 12px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding: 0.5em 2.5em 0.5em 0.5em;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
            background-position: calc(100% - .5rem), 100% 0;
            background-size: 1.5em 1.5em;
            background-repeat: no-repeat;
          }
        }
      }

      &__actions {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &_button {
      margin-top: 23px;
    }
  }

  &__generated {
    margin-top: 1.5em;

    td[data-label='Link'] {
      .slds-m-left_small{
        margin-left: 6.5rem !important;
      }
    }

    div[title='Download Link'] {
      margin-left: 6.5rem;
    }

    &_header {
      span {
        font-size: 16px;
        font-weight: bold;
      }

      margin-bottom: 1em;
      display: inline-block;
    }

    &_list_item {
      display: flex;
      margin-bottom: 0.5em;

      >* {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }

    &_list_item.list_headers {
      font-weight: bold;
      margin-bottom: 1em;
      ;
    }

    &_list_item_empty {
      color: var(--lightGrayDisabledItem);
      text-align: center;
      margin-top: 3em;
    }
  }

  &__note {
    font-size: 15px;
    color: var(--darkGrayText);
    margin-top: 1em;

    .email {
      color: black;
    }
  }

  .react-datepicker__day--today {
    color: black;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .tooltip-container {
    display: inline;
  }

  .link-expired {
    color: var(--lightGrayDisabledItem);
    cursor: not-allowed;
  }
}
.settings-new-title {
  font-size: 15px !important;
  color: #333 !important;
  margin-top: -20px !important;
}

.settings-text {
  font-size: 13.5px !important;
  color: #222 !important;
}
.settings-download-text {
  font-size: 13.5px !important;
}
