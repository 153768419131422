.header-main-button, .header-second-button {
    margin-top: 0.1rem;
}
.admin-header-title {
    font-weight: 600 !important;
    color: #495D7A !important;
    font-size: 16px !important;
}
.admin-header-text {
    font-size: 14px !important;
}
