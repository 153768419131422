.settings-main-v2 {
    background-color: var(--white);
    height: calc(100% - 67px);
    border-radius: .3rem;
    padding: 1.5rem 4rem 1.5rem 4rem;
    line-height: 1.2;

    .slds-spinner {
        position: relative;
      }

    .info-text {
        font-size: 16px;
        padding: 0 0 0.5rem 0;
    }

    .email-info-text {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }

    .email-info-group {
        padding: 1rem 0 0.5rem 0;

        button {
            margin-top: -1px;
        }

        .email-item {
            position: relative;
            color: var(--primaryBlack);
            background-color: var(--primaryLightGray);
            padding: 0.5em;
            margin-right: 0.5em;
            border-radius: 5px;

            .remove-icon {
                margin-left: 10px;
                color: var(--primaryDarkGray);
            }
            .remove-icon:hover {
                cursor: pointer;
            }
        }
        .email-input-container {
            display: flex;
        }

        .emails-item-container {
            margin: 0.2rem 0.2rem 0 0;

            .slds-pill:focus {
                border-color: var(--lightGrayBorder);
                box-shadow: none;
                -webkit-box-shadow: none;
            }
            
            .slds-pill {
                 background: var(--primaryLightGray); 
            }
            
            .slds-pill__remove {
                cursor: pointer; 
            }
        }

        .emails-item-list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .warning-text {
        bottom: 0; 
        width: 100%; 
        font-size: 14px;
        padding: 1em 0 1rem 0;
    }

    .settings-title {
        margin: 0.5rem 0;
        font-size: 1.5em;
        font-weight: bold;
    }

    .settings-item-container {
        display: flex;
        margin-bottom: 1rem;

        .settings-enable-status {
            margin: 0 0.1em 0 0   ;
            width: 8rem;
        }

        .settings-show-or-edit-text {
            width: 9.3rem;
        }
    }
}

.show-settings-link {
    color: blue;
    cursor: pointer;
    font: inherit !important;
    font-size: 14px !important;

    &:hover {
        text-decoration: underline;
    }
}

.display-flex {
    display: flex;
}

.admin-settings-titles {
    font-size: 15px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    text-align: left !important;
    margin-top: -5px !important;
}

.admin-settings-paragraph {
    font-size: 14px !important;
    line-height: 24px !important;
    color: #222 !important;
}
