/* DataTable.css */

.des-lib-datatable-data-table-container {
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.des-lib-datatable-data-table-container-stateles {
  width: 100% !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// .des-lib-datatable-table-container {
//   overflow-x: auto;
//   max-width: 100%;
// }

.des-lib-datatable-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 18px;
}

.des-lib-datatable-th,
.des-lib-datatable-td {
  border: 0.2px solid #ddd;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  border-radius: 0.3125rem 0.3125rem 0rem 0rem;
  background: #F4F7FB;
}

.des-lib-datatable-th {
  color: #333;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 171.429% */
  text-transform: uppercase;
}

.des-lib-datatable-td {
  background-color: #fff;
  color: #333;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 100 !important;
  height: 1.4rem;
  vertical-align: middle;
  flex-shrink: 0;
}

.abbreviate-on-overflow {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.des-lib-datatable-button {
  border-radius: 0.4rem;
  background: #0176D3 !important;
  width: 13rem;
  height: 2.3rem;
  flex-shrink: 0;
  color: #f2f2f2;
  font-size: 14px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.des-lib-datatable-create-text {
  color: var(--color-text, #495D7A);
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
  line-height: 150%;
  /* 2.37469rem */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.des-lib-datatable-icon {
  width: 14px;
  height: 14px;
  fill: var(--color-neutral-light, #FFF);
}

.des-lib-datatable-action-icon-shape {
  width: 25px;
  height: 25px;
  fill: var(--color-neutral-light, #FFF);
}

.des-lib-datatable-name {
  display: flex;
  align-items: center;
  text-align: center;
  color: #005FB2;
  min-width: 400px;
  cursor: pointer;
}

.des-lib-datatable-drag-and-drop-icon-shape {
  width: 18px;
  height: 18px;
  fill: var(--color-neutral-light, #FFF);
  cursor: pointer;
}

.des-lib-datatable-error-container {
  background: #BA0517;
  font-size: 10px;
}

.des-lib-datatable-error-text {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  /* 02 - Label/Bold */
  font-family: var(--primaryFont);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  /* 150% */
}

.des-lib-datatable-last-run {
  color: #005FB2;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  /* 150% */
  text-decoration-line: underline;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.des-lib-datatable-sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  width: 90%;
}

.des-lib-datatable-pagination {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  display: none;
}

.des-lib-datatable-pagination-content {
  border-radius: 0.3125rem;
  border: 1px solid var(--color-stroke, #DDDBDA);
  background: #FFF;
  display: flex;
  width: 7rem;
  height: 1.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.des-lib-datatable-pagination-text {
  color: #333;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-weight: 400;
  line-height: 2rem;
  /* 150% */
  height: 2rem;
  flex-shrink: 0;
  width: 70%;
}

.des-lib-datatable-pagination-down-icon {
  width: 14px;
  height: 14px;
  fill: var(--color-neutral-light, #FFF);
  margin-left: 5px;
  display: flex;
  justify-content: flex-end;
}

.des-lib-datatable-pagination-icons {
  border-radius: 0.3125rem;
  border: 1px solid var(--color-stroke, #DDDBDA);
  background-color: rgb(234, 243, 252);
  margin: 3px;
  display: flex;
  width: 1.4rem;
  align-items: center;
  justify-content: center;
}

.des-lib-datatable-action-popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  border-radius: 0.3125rem;
  border: 1px solid var(--color-stroke, #CEDAEC);
  background: var(--color-neutral-light, #FFF);
  /* Elevation 1 */
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 15.5625rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
}

.des-lib-datatable-action-popup button {
  margin: 5px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
}

.des-lib-datatable-action-popup button:hover {
  background-color: #2980b9;
}

.des-lib-datatable-actions {
  color: #495D7A !important;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0.25rem;
  background: #fff;
  align-items: flex-start;
  font-weight: 100 !important;
  align-self: stretch;
  cursor: pointer;
}

.des-lib-datatable-action-icons {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.des-lib-datatable-delete-action {
  color: #EF1D1D !important;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 100 !important;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0.25rem;
  background: #FFF3F3;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
}

.des-lib-datatable-actions:hover {
  background: #fff;
}


.des-lib-datatable-create-selection {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #0176D3;
}

.des-lib-datatable-delete-title {
  color: var(--color-text-emphasis, #181818);
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 100% */
  margin-top: 2rem;
}

.des-lib-datatable-delete-text-top {
  color: var(--color-text, #495D7A);
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 15px;
  font-style: normal;
  font-weight: 100;
  line-height: 150%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.des-lib-datatable-delete-middle-box {
  border-radius: 0.4375rem;
  border: 1px solid var(--color-stroke, #DDDBDA);
  background: var(--color-surface, #F4F7FB);
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  margin-bottom: 2rem;
}

.des-lib-datatable-delete-middle-box-text {
  color: var(--color-text, #495D7A);
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 15px;
  font-style: normal;
  font-weight: 100;
  line-height: 150%;
  /* 1.40625rem */
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.des-lib-datatable-name-link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.des-lib-datatable-delete-middle-box-link {
  color: var(--color-accent, #0176D3);
  font-family: var(--primaryFont);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.des-lib-datatable-delete-actions {
  position: relative;
}

.des-lib-datatable-delete-btn {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-top: -1.8rem;
}

.des-lib-datatable-last-run-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.des-lib-datatable-last-run-table th,
.des-lib-datatable-last-run-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: var(--color-text, #495D7A);
  font-family: var(--primaryFont);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 1.40625rem */
}

.des-lib-datatable-last-run-table th {
  border: none;
  /* Remove border for table header */
}

.des-lib-datatable-action-last-run-date {
  color: var(--color-text, #495D7A);
  font-family: var(--primaryFont);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 1.40625rem */
  margin-right: 3px;
}

.des-lib-datatable-first-column {
  margin-top: 3px;
}

.des-lib-datatable-run-logs-actions {
  position: relative;
  display: flex;
  flex-direction: row;
}

.des-lib-datatable-slds-icon-text-error {
  --slds-c-icon-color-foreground: '#EF1D1D'
}

svg.slds-icon-text-error.slds-icon.slds-icon_x-small.slds-icon-text-default {
  margin-right: 9px !important;
}

.slds-dropdown__item.slds-text-color_error.des-lib-datatable-delete-action.slds-dropdown__item>a {
  color: #ea001e !important;
}

.customDropdown .slds-dropdown-trigger {
  min-width: 100px !important;
  /* Adjust the minimum width */
  padding: 0 12px;
  /* Add padding to the dropdown button */
  height: 36px;
  /* Set the height of the dropdown button */
  line-height: 36px;
  /* Set the line height */
  border-radius: 4px;
  /* Optionally, add border radius */
  border: 1px solid #ccc !important;
  /* Optionally, add border */
  background-color: #fff;
  /* Optionally, set background color */
}

.customDropdown .slds-dropdown ul {
  min-width: 100px;
  /* Adjust the minimum width */
  border-radius: 4px;
  /* Optionally, add border radius */
  border: 1px solid #ccc;
  /* Optionally, add border */
  background-color: #fff;
  /* Optionally, set background color */
}

.customDropdown .slds-dropdown ul li {
  padding: 8px 12px;
  /* Add padding to menu items */
  cursor: pointer;
  /* Change cursor to pointer */
}

.customDropdown .slds-dropdown ul li:hover {
  background-color: #f0f0f0;
  /* Change background color on hover */
}

.header-for-selected-run-log {
  align-items: center;
  display: flex;
  justify-content: center;

  .back-icon {
    position: absolute;
    left: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;

    &:hover {
      cursor: pointer;
      color: var(--darkBlue);
    }
  }
}

.logs-detail-style {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  font-family: var(--primaryFont);
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

}

.show-none {
  display: none;
}

.des-lib-log-details {
  color: #333;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--primaryFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 100 !important;
  height: 1.4rem;
}

.des-lib-datatable-actions-dropdown {
  z-index: 9999;
}

.bold {
  font-weight: bold;
}