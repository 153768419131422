// Default variables for input sizes
$default-input-padding: inherit;

// Variables for input font sizes
$extra-small-input-font-size: 0.625rem; // 10px
$small-input-font-size: 0.75rem; // 12px
$medium-input-font-size: 0.9375rem; // 15px
$large-input-font-size: 1.125rem; // 18px
$extra-large-input-font-size: 1.375rem; // 22px

// Variables for input paddings
$extra-small-input-padding: 0 $default-input-padding;
$small-input-padding: 0 $default-input-padding;
$medium-input-padding: 0 $default-input-padding; // This is the default padding
$large-input-padding: 0.25rem 2rem;
$extra-large-input-padding: 0.5rem 2.5rem;

// Mixin for input sizes
@mixin input-size($font-size, $padding) {
  padding: $padding;
  font-size: $font-size;
  height: auto; // Adjust the height automatically based on padding and font-size
}

// Base input style for medium, which is the default
.des-input {
  input{
    @include input-size($medium-input-font-size, $medium-input-padding);
  }
  // Define common input styles here, e.g., border, background-color, text color, etc.
}

// Extra small input styles
.des-input--x-small {
  input{
    @include input-size($extra-small-input-font-size, $extra-small-input-padding);
  }
}

// Small input styles
.des-input--small {
  input{
    @include input-size($small-input-font-size, $small-input-padding);
  }
}

// Large input styles
.des-input--large {
  input{
    @include input-size($large-input-font-size, $large-input-padding);
  }
}

// Extra large input styles
.des-input--x-large {
  input{
    @include input-size($extra-large-input-font-size, $extra-large-input-padding);
  }
}
