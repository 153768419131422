.related-records-container {
  grid-row: 2;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 100% !important;
  margin-left: unset !important;
  height: unset !important;

  .section-title {
    margin: 0;
  }

  .related-records-scroll {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: fit-content;

    #related-collections {
      border-radius: 0.3rem;
      min-height: auto !important;
      overflow-y: auto;
    }
  }
}

#related-records-hr {
  margin-bottom: 8px !important;
  margin-top: 0px;
}