.multiple_choice-v2 {
    margin-top: 0.3rem;

    button:nth-last-child(1) {
      margin-top: .1rem;

      &.red-btn {
        background-color: var(--lightRed);
      }
    }

    &_line {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border: 1px solid var(--lightGreyedOut);
      background-color: var(--lightGreyedOut);
      border-radius: .3rem;
      padding: .5rem;
      margin: 0.5rem 0 0 0;

      p:nth-child(3) {
        padding: 0 1rem;
      }

      p,
      div {
        margin: 0.12rem 0;
      }

      .multiple_box-1 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info_about_connection {
          span {
            font-weight: 700;
          }
        }

        .box-inside {
          display: flex;
          width: 100%;
          align-items: center;

          p {
            padding: 0 1rem;
          }

          .slds-form-element {
            flex: 1;

            .slds-select {
              line-height: 1.2rem;
            }
          }
        }
      }

      button:nth-child(2){
        width: 8.65rem;
      }
    }
  }