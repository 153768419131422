.slds-spinner {
  color: blue !important;

  &::after, &::before {
    background-color: #0176d3 !important;
  }

  .slds-spinner__dot-a::before,
  .slds-spinner__dot-a::after,
  .slds-spinner__dot-b::before,
  .slds-spinner__dot-b::after {
    background-color: #0176d3 !important;
  }
}