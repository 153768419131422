// Variables for repeated values
$primary-blue: var(--primaryBlue);
$dark-gray: var(--darkGray);
$disabled-overlay-orange: var(--disabledOverlayOrange);
$green-active: var(--greenActive);
$primary-green: var(--primaryGreen);
$white: var(--white);
$dark-orange-box-shadow: var(--darkOrangeBoxShadow);

// Overview wrapper styles
.des-overview_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  background-color: #F3F2F2;
  padding: 1rem 1rem;
  box-shadow: 0px 0px 5px 1px #DDDBDA;
  border: 1px solid #DDDBDA;
  margin-top: 3px;

  // Title styles
  &_title {
    color: $primary-blue;
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    margin-right: 2rem;

    .slds-button__icon {
      height: 2rem;
      width: 2rem;
    }
  }

  // Middle container styles
  &_middleContainer {
    display: flex;
    max-width: 30rem;

    #createBy-search,
    #updatedBy-search,
    #dropdown-search {
      flex: 1 1;
      width: 16rem;
      button {
        color: $dark-gray;
        width: 100%;
        justify-content: flex-start;
        svg {
          margin-left: auto;
        }
      }
    }

    #dropdown-search {
      transition: 0.2s cubic-bezier(0.62, 0.65, 0.88, 1.14);
      width: 10rem;
      font-size: small;

      .des-select__criteria-button {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;
      }
    }

    .des-input__name-search {
      flex: 1 1;
      width: 16rem;
    }
  }

  // Button styles
  &_button {
    display: flex;
    min-width: 14rem;
    justify-content: flex-end;

    .slds-icon_container {
      margin: 5px;
    }

    .guidance-parent-new-selection {
      position: absolute;
      .guidance-tip-container {
        position: absolute;
        top: 1.8em;
        right: 0;
        z-index: 3;
      }
    }

    .des-new-selection-btn {
      width: 12rem;
    }

    .des-calendar-view-btn {
      margin-right: 1rem;
      padding: 0 0.5rem;
      color: var(--darkGray);
    }
  }

  // Selection switch group styles
  #selection-wf-switch-group {
    width: 25em;

    #selections-section,
    #waterfall-selections-section {
      flex: 50%;
    }
  }

  // Calendar view styles
  #calendar-view-for-schedules {
    margin-right: 0.75rem;

    .slds-icon_container {
      margin: 0;
    }
    .slds-icon-standard-account {
      background: #fff;
    }
    .slds-icon {
      fill: var(--darkGray);
      padding: 3px;
    }

    .upgrade-badge-icon {
      top: -8px;
      right: -13px;
    }

    .tooltip-container {
      margin: 0px;
      top: -1em;
      right: -0.5em;
      position: absolute;
    }
  }
  #calendar-view-for-schedules.slds-button_neutral {
    padding: 0;
  }

  .overview-title-section-wrapper {
    display: flex;
    justify-content: space-between;
  }

  // Unavailable feature styling
  .unavailable-feature {
    background: var(--disabledGrayOverlay);
    cursor: not-allowed;

    .slds-button__icon{
      opacity: 0.5;
    }
    .slds-icon-standard-account {
      background: rgba(0, 0, 0, 0.341);
    }

    .tooltip-container {
      position: absolute;
      top: -1em;
      right: -0.8em;
    }
  }

  // Selection count limits styling
  .selection-count-limits {
    margin: 0 0.5em;
    text-align: center;
    font-weight: bold;
  }

  // Styles for the navbar
  .navbar-css {
    margin-right: 10em;
  }

  // Helper class for expanding elements
  .makeLonger {
    flex: 0.8 !important;
  }
}
