.cancel-save-retention {
    margin-bottom: 1rem;
}

.toggle-retention-policy {
    margin-left: 2.2rem;
}

.mb-0px {
    margin-bottom: 0px !important;
}
.wrapper-firstPart .after-number-input {
    width: 50% !important;
}
.admin-settings-width {
    width: 90% !important;
}
.elements-position {
    margin-top: -7px !important;
}
.slds-flex-container {
    display: flex;
    gap: 5px;
}
.slds-flex-item {
    flex: 1;
}
.slds-full-width {
    width: 100% !important;
}
.admin-settings-small-text {
    font-size: 12px !important;
    color: #222 !important;
}
.admin-settings-small-text {
    font-size: 12px !important;
    color: #222 !important;
}

.admin-settings-small-text span.radio-label {
    font-size: 13px !important;
}

#container-data-retention-policy .slds-modal__content .DRP-part-two_2 .period-container .slds-form-element .wrapper-firstPart .group-slds-after .slds-form-element .slds-form-element__control .slds-select_container select {
    font-size: 13px !important;
}
