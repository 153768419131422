.filter-line-high-level-container {

  // Criteria select
  .filter-criteria-col {
    width: 21%;
    padding-right: 5px;
    min-width: 10rem;
    display: flex;
    height: 2rem;

    &.render-merge-icon {
      width: 21.7%;
      transform: translateX(-.01rem);
    }

    select {
      width: 100%;
      border: 1px solid var(--lightGrayColdBorder);
      padding: 0rem 0.75rem;
      border-radius: .25rem;
      line-height: 1.5rem;
    }

    .slds-form-element:nth-child(1) {
      min-width: 44%;
    }

    .slds-form-element {
      width: 100%;
    }
  }
}


.filter-line {
  padding: .5rem;
  margin: 0rem;
  border-bottom: 1px solid var(--blackOpacity125);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  line-height: 1rem;

  .collection-alias {
    line-height: 19px;
  }

  .icons-container {
    margin-top: 2px;

    .upgrade-badge-icon{
      position: relative;
    }
  }

  .target-collection-dropzone {
    width: 100%;
  }

  .subquery-dropzone {
    display: initial;
  }

  #target-DE-dropdown {
    width: 100% !important;
  }

  &_filter-name {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1;
    line-height: 1rem;
    min-width: 9.8rem;

    b {
      font-weight: 700;
      word-break: break-word;
      overflow: hidden;

      .collection-alias {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
      }
    }

    .with-padding-top-8 {
      padding-top: 8px;
    }
  }

  // Formula button
  .formula-button {
    margin-left: .3rem;
    width: 2rem;
    height: 2rem;
    position: relative;

    img {
      position: absolute;
    }
  }

  .formula-button-selected {
    background-color: var(--greenActive);

    &:hover {
      background-color: var(--primaryGreen);
    }
  }
}

.timezone-icon-container {
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 4;

  .timezone-icon {
    fill: #005fb2;
    height: 1.875rem;
  }

  .timezone-icon-active {
    fill: #4BCA81;
    height: 1.875rem;
  }
}

.datepicker-container {
  width: 100%;

  .first-child {
    width: 100%;
    display: flex;
  }
}

.timezone-text {
  display: inline-block;
  font-size: 0.9rem;
  margin-top: 5px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transformed {
  transform: translateX(-.4rem);
}

.between-datepicker-container-2 {
  display: flex;
}

// Remove filter button
.remove-filterline {
  padding: 0 0.5rem;
  display: flex;
  align-self: center;

  &.in-relation {
    margin-top: 0.4rem;
  }

  &.multiselect {
    align-self: flex-start;
  }

  .remove-line {
    padding: 0;
    border: none;
    background: none;
  }
}

.filter-line_filter-name {
  line-height: 1.5rem;

  .filter-label {
    color: var(--blackLabel);
  }
}

// Date filter is-element
.filter-line-text {
  font-size: 1em !important;
  padding: 6px 0;
  text-align: center;
}

.filter-line-and-text {
  margin: 0;
  margin-right: 10% !important;
  padding: 7px 0;
}

.filter-line-is-text {
  margin-right: 10px;
}

.align_self-flex_start {
  display: flex;
  align-self: flex-start;
}

.align_self-baseline {
  display: flex;
  align-self: baseline;
}

.align_self-center {
  display: flex;
  align-self: center;
}

.tooltip-span-container {
  position: relative;
  top: -10px;
  right: 0;
}

.full-sized-div {
  width: 100% !important;
}

.filter-line-date-start-text {
  padding-left: 5px;
}

.filter-value {
  width: 100%;
}

//Field where you type your filters
.filter-value-col {
  min-height: 2rem;
  max-height: max-content;
  display: flex;
  width: 49.5%;
  align-items: center;
  position: relative;

  &.render-merge-icon {
    width: 58.8%;
  }

  &.isSubQuery {

    .react-datepicker-popper {
      transform: translate3d(0rem, 2.1rem, 0px) !important;
    }

    .react-datepicker__triangle {
      margin-left: 50% !important;
    }
  }

  .slds-form-element {
    width: 100%;
    margin-bottom: 0px !important;
  }

  select.filter-value {
    line-height: 1.5rem;
  }

  input {
    padding-right: 0;
  }

  .compare-fields-filter-toggle {
    display: flex;
    cursor: pointer;
    height: calc(1.875rem + (1px * 2));

    button {
      width: 2rem;
      margin-right: .3rem;
      padding: 1px 9px;
    }
  }

  .compare-fields-filter-input-container {
    display: flex;
    width: 100%;

    .slds-form-element:nth-child(1) {
      margin-right: .3rem;
    }

    .slds-form-element {
      .slds-form-element__control {
        .slds-select_container {
          select {
            line-height: 1.2rem;
          }
        }
      }
    }
  }

  .dynamic-date-type-container {
    margin: 0 1rem 0 0;

    .dynamic-date-calendar-label, .dynamic-date-relative-label {
      label {
        margin-top: 0;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50%;

        .slds-form-element__label {
          margin: 0 0.5rem;
          padding: 0;
        }

        .radio-label {
          vertical-align: baseline;
        }

        span {
          white-space: nowrap;
        }

        span:nth-child(2){
          transform: translateY(-.1rem);
        }

        input {
          width: 1rem;
          height: 1.5rem !important;
        }
      }
    }
  }
}

.draggableDiv__without-subQuery-filter-icon {
  width: calc(49.5%) !important;
  margin-right: 2.7rem;
}

.draggableDiv__with-subQuery-filter-icon {
  .slds-form-element {
    padding-right: 0.2rem;
  }
}

.draggableDiv__isSubQuery {
  margin-right: 0;
}

// Date picker input
.react-datepicker-wrapper {
  width: -webkit-fill-available;
  max-width: 77%;
  display: flex !important;
  height: 2rem;
}

.react-datepicker__input-container {
  width: -webkit-fill-available;
  display: flex !important;

  input {
    border: none;
    border-radius: .25rem;
    border: 1px solid var(--lightGrayBorder);
    padding-left: .5rem;
  }
}

// Date picker DATE
.react-datepicker-popper {
  left: -22px;

  div {
    .react-datepicker {
      .react-datepicker__month-container {
        .react-datepicker__header {
          font-family: Salesforce Sans, Arial, sans-serif !important;
          background-color: var(--white);
          font-size: .7rem;
          border-bottom: none;

          .react-datepicker__current-month {
            font-size: .75rem;
            font-weight: 400;
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              margin: .7rem 0.33rem 0.23rem .33rem;
              color: var(--primaryDarkGray);
            }
          }
        }

        .react-datepicker__month {
          margin-top: 0;

          .react-datepicker__week {
            .react-datepicker__day {
              margin: 0.23rem .33rem;
              font-family: Salesforce Sans, Arial, sans-serif !important;
              border-radius: 50%;

              &.react-datepicker__day--selected {
                background-color: var(--primaryBlue) !important;
                color: var(--white);
              }

              &.react-datepicker__day--today {
                background-color: var(--lightGreyedOut);
                font-weight: 400;
              }
            }

            .react-datepicker__day--outside-month {
              opacity: .3 !important;
            }
          }
        }
      }

      .react-datepicker__input-time-container {
        font-family: Salesforce Sans, Arial, sans-serif !important;
      }
    }
  }
}

.subquery-dropzone {
  box-shadow: inset 0 0 8px var(--blackBoxShadow);
  width: max-content;
  background-color: var(--dropzoneLightGrayBackground);
  display: flex;
  justify-content: center;
  border-radius: .25rem;
  transition: .3s all;
}

.forceWidth {
  width: 54.9% !important;
  min-width: 54.9%;
}

.subquery-dropzone:nth-child(2) {
  margin: 0;
}

.subquery-dragged-field {
  background-color: var(--white);
  box-shadow: none;
  display: flex;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--lightGrayColdBorder);
  border-radius: .25rem;

  .remove-field-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2rem;
  }
}


.remove-field-target-de {
  border: none;
  padding: 0;
  background: none;
  margin-right: .8rem;
  text-align: right !important;
  display: flex;
  margin-left: .5rem;
}

.subquery-p {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2.8px;
  width: 100%;
  font-size: .7rem;
  line-height: 2rem;
  border: .1rem dashed transparent;
  transition: .3s all;

  &.greenBackground {
    background-color: var(--greenActive);
    color: var(--white);
  }
}


.subquery-field {
  margin-left: .3rem;
}

select.subquery-field {
  line-height: 1.5rem;
}

.align-items-center {
  align-items: center;
}

// Move filters icon
.icons-container {
  display: flex;

  &.render-merge-icon {
    transform: translateX(-1rem);
  }

  .drag-and-drop {
    margin-top: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: .3rem;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .add-logic-link {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    margin-left: 0.5rem;
    cursor: pointer;
    background-color: var(--primaryLightGray);
    color: var(--grayBorder);
  }
}

.subquery-filter {
  margin-left: .6rem !important;
  width: 2rem;
  height: calc(1.875rem + (1px * 2));

  &_green {
    background: var(--greenActive);
    border-color: var(--greenActive);

    &:hover,
    &:focus {
      background: var(--primaryGreen);
      border-color: var(--primaryGreen);
    }
  }
}

.dynamic-date-parameters-container {
  display: flex;
  width: 100%;
  padding: 0;

  input {
    margin-right: .3rem;
    transform: translateX(-.4rem);
  }

  .slds-form-element:nth-child(2) {
    margin-right: .3rem;
  }
}

.dynamic-date-date-value {
  text-align: center;
}

.dynamic-date-filter-interval {
  padding: 0;
}

.dynamic-date-date-value-start {
  padding: 0;
}

.filter-value-small-container {
  display: flex;
  flex-flow: row;
  width: -webkit-fill-available;
  width: -moz-available;

  .slds-form-element {
    input {
      width: 100%;
    }
  }

  .slds-form-element:nth-child(1) {
    input {
      width: 98%;
    }
  }
}

.filter-value-small {
  width: 50%;
  margin-right: 10px;
}

.filter-merge-candidate {
  background-color: var(--primaryLightGray);

  .add-logic-link {
    background-color: var(--lightOrangeBorder);
    color: var(--white);
  }
}

.formula-class {
  display: flex;
  margin: .65rem 0;
  text-align: justify;

  .formula-radio {
    min-width: 1.7rem;
    min-height: 1.1rem;
    transform: translateY(.07rem);
  }

  label {
    padding-left: .6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p:nth-child(1) {
      font-weight: 700;
    }
  }
}

.multiselect-picklist-container {
  display: flex;

  .dropdown-menu div:has(.ReactVirtualized__List) {
    height: unset !important;
  }

  .dropdown-menu {
    .ReactVirtualized__List {
      height: unset !important;
    }
  }

  .multiselect-picklist-div {
    flex: 1;
    margin-right: 5px;
    max-width: 100%;
  }

  .input-picklist-toggle {
    display: flex;
    cursor: pointer;
    height: calc(1.875rem + (1px * 2));

    button {
      width: 2rem;
      margin-right: .3rem;
      padding: 1px 9px;
    }
  }
}

.inResultsFormulaFilterLine-container {
  .filterline-container.single_filterline {
    margin-left: 4em;
  }
}

.toggle-formula-container-button {
  border: none;
  padding: 0;
  background: none;
  margin-left: .6rem;
  font-size: 1.6em;
  display: flex;
}
