.banner {
    &_header {
      background-color: #D8EDFF;
      padding: .75rem 0;
      position: relative;
      z-index: 1;
      margin-top: .7rem;
    }
  
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: .3rem;
      margin: 0 auto;
      width: 98.3%;
    }

    &_header_limits {
      background-color: #feefb4ff;
      padding: .75rem 0;
      position: relative;
      z-index: 1;
      margin-top: .7rem;
      margin-bottom: .7rem;
    }

    &_limits_wrapper {
      display: flex;
      align-items: center;
      border-radius: .3rem;
      margin: 0 auto;
      width: 98.3%;
    }
  }

  .banner-title {
    display: flex;
    justify-content: space-between;
  }

  .banner-limits-title {
    font-size: large;
    font-weight: bold;
    padding: 0 30px 0 30px;
  }

  .banner-limits-body {
    padding-right: 30px;
  }

  .banner-limits-chip {
    margin: 0 5px 0 5px;
    font-weight: lighter;
  }
  
  .banner-limits-values {
    font-size: small;
    padding-right: 30px;
    display: flex;
    align-items: center;

  }

  .banner-limits-limit {
    padding: 0 .5rem 0 .5rem;
    display: flex;
    align-items: center;

  }

  .banner-span {
    margin-left: 0.5em !important;
  }

  .remove-tab {
    color: gray;
    margin-left: 0.3rem;
    border: none;
    background-color: transparent;
    outline: none;
    transform: translateY(0.03rem);
    :hover {
    color: var(--darkRed) !important;
    }
  }

  #banner {
    background-color: var(--primaryGreen);
    border: none;

    &:hover {
        background-color: var(--greenActive);;
    }
}

.banner_buttons {
  display: flex;
  width: 15%;
  justify-content: flex-end;
  position: relative;
}

.banner-limits-buttons {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
