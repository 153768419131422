.waterfall_view_new_v2 {
    width: 100%;
    border-radius: .3rem;
    min-height: calc(100vh - 147.5px);

    .waterfall_wrapper {
        display: flex;
        width: 100%;
    }

    &.run_details {
        .waterfall_wrapper {
            display: flex;
            flex-direction: column;

            &.run-details-wrapper {
                background: #EAF0F8;
                height: calc(100vh - 6.3rem);
                overflow: auto;
                overflow-x: hidden;
            }
        }

        .selection-chain-container {
            width: 60%;
            margin: 0 auto;
            margin-top: 1.5rem;
            background: #EAF0F8;
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;

            &.not-running {
                flex: 1 1 auto;
                margin-top: 3rem;
            }
        }

        .cart-results-container-v2 {
            width: 60%;
            margin: 0 auto;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            .without-results-wrapper-v2 {
                p {
                    font-weight: 500;
                    letter-spacing: 0.1rem;
                    line-height: 2rem;
                    text-align: center;
                    padding: 1rem;
                    color: var(--grayBorder);
                    text-transform: uppercase;
                    border: .1rem dashed transparent;
                    transition: .3s all !important;
                    border: var(--transparentBorder);
                    position: absolute;
                    width: 58% !important;

                    @media (max-height: 460px) {
                        position: relative;
                        top: 0;
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar-track {
        background-color: var(--lightGrayScrollTrack);
        border-radius: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
        border: 1px solid var(--lightGrayScrollTrack);
        background-color: var(--darkenLightGrayScrollThumb);
        border-radius: 0.3rem;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }

    .archived-warning {
        margin-bottom: 2rem;
        margin-top: 56px;
    }
}

.selection-container-style {
    width: 100%;
}

.isArchived-warning {
    display: flex;
    flex-direction: column;
}