.waterfall_view_new_v2 {
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;

    .warning-notification {
        .warning-run-different-selections {
            margin: 0.5rem 0;
        }
    }

    .archived-warning-run-view {
        margin-top: 56px;
    }
}
