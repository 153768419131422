// Variables
$border-color-blue: #014486;
$text-color-dark-blue: #00396b;
$divider-color: #cedaec;
$hover-color: #D8EDFF;
$hover-transition: all 0.2s ease-in-out;

.rightbar-v2 {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 700;
  background-color: var(--mostlyWhiteBackground);
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  width: var(--newRightbarWidth);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  &--expanded {
    width: var(--newRightbarWidthExpanded);
    align-items: flex-start;

    .rightbar-v2__toggle {
      justify-content: flex-start;
      padding-left: 10px;

      .slds-popover_tooltip {
        left: calc(100% + 1rem);
        right: auto;
      }
    }

    .rightbar-v2__button {
      justify-content: flex-start;
      padding-left: 20px;

      span {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__toggle {
    width: 100%;
    display: flex;
    justify-content: center;
    transition: justify-content 0.3s ease, padding-left 0.3s ease;
    position: relative;

    .slds-popover_tooltip {
      position: absolute;
      margin-top: 1rem;
      padding: 0.5rem;
    }

    &-button {
      margin-top: 20px;
      background-color: #FFFFFF;
      border: 1px solid $border-color-blue;
      border-radius: 30%;
      padding: 0.3em;
      transition: $hover-transition;

      &:hover {
        background-color: $hover-color;
        transform: scale(1.05);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &:not(&--expanded) {
    .rightbar-v2__toggle {
      .slds-popover_tooltip {
        right: calc(100% + 1rem);
        left: auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
  }

  &__button {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.9em;
    cursor: pointer;
    transition: $hover-transition, justify-content 0.3s ease, padding-left 0.3s ease;

    &:hover {
      background-color: $hover-color;
      transform: translateX(5px);

      .slds-icon {
        transform: scale(1.1);
      }

      span {
        color: $border-color-blue;
      }
    }

    .slds-icon {
      margin-right: 0.6em;
      position: relative;
      fill: $text-color-dark-blue;
      transition: $hover-transition;
    }

    span {
      margin-left: 10px;
      font-size: 0.9rem;
      color: $text-color-dark-blue;
      opacity: 0;
      transform: translateX(-10px);
      transition: $hover-transition, opacity 0.3s ease, transform 0.3s ease;
      white-space: nowrap;
    }
  }

  &__columns-menu {
    padding: 1rem;
    width: 250px;
    max-height: 80vh;
    overflow-y: auto;

    .expand-column-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .expand-column-label {
        font-family: var(--primaryFont);
        color: #928f8b;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .column-item {
      display: flex;
      padding: 8px 12px 16px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      justify-content: space-between;

      &:hover {
        background-color: $hover-color;
      }

      .drag-and-drop {
        border: unset;
        width: 1rem;
        padding: unset;
        display: block;
        cursor: move;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }


  .des-popover-menu__divider {
    border-top: 1px solid $divider-color;
    margin: 0.1em 0;
  }
}

// Popover styles
.rightbar-v2__popover {
  > div:first-child {
    width: 100%;
    display: flex;
    margin-right: 1em;
  }

  #edit-columns-popover {
    .slds-popover__body {
      padding: 0;
    }

    .rightbar-v2__button-icon {
      width: 1.5rem;
    }

    .slds-popover {
      max-width: none;
      width: 300px;
    }
  }
}

// Checkbox styles
.folder-selection-checkbox {
  .slds-form-element__label {
    font-size: 0.875rem;
    color: $text-color-dark-blue;
  }

  .slds-checkbox_faux {
    border-color: $border-color-blue;
  }
}

// Responsive styles
@media (max-height: 600px) {
  .rightbar-v2 {
    &__columns-menu {
      max-height: 70vh;
    }
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rightbar-v2__columns-menu {
  animation: fadeIn 0.3s ease-in-out;
}