.good-to-know-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  position: relative;
  border: unset !important;
}

.good-to-know-dark-mode {
  background: var(--color-surface, #F4F7FB);
}

.good-to-know-light-mode {
  background: var(--color-surface, #FFFFFF);
}

.good-to-know {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 7px;
  border: unset !important;

  &_border {
    border-radius: 7px;
    border: 1px solid var(--color-stroke, #CEDAEC);
  }

  .light-bulb {
    width: 20px;
    height: 20px;
    fill: #778DAC;
    border: unset !important;
  }

  .text {
    width: 500px;
    color: var(--color-text, #495D7A);
    font-family: var(--primaryFont);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border: unset !important;

    .title {
      border: unset !important;
      font-size: inherit;
    }

    .second-subtext {
      color: var(--color-text, #495D7A);
      font-family: var(--primaryFont);
      font-size: inherit;
      border: unset !important;
    }
  }
}