#newAutoCreatedTDE-folder-modal {
    left: 24rem;

    .de-name-error-alert {
        margin-top: 0.3em;
    }
}

// second modal
#second-modal {
    z-index: -10;
    visibility: hidden;

    .slds-modal__container {
        .slds-modal__content {
            min-height: 23.8rem;
            height: 25.6rem;
        }
    }

    &.show {
        z-index: 9500;
        visibility: visible;
    }
}

.copySelection-modal {
    .slds-backdrop_open {
        visibility: hidden;
    }
}

.new-target-de-location {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
}

.folder-with-icon {
    display: flex;
    align-items: center;

    .new-target-de-location-name {
        max-width: 18rem;
        overflow-wrap: break-word;
        margin-top: -3px;
    }
}

.change-copy-target-de-location-v2,
.change-new-target-de-location-v2 {
    line-height: 1rem;
    height: 1.5rem;
    margin-top: -3px;
    margin-right: 6px;
}

.name-copy-targetDE {
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;

    span {
        flex-basis: 60%;
    }

    .slds-required {
        margin: 0;
        left: -0.5rem;
        position: absolute;
    }

    .slds-form-element__control {
        width: -webkit-fill-available;
    }
}

.copy-target-de-label {
    font-weight: 600;
}

.left-8px {
    left: 8px !important;
}

.pl-2rem {
    padding-left: 2rem !important;
}

.content-spinner {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .slds-spinner_x-small {
        width: 1rem;
    }
}

.content-loading-search-folders {
    height: 100%;

    .loading-available-folders-spinner {
        left: 50%;
        top: 54%;
    }
}

.loading-available-folders-spinner {
    left: 35%;
    top: 50%;
}

.loading-available-folders-label {
    font-size: 1.2rem;
    margin-left: 3.5rem;
}

#available-DEs-folders {
    #cancel-btn {
        float: left;
    }

    #available-folders-modal-content {
        height: 100%;
    }
}

.new-target-de-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 5rem;

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .title {
            margin-bottom: 10px;
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;

        .container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            width: 100%;

            .slds-form-element__control {
                width: 100%;
            }

            .de-name-error-alert {
                width: 100%;
            }
        }

        .location-container {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .options-container {
            gap: 8px;
            align-self: stretch;

            .subcontainers {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                border-radius: 7px;
                background: var(--color-surface, #F4F7FB);

                .slds-checkbox_toggle {
                    justify-content: space-between;
                }

                p {
                    color: var(--color-text, #495D7A);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    align-self: stretch;
                    letter-spacing: 0.26px;
                    text-align: left;
                }
            }
        }

        .footer {
            justify-content: space-between;
            display: flex;
            width: 100%;
        }
    }
}

.change-location-target-de {
    z-index: 9999999999 !important;
    left: -7%;
    top: 10%;
}

.slds-modal__container {
    width: 28rem;

    .slds-modal__content {
        .empty-DE_name_error-space {
            height: 1rem;
        }

        .slds-form-element {

            &.space-for-error {
                height: 6rem;
            }

            label {
                abbr {
                    text-decoration: none;
                }
            }
        }

        .new-target-de-location {
            display: flex;
            align-items: center;

            i,
            div {
                margin-right: .5rem;
            }
        }

        .new-target-de-label {
            margin-top: .6rem;
        }

        .checkbox-container {

            .checkbox-sendable,
            .checkbox-testable {
                font-size: 1rem;
            }
        }

        .policy {
            display: flex;
            align-items: center;
            margin-top: .1rem;

            .slds-button {
                margin-left: 1.5rem;
            }
        }
    }
}