.available-selection-v2 {
  margin-top: -5rem;
  border-right: 1px solid var(--lightGrayBorder);

  .stickyAE {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0%;
    height: 100%;
  }

  .available-selection-section {
    display: flex;
    height: 100%;
  }

  .section-title {
    white-space: nowrap;
    padding: 0.8rem 0;
    color: var(--blackTitle);
    border-radius: 0.2rem;
    font-weight: 600;
  }

  .overview_folders_wrapper {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.2rem;
  }

  .overview_folders_wrapper-hide {
    left: 15.7rem !important;

    &.hide {
      left: -3.87rem !important;
    }
  }

  .created-folders {
    height: calc(100vh - 11rem);
  }
}

.available-selection-v2-isArchived {
  margin-top: 0.7rem;
}