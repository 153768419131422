// Default variables for button sizes
$default-vertical-padding: 0rem;

// Variables for button font sizes
$extra-small-font-size: 0.625rem; // 10px
$small-font-size: 0.75rem; // 12px
$medium-font-size: 1rem; // 15px,
$large-font-size: 1.125rem; // 18px
$extra-large-font-size: 1.375rem; // 22px

// Variables for button horizontal paddings
$extra-small-horizontal-padding: 0.5rem;
$small-horizontal-padding: 0.75rem;
$medium-horizontal-padding: 1rem; // This is the default padding
$large-horizontal-padding: 1.25rem;
$extra-large-horizontal-padding: 1.5rem;

// Variables for button vertical paddings
$extra-small-vertical-padding: $default-vertical-padding;
$small-vertical-padding: $default-vertical-padding;
$medium-vertical-padding: $default-vertical-padding;
$large-vertical-padding: 0.15rem;
$extra-large-vertical-padding: 0.25rem;

// Mixin for button sizes
@mixin button-size($font-size, $horizontal-padding, $vertical-padding: $medium-vertical-padding) {
  padding: $vertical-padding $horizontal-padding;
  font-size: $font-size;
}

// Base button style for medium, which is the default
.des-button {
  @include button-size($medium-font-size, 1rem);
  // Define common button styles here, e.g., border, background-color, text color, etc.

  // Nested icon styles specific to the .des-button block
  .slds-tooltip-trigger {
    .slds-button_icon {
      padding-left: 0;
      padding-right: 0;
      left: 5px;
      border: none;
    }
  }

  &:disabled {
    cursor: not-allowed
  }
}

// Extra small button styles
.des-button--x-small {
  @include button-size($extra-small-font-size, $extra-small-horizontal-padding, $extra-small-vertical-padding);
}

// Small button styles
.des-button--small {
  @include button-size($small-font-size, $small-horizontal-padding, $small-vertical-padding);
}

// Large button styles
.des-button--large {
  @include button-size($large-font-size, $large-horizontal-padding, $large-vertical-padding);
}

// Extra large button styles
.des-button--x-large {
  @include button-size($extra-large-font-size, $extra-large-horizontal-padding, $extra-large-vertical-padding);
}
