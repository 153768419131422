.available-extension-v2 {
  width: 21em;
  margin-left: 5px;
  height: 93.2%;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow: auto;

  .disabled-overlay-cv{
    position: absolute;
    width: 100%;
    height: 40%;
    background-color: var(--disabledGrayOverlay);
    z-index: 1;
    cursor: not-allowed;
  }

  .stickyAE {
    height: 100%;
    display: flex;
    flex-flow: column;

    .button-group-tde {
      margin-top: 10px !important;
    }
  }

  .custom-values-btn{
    .slds-popover{
      width: 60%;
    }
  }

  button {
    font-family: "SF Pro", Arial, sans-serif !important;
  }

  .section-title {
    white-space: nowrap;
    padding: 0.8rem 0;
    color: var(--blackTitle);
    border-radius: 0.2rem;
    font-weight: 700;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    font-family: var(--primaryFont);
    color: #495D7A;
    margin-left: 2px;
    padding-bottom: 0;
    margin-bottom: 14px !important;
    margin-top: -8px !important;

    .guidance-tip-container {
      display: inline-block;
      margin: 0;
      position: absolute;
      top: 0;
    }
  }

  &_input_wrapper {
    display: flex;
    overflow: hidden;

    input {
      width: 100%;
      min-width: 0;
      padding: 0.2rem 0.2rem 0.2rem 1rem;
      border: none;
      border-top-left-radius: 0.25rem;

      &::placeholder {
        color: var(--blackOpacity50);
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: 0.7rem 0.8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  &_available_collections_new {
    scrollbar-width: thin;
    margin-left: -11px !important;
    width: 100%;
  }

  &_loader {
    position: relative;

    .demo-only {
      height: 14.8rem;

      .slds-spinner {
        top: 47%;
        position: absolute;
        z-index: 1;
      }
    }
  }
}

.hide-available-extension-v2 {
  animation: smooth-disappear-available-exensions-v2 1s ease-in forwards;
  animation-delay: 0.1s;
}

.hide-available-extension-v2-immediately {
  visibility: hidden;
  display: none;
}

@keyframes smooth-disappear-available-exensions-v2 {
  from {
    visibility: unset;
  }

  to {
    visibility: hidden;
    display: none;
  }
}

.stickyAE-appear-animation {
  animation: smooth-appear-stickyAE 1s ease-in forwards;
  animation-delay: 0s;
}

@keyframes smooth-appear-stickyAE {
  from {
    visibility: hidden;
    opacity: 0;
    display: none;
    margin-top: -70rem;
  }

  to {
    visibility: unset;
  }
}

.stickyAE-disappear-animation {
  animation: smooth-disappear-stickyAE 1s ease-out forwards;
  animation-delay: 0.3s;
}

.stickyAE-disappear-animation-immediately {
  visibility: hidden;
  opacity: 0;
  display: none;
  margin-top: -70rem;
}

@keyframes smooth-disappear-stickyAE {
  from {
    visibility: unset;
  }

  to {
    visibility: hidden;
    opacity: 0;
    display: none;
    margin-top: -70rem;
  }
}


.available-folders-icon {
  font-size: 1.3rem;
  margin-left: 12px;

}

.cursor-pointer {
  cursor: pointer;
}

.available-folders-filter-icon {
  width: 2.4rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: border 0.15s linear;
  background-color: var(--white);
  border-color: var(--primaryBlue);
  color: var(--primaryBlue);
  border: none;

  &:hover,
  &:focus {
    background: var(--lightGrayHover);
  }
}

.disabled-available-folders-filter-icon-v2 {
  cursor: not-allowed !important;
}

.border-top-right-radius-0px {
  border-top-right-radius: 0px !important;
}

.disabled {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: flex-start;
  position: -webkit-sticky;
  top: 0;
  cursor: default !important;
}

.h-17rem {
  height: 17.2rem !important;
}

.data-sets-tab {
  display: flex;
}

.data-sets-message {
  font-size: 16px;
  text-align: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  font-family: var(--primaryFont);
  color: #495D7A;
  margin-left: 6px;
}

.section-notification {
  font-size: 0.9rem;
  color: var(--primaryGreen);
  margin-bottom: 0.8rem;
}

.des-make-space-for-scroll {
  width: 100% !important;
  overflow-y: auto;
  height: 90%;
  scrollbar-width: thin;
  display: flex;
  flex-flow: column;
  scrollbar-gutter: stable both-edges;
}

.available-extension-v2_available_collections {
  width: 100% !important;
}

.des-line {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.des-search-position-v2 {
  width: 90.1%;
  margin-bottom: 2px;
  color: #495D7A !important;
  margin-left: 2px !important;
}

.de-dv-button-group {
  width: 14rem;

  .de-button {
    width: 8rem;
    padding: 0;
  }

  .dv-button {
    width: 6.5rem;
    padding: 0;
  }
}

.data-set-button {
  width: 6.7rem;
  padding: 0;
  margin-left: -2px !important;
}
.main-search-container {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.filter-icon-v2 {
  margin-left: 2px !important;
  margin-top: 2px !important;
}
