.dataExtension_multipleTabs_v2 {
  margin: 0 auto;
  width: 100%;
  padding-left: 0 8px;
  height: 96%;
  margin-top: 1px;

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  .data-extensions-area-collapsed {
    height: 0%;
  }

  .data-extensions-area-expanded {
    height: 100%;
  }

  .slds-tabs_scoped {
    border-top-left-radius: .3rem !important;
    border-top-right-radius: .3rem !important;
    border: none;
    height: 100%;

    .tab-panel {
      height: 100%;
      padding-left: 0 !important;
    }

    ul {
      scrollbar-width: thin;
    }

    .slds-is-active {
      a {
        .remove-tab {
          i {
            color: var(--primaryBlack) !important;
          }
        }
      }
    }

    // Tabs
    .slds-tabs_scoped__nav {
      border-top-left-radius: .3rem !important;
      border-top-right-radius: .3rem !important;
      border: none;


      li {
        max-width: 19.4rem;

        a {
          display: flex;
          align-items: center;
          border-right: 1px solid var(--lightGrayBorder);
          border-left: 1px solid var(--lightGrayBorder);

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 1rem;
          }

          .remove-tab {
            color: gray;
            margin-left: .1rem;
            margin-right: -.3rem;
            border: none;
            background-color: transparent;
            outline: none;
            transform: translateY(0.03rem);

            :hover {
              color: var(--darkRed) !important;
            }
          }

          &.disable-mouse-events {
            pointer-events: none;
            opacity: 0.7;
          }
        }
      }

      li.active {
        a {
          font-weight: 600;
          border-right: 1px solid var(--lightGrayBorder);
          border-left: 1px solid var(--lightGrayBorder);
        }
      }

      li:last-child {
        a {
          button {
            background-color: var(--lightGrayIconBackground);
            width: 2.5rem;
            height: 2.5rem;
            border: none;
            transform: translateY(-.5px);
          }

          :hover {
            background-color: var(--lightGrayIconBackgroundHover);
            border: none;
          }
        }
      }
    }

    // Content
    .slds-tabs_scoped__content {
      border: 1px solid var(--lightGrayBorder);
      display: none;
      background-color: transparent;
      border: none;
      padding: unset !important;
      height: 100%;

      &.active {
        display: block;
      }
    }
  }

  .guidance-parent-dataextension_tab {
    position: absolute;

    .guidance-tip-container {
      top: -2em;
      position: absolute;
      z-index: 3;
      left: 8em;
    }
  }
}

html .swal2-noanimation .swal2-actions {
  justify-content: flex-start;
  margin: 0 0.65rem;
}

.tab-submenu {
  &.down {
    height: 3rem !important;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slds-scrollable_x {
  display: none;
}

.des-main-line {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tabs-container {
  width: 21.65rem;
  border-bottom: 1px solid #D3D3D3;
  max-height: 20rem;
  border-right: unset;
  z-index: 99;
  background: white;

  .tabs-list {
    overflow: auto;
    max-height: 8rem;

    .source-main-container {
      display: flex;
      margin-right: 16px;
      margin-bottom: 0px;
      margin-top: 0px;
      height: 40px;
      padding: 8px 16px;
      border-radius: 5px;
      justify-content: space-between;
      margin-top: 10px;

      .source-container {
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .source-text {
          font-family: var(--primaryFont);
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: #495D7A;
        }
      }
    }

    .active-tab {
      background-color: #F4F7FB;
    }
  }

  .add-new-source {
    font-family: var(--primaryFont);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #747474;
    margin-left: 10px;
    margin-bottom: 11px;
    cursor: pointer;
    margin-top: 10px;
  }
}

.tabs-container-hidden {
  z-index: 0;
}

.react-flow__container {
  top: 17px;
}