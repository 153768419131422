.prio-type {
  div {
    .slds-radio_button-group {
      display: flex;

      span {
        flex: 1;
        text-align: center;

        label {
          width: 100%;
        }
      }
    }
  }
}

.getHelpText {
  margin-top: .3rem;
  font-size: .9rem;
  margin-bottom: 1.3rem;
  color: var(--color-text-subtle, #747474);
  font-feature-settings: 'clig' off, 'liga' off;
  line-height: 24px
}