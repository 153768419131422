.cart-results-container-v2 {
    width: 50%;
    margin: 5px 0.5rem;
    z-index: 99;

    .without-results-wrapper, .results-with-error-wrapper-new {
        border-radius: 40px;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height:100%;

        // &.true {
        //     min-height: calc(100vh - 160px);
        // }

        p {
            font-weight: 500;
            letter-spacing: 0.1rem;
            line-height: 2rem;
            text-align: center;
            padding: 1rem;
            color: var(--grayBorder);
            text-transform: uppercase;
            border: .1rem dashed transparent;
            transition: .3s all !important;
            border: var(--transparentBorder);
            position: fixed;
            top: 50%;
            z-index: -1;
            width: 47% !important;

            @media (max-height: 460px) {
                position: relative;
                top:0;
            }
        }

        .results-with-error-content-new {
            text-align: center;
            z-index: -1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div {
                width: auto;
                border-radius: 1rem;
            }

            .sad-penguin {
                width: 11rem;
                padding: 1rem;
            }

            .run-step-error {
                margin-right: 0.25rem;
                font-weight: 700;
                min-width: fit-content;
            }

            #waterfall-run-error-msg {
                display: flex;
                align-items: center;
            }

            h2:nth-last-child(1) {
                max-width: 45rem;
            }

            @media (max-height: 460px) {
                position: relative;
                top:0;
            }
        }
    }

    @keyframes showContent {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .funnel-chart, .results-with-error-wrapper-new {
        animation: showContent 0.25s ease-in-out both;
    }

    .funnel-chart {
        margin: -5px 0;

        ._1WnkD {
            clip-path: polygon(0 0, 100% 0, 60% 78%, 60% 100%, 40% 100%, 40% 78%);
        }

        ._1JWHC {
            height: 9.8rem;
            margin: 5px 0;
            color: var(--primaryBlack);

            > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        ._1WnkD, ._1JWHC {
            :hover {
                background-color: var(--primaryYellow) !important;
            }
        }
    }

    .loading-chart-container_new {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border: 1px solid var(--lightGrayBorder);
        height: 18rem;
        background: #FFFFFF;
        margin-top: 2em;
        padding: 1em;
        border-radius: 40px;
        margin-bottom: 2rem;

        .loading-indicator-wrapper {
            text-align: center;

            img {
                height: 15rem;
            }
            p {
                color: var(--grayBorder);
                text-transform: uppercase;
                letter-spacing: 0.1rem;
            }

            @media (max-height: 460px) {
                position: relative;
                top:0;
            }
        }
    }
}
