.preview-v2 {
  background-color: var(--white);
  margin: 0 auto 0 auto;
  width: 100%;
  position: relative;
  height: calc(100vh - 80.5px);
  border-top: 1px solid #CEDAEC;
  overflow-y: auto;
  padding: 0 1.1rem 0 1.1rem;

  .slds-builder-toolbar {
    position: relative;
    top: -10px;
    width: 100%;
    z-index: 1;
    border-left: 1px solid #D3D3D3;
    border-right: 1px solid #D3D3D3;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &_wrapperr-v2 {
    .slds-button {
      .preview-loader-container {
        display: flex;
        position: relative;

        .slds-spinner {
          position: absolute;
          left: 0;
        }
      }
    }

    button {
      margin-top: -2rem;
    }
  }

  .back-to-selection-button-text {
    color: var(--color-text, #495D7A);
    font-family: var(--primaryFont);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }

  .back-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #495D7A;
  }

  #progress-indicator-v2 {
    position: relative;
    transition: 1s all;
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: auto;
  }

  &_result {
    overflow: auto;
    margin-top: 8px;
    // height: calc(100vh - 20rem);

    &_title {
      padding-top: 4.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .number-of-records-and-last-run {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .number-of-records,
        .last-run {
          color: var(--color-text-emphasis, #032A58);
          font-family: var(--primaryFont);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          .info-icon {
            margin-right: 8px;
          }
        }

        .circle-icon {
          width: 10px;
          height: 10px;
        }
      }

      .play-icon {
        margin-left: 8px;
      }
    }

    &_table {
      table {
        text-align: center;
        margin: 0;
        width: 100%;
        border-top-width: thin !important;
        border-collapse: separate;
        border: solid var(--color-stroke, #CEDAEC) 1px;
        border-radius: 11px;

        .slds-notify {
          width: 80%;
          margin: 0 auto;
        }

        td,
        th {
          border-left: solid var(--color-stroke, #CEDAEC) 1px;
          border-top: solid var(--color-stroke, #CEDAEC) 1px;
        }

        th {
          background-color: var(--color-surface, #F4F7FB);
          border-top: none;
        }

        td:first-child,
        th:first-child {
          border-left: none;
        }

        th:first-child {
          border-left: none;
          border-top-left-radius: 11px;
        }

        th:last-child {
          border-top-right-radius: 11px;
        }

        thead {
          tr th {
            text-align: left;
            padding-left: 1.1rem;
            padding-right: 0;
          }

          &:last-child {
            padding-right: 1.15rem;
          }
        }

        tbody {
          tr {
            td {
              padding-left: 1.1rem;
              line-height: 1.1rem;
              padding: 12px 16px;
              align-items: center;
              gap: 8px;
              align-self: stretch;

              .slds-notify:hover {
                color: var(--black);
              }

              div {
                line-height: 1.2rem;
              }

              div:empty::after {
                content: "\00a0";
              }
            }

            td:last-child.move-number-right {
              div {
                margin-right: 1.15rem;
              }
            }

            td:nth-child(1) {
              padding-left: 1.1rem;
            }
          }
        }
      }
    }
  }

  .tooltip-one,
  .tooltip-two,
  .tooltip-three,
  .tooltip-four,
  .tooltip-five {
    padding-left: 2rem;
    padding-top: 5rem;
    position: absolute;
  }

  #btn-tooltip-one {
    position: absolute;
    top: -3.5rem;
    left: -2.8rem;
    white-space: nowrap;

    display: inline-flex;
    padding: 2px 12px;
    align-items: center;
    gap: 4px;

    border-radius: 40px;
    border: 1px solid var(--color-accent, #0064CC);
    background: #FFF;

    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  #btn-tooltip-two {
    left: -3rem !important;
  }

  #btn-tooltip-two,
  #btn-tooltip-three,
  #btn-tooltip-four,
  #btn-tooltip-five {
    position: absolute;
    top: -3.5rem;
    left: -3.3rem;

    display: inline-flex;
    padding: 2px 12px;
    align-items: center;
    gap: 4px;

    border-radius: 40px;
    border: 1px solid var(--color-accent, #0064CC);
    background: #FFF;

    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  #btn-tooltip-five {
    background: var(--color-accent, #0064CC);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .button-relative {
    position: relative;
  }

  .preview-tip-container {
    position: relative;
    max-width: 40%;
    margin: 0 auto;

    .message {
      position: relative;
      min-height: 1em;
      margin: 1em 0;
      background: #f8f8f9;
      padding: 1em 1.5em;
      line-height: 1.4285em;
      box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
      border-radius: 0.3rem;

      .header {
        color: var(--primaryOrange);
        font-size: 1.15em;
      }

      p {
        margin-top: 0.25em;
      }
    }

    .fade-in {
      transition: opacity 1s ease;
    }

    .fade-out {
      opacity: 0;
      transition: opacity 1s ease;
    }
  }

  .view-temporary-data-extension {
    margin-top: 10px;
    display: inline-block;
  }
}

.preview-data-action-info {
  margin-top: 1rem;
}

.move-number-right {
  text-align: right !important;
  padding-right: 16px !important;
}

#sql-query-modal {
  font-family: monospace;
}

.slds-modal__container {
  width: 35rem;
}

.sql-hl-keyword {
  color: var(--sqlBlue);
}

.sql-hl-function {
  color: var(--primaryRed);
}

.sql-hl-number {
  color: var(--primaryGreen);
}

.sql-hl-string {
  color: var(--primaryRed);
}

.sql-hl-special {
  color: var(--black);
}

.sql-hl-bracket {
  color: var(--black);
}

.slds-modal .slds-modal__container.sql-query-modal {
  @media (min-width: 55rem) {
    min-width: 50rem;
  }
}

.abbreviate-sql-query {
  word-wrap: break-word;
  line-height: 1.25rem;
  padding: 0.3rem;
}

#sql-query-modal span {
  white-space: pre-wrap !important;
}

.align-items {
  display: flex;

  .placeholder {
    height: 2rem;
  }
}

.preview-no-running {
  width: 395px;
  height: 330px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: auto;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;

  .preview-is-safe-place {
    width: 335px;
    height: 138px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .text-1 {
      color: var(--color-text-emphasis, #032A58);
      text-align: center;
      font-family: var(--primaryFont);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    .text-2 {
      width: 335px;
      color: var(--color-text, #495D7A);
      text-align: center;
      font-family: var(--primaryFont);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%
    }
  }

  .no-height {
    height: unset;
  }

  .good-to-know {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 7px;
    background: var(--color-surface, #F4F7FB);

    &_border {
      border-radius: 7px;
      border: 1px solid var(--color-stroke, #CEDAEC);
    }

    .light-bulb {
      width: 20px;
      height: 20px;
      fill: #778DAC;
    }

    .text {
      width: 335px;
      color: var(--color-text, #495D7A);
      font-family: var(--primaryFont);
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      .second-subtext {
        color: var(--color-text, #495D7A);
        font-family: var(--primaryFont);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .buttons {
      display: flex;
      height: 34px;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .preview-results-text {
        color: var(--color-neutral-light, #FFF);
        font-family: var(--primaryFont);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .bring-me-back-button-text {
        color: var(--color-text, #495D7A);
        font-family: var(--primaryFont);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .run-the-selection {
        color: var(--color-neutral-light, #FFF);
        font-family: var(--primaryFont);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 8px;
      }

      .eye-icon {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }

      .play-icon {
        width: 20px;
        height: 20px;
        fill: #495D7A;
        margin-right: 8px;
      }

      .run-selection-text {
        color: var(--color-text, #495D7A);
        font-family: var(--primaryFont);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.preview-running {
  display: none;
  width: 700px;
  height: 330px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: auto;
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;

  .happy-penguin,
  .sad-penguin {
    display: flex;
    width: 250px;
    height: 250px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .sad-penguin {
    width: unset;
  }

  .sad-penguin-container {
    text-align: -webkit-center;
  }

  .sad-penguin-alert {
    width: 50rem;
  }
}

.preview-running:has(.slds-progress) {
  display: inline-flex;
}

.preview-running:has(.happy-penguin) {
  top: 20rem;
  display: inline-flex;
}

.cancel-preview-button {
  color: var(--color-accent, #0064CC);
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.after-records-div {
  display: flex;
  padding: 24px;
  gap: 24px;
  border-radius: 7px;
  background: var(--color-surface, #F4F7FB);
  width: 500px;
  height: 200px;
  margin-top: 20px !important;
  position: relative;
  left: 50%;
  margin-bottom: 20px !important;
  margin: unset;
  top: auto;
  transform: translate(-50%, 0%);

  .preview-is-safe-place {
    width: 100%;
  }

  .play-icon {
    fill: white !important;
    margin-right: unset !important;
  }
}

.records-container {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 100px;
  position: relative;
  width: 324px;
  margin-top: 5rem;

  .no-records-generated-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    border: 1px solid var(--color-stroke, #CEDAEC);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    width: 324px;
    height: 128px;

    .processed {
      display: flex;
      padding: 2px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 40px;
      border: 1px solid var(--color-text-subtle, #778DAC);
      background: #FFF;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);

      span {
        color: var(--color-text-subtle, #778DAC);
        font-family: var(--primaryFont);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .no-records-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      span {
        color: var(--color-text-emphasis, #032A58);
        font-family: var(--primaryFont);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .see-all-link {
      display: flex;

      .see-all-label {
        color: var(--color-accent, #0064CC);
        font-family: var(--primaryFont);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .link {
        fill: #0064CC !important;
        margin-left: 4px;
        margin-top: -0.4em;
      }
    }
  }
}

.priodedup-records-container {
  display: flex;
  position: relative;

  .priodedup {
    margin-left: 1rem;
  }
}

.run-preview-button-after-changes {
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 35px;
  top: 90px;
  margin-bottom: 5rem;
}

.run-preview-button-without-records {
  left: calc(50% - 95px);
  transform: translate(-50%, -50%);
  width: 180px;
  height: 35px;
  top: 90px;
  margin-bottom: 5rem;
}

.show-sql-run-btn-without-records {
  left: calc(50% - 90px);
  transform: translate(-50%, -50%);
  width: 180px;
  height: 35px;
  top: 90px;
  margin-bottom: 5rem;
}

.records-container:has(.priodedup) {
  width: 648px;
}

.preview-running-buttons {
  display: flex;

  .show-sql-btn {
    margin-left: 1rem;
    margin-top: -0.28rem;
  }
}

.navbar-for-preview {
  display: flex;

  .empty-preview-div {
    width: 378.71px;
  }

  .preview-navbar-container {
    width: 98.6%;
    background: #F4F7FB;
    position: relative;

    .preview-navbar {
      .slds-builder-toolbar {
        border-bottom: unset;
      }
    }
  }

  .tabs-container-preview {
    width: 21.717rem;
    border-bottom: 0;
  }
}