.selection-input {
  grid-column: unset;
  display: flex;
  user-select: none;

  .slds-form-element {
    width: 100%;
    position: relative;

    .slds-form-element__control {
      display: flex;
      align-items: center;

      .slds-input {
        padding-left: 1rem;
        font-weight: normal;
      }

      .notePopUp {
        width: 15rem;
        position: absolute;

        .slds-popover {
          position: absolute;
          top: 0.3rem;
          left: 1rem;
          min-height: 1.6rem;

          &::before {
            top: 10%;
          }
          &::after {
            display: none;
          }
        }
      }

      i {
        cursor: pointer;
      }

      // folder popup icon
      .folder-popup-icon {
        margin-left: 0.3rem;
        width: 2rem;
        cursor: pointer;
        svg,
        use {
          pointer-events: none;
        }
      }

      // folder popup
      .folderPopup {
        height: 25rem;
        width: 25rem;
        border: 1px solid var(--lightGrayBorder);
        background-color: var(--white);
        border-radius: 0.3rem;
        position: fixed;
        display: none;
        left: unset;
        top: unset;
        z-index: 100;
        overflow: hidden;
        padding-top: 2.2rem;
        box-shadow: 0 1px 2px 0 var(--primaryBoxShadowGray);

        &.active {
          display: block;
        }

        .currentFolderIn {
          position: absolute;
        }

        #get-parent-folders {
          margin-right: 10px;
          margin-left: 6px;
          transition-duration: 0.4s;
          cursor: pointer;
          background-color: var(--white);
          color: var(--black);

          &:hover {
            background-color: var(--lightGrayScrollTrack);
          }
        }

        .makeSpaceFolder {
          height: 100%;
          width: 100%;
          position: relative;
          border-top: 1px solid var(--lightGrayBorder);
        }

        .closeFolderPopup {
          position: absolute;
          right: 0.5rem;
          top: 0.55rem;
          z-index: 200;
          cursor: pointer;

          &:hover use {
            fill: var(--black);
          }

          svg,
          use {
            pointer-events: none;
            fill: var(--primaryDarkGray);
          }
        }

        .confirmPopupButton {
          position: absolute;
          bottom: .5rem;
          right: .5rem;
          z-index: 1;
        }

        .selectedFolder {
          position: absolute;
          bottom: .9rem;
          left: .8rem;
          z-index: 1;
          font-size: 1.2rem;
          width: 17rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1.4rem;
        }
      }
    }
  }

  #name-field-error {
    display: none;
  }
  .folder-container{
    margin-left: -2rem;
  }
  .folder-name {
    margin-top: 1rem !important;
    margin-bottom: -8px;
  }
  .start-name {
    margin-top: 5px;
    margin-bottom: -4px;
  }
  .sql-text {
    margin-top: 10px;
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 10px;
    color: var(--darkGray);
  }

  .input-name {
    width: 100% !important;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    height: 1.3rem;
  }
}

.input-folder-icon {
  margin-right: 1rem;

  &.in-modal {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    width: 100%;
    padding: 1rem 2rem;


    input {
      width: 100%;
    }

    .input-name-comment {
      width: 100%;
      font-style: italic;
      margin-top: 0.4rem;
      margin-bottom: 0;
      height: 1.3rem;
      font-size: 0.9rem;
      color: var(--darkGray);
    }

    .span-container-in-modal {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 1rem;
      &.disabled-overlay{
        background-color: var(--disabledGrayOverlay);
        padding: 5px;
        cursor: not-allowed;
        .upgrade-badge-icon{
          left: 11.2em;
          bottom: 1.2em;
        }
        .folder-popup-icon, .selected-folder-name{
          pointer-events: none;
          opacity: 0.5;
        }

        .tooltip-container{
          position: absolute;
          left: 12em;
          bottom: 1.5em;
        }
      }
    }

    .selected-folder-name {
      margin-left: 1rem;
    }

    #folder-popup-id {
      position: fixed !important;
      top: 45%;
      left: 4.5rem;
    }

    #folder-popup-id-template {
      position: fixed !important;
      top: 42%;
      left: 4.5rem;
    }

    #folder-popup-id-sql {
      position: fixed !important;
      top: 38%;
      left: 4.5rem;
    }
  }
}

.span-container-in-nav{
  &.disabled-overlay{
    background-color: var(--disabledGrayOverlay);
    cursor: not-allowed;
    .upgrade-badge-icon{
      bottom: 1em;
      right: -1em;
    }
    .folder-popup-icon, .selected-folder-name{
      pointer-events: none;
      opacity: 0.5;
    }

    .des-tooltip{
      display: flex;

      .slds-tooltip-trigger{
        display: flex;
        margin-left: -5px;
      }
    }

    .slds-popover_tooltip{
      z-index: 999999;
    }
  }
}

.selection-choose-template {
  padding: 0 2rem ;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.selection-choose-template-one {
  padding-left: 1rem ;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.sql-to-selection {
  padding-left: 1rem;
}

.radio-buttons {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--primaryFont);
  letter-spacing: 0em;
  text-align: left;
  display: grid;
  padding: 0 2rem ;

  .radio-one {
    margin-bottom: 1rem;
  }

  .radio-button-font.radio-two {
    margin-bottom: 1.5rem;
  }

  .radio-three {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .radio-label {
    font-size: 14px;
  }
}

.link-spacing {
  margin-left: 5px !important;
}

#selection-name-error {
  font-size: 14px;
  height: 30.13px;
  line-height: 14px;
}
