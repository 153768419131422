/*Searchable Dropdown*/

.ui.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  text-align: left;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.ui.dropdown .menu {
  cursor: auto;
  position: absolute;
  display: none;
  outline: none;
  top: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin: 0em;
  padding: 0em 0em;
  background: #FFFFFF;
  font-size: 1em;
  text-shadow: none;
  text-align: left;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
}

.ui.dropdown .menu>* {
  white-space: nowrap;
}

.ui.dropdown>input:not(.search):first-child,
.ui.dropdown>select {
  display: none !important;
}


.ui.dropdown>.dropdown.icon {
  position: relative;
  width: auto;
  font-size: 0.85714286em;
  margin: 0em 0em 0em 1em;
}

.ui.dropdown .menu>.item .dropdown.icon {
  width: auto;
  float: right;
  margin: 0em 0em 0em 1em;
}

.ui.dropdown .menu>.item .dropdown.icon+.text {
  margin-right: 1em;
}

.ui.dropdown>.text {
  display: inline-block;
  -webkit-transition: none;
  transition: none;
}

.ui.dropdown .menu>.item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
}

.ui.dropdown .menu>.item:first-child {
  border-top-width: 0px;
}

.ui.dropdown>.text>[class*="right floated"],
.ui.dropdown .menu .item>[class*="right floated"] {
  float: right !important;
  margin-right: 0em !important;
  margin-left: 1em !important;
}

.ui.dropdown>.text>[class*="left floated"],
.ui.dropdown .menu .item>[class*="left floated"] {
  float: left !important;
  margin-left: 0em !important;
  margin-right: 1em !important;
}

.ui.dropdown .menu .item>.icon.floated,
.ui.dropdown .menu .item>.flag.floated,
.ui.dropdown .menu .item>.image.floated,
.ui.dropdown .menu .item>img.floated {
  margin-top: 0em;
}

.ui.dropdown .menu>.header {
  margin: 1rem 0rem 0.75rem;
  padding: 0em 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: bold;
  text-transform: uppercase;
}

.ui.dropdown .menu>.divider {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  height: 0em;
  margin: 0.5em 0em;
}

.ui.dropdown.dropdown .menu>.input {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1.14285714rem 0.78571429rem;
  min-width: 10rem;
}

.ui.dropdown .menu>.header+.input {
  margin-top: 0em;
}

.ui.dropdown .menu>.input:not(.transparent) input {
  padding: 0.5em 1em;
}

.ui.dropdown .menu>.input:not(.transparent) .button,
.ui.dropdown .menu>.input:not(.transparent) .icon,
.ui.dropdown .menu>.input:not(.transparent) .label {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.ui.dropdown>.text>.description,
.ui.dropdown .menu>.item>.description {
  float: right;
  margin: 0em 0em 0em 1em;
  color: rgba(0, 0, 0, 0.4);
}

.ui.dropdown .menu>.message {
  padding: 0.78571429rem 1.14285714rem;
  font-weight: normal;
}

.ui.dropdown .menu>.message:not(.ui) {
  color: rgba(0, 0, 0, 0.4);
}


.ui.dropdown .menu .menu {
  top: 0% !important;
  left: 100%;
  right: auto;
  margin: 0em 0em 0em -0.5em !important;
  border-radius: 0.28571429rem !important;
  z-index: 21 !important;
}


.ui.dropdown .menu .menu:after {
  display: none;
}

.ui.dropdown>.text>.icon,
.ui.dropdown>.text>.label,
.ui.dropdown>.text>.flag,
.ui.dropdown>.text>img,
.ui.dropdown>.text>.image {
  margin-top: 0em;
}

.ui.dropdown .menu>.item>.icon,
.ui.dropdown .menu>.item>.label,
.ui.dropdown .menu>.item>.flag,
.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>img {
  margin-top: 0em;
}

.ui.dropdown>.text>.icon,
.ui.dropdown>.text>.label,
.ui.dropdown>.text>.flag,
.ui.dropdown>.text>img,
.ui.dropdown>.text>.image,
.ui.dropdown .menu>.item>.icon,
.ui.dropdown .menu>.item>.label,
.ui.dropdown .menu>.item>.flag,
.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>img {
  margin-left: 0em;
  float: none;
  margin-right: 0.78571429rem;
}

.ui.dropdown>.text>img,
.ui.dropdown>.text>.image,
.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>img {
  display: inline-block;
  vertical-align: top;
  width: auto;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  max-height: 2em;
}


.ui.dropdown .ui.menu>.item:before,
.ui.menu .ui.dropdown .menu>.item:before {
  display: none;
}

/* Prevent Menu Item Border */

.ui.menu .ui.dropdown .menu .active.item {
  border-left: none;
}

/* Automatically float dropdown menu right on last menu item */

.ui.menu .right.menu .dropdown:last-child .menu,
.ui.menu .right.dropdown.item .menu,
.ui.buttons>.ui.dropdown:last-child .menu {
  left: auto;
  right: 0em;
}



.ui.label.dropdown .menu {
  min-width: 100%;
}


.ui.dropdown.icon.button>.dropdown.icon {
  margin: 0em;
}

.ui.button.dropdown .menu {
  min-width: 100%;
}

.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #FFFFFF;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.ui.selection.dropdown.visible,
.ui.selection.dropdown.active {
  z-index: 10;
}

select.ui.dropdown {
  height: 38px;
  padding: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  visibility: visible;
}

.ui.selection.dropdown>.dropdown.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 1em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}

/*  Selection Menu */

.ui.selection.dropdown .menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  border-top-width: 0px !important;
  width: auto;
  outline: none;
  margin: 0px -1px;
  min-width: calc(100% + 2px);
  width: calc(100% + 2px);
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}

.ui.selection.dropdown .menu:after,
.ui.selection.dropdown .menu:before {
  display: none;
}

/*--------------
    Message
---------------*/

.ui.selection.dropdown .menu>.message {
  padding: 0.78571429rem 1.14285714rem;
}

@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: 8.01428571rem;
  }
}

@media only screen and (min-width: 768px) {
  .ui.selection.dropdown .menu {
    max-height: 10.68571429rem;
  }
}

@media only screen and (min-width: 992px) {
  .ui.selection.dropdown .menu {
    max-height: 16.02857143rem;
  }
}

@media only screen and (min-width: 1920px) {
  .ui.selection.dropdown .menu {
    max-height: 21.37142857rem;
  }
}

.ui.selection.dropdown .menu>.item {
  border-top: 1px solid #FAFAFA;
  padding: 0.78571429rem 1.14285714rem !important;
  white-space: normal;
  word-wrap: normal;
}

.ui.selection.dropdown .menu>.hidden.addition.item {
  display: none;
}

.ui.selection.dropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
  -webkit-box-shadow: none;
  box-shadow: none;
}


.ui.selection.active.dropdown {
  border-color: #96C8DA;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

.ui.selection.active.dropdown .menu {
  border-color: #96C8DA;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

.ui.selection.dropdown:focus {
  border-color: #96C8DA;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.selection.dropdown:focus .menu {
  border-color: #96C8DA;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Visible */

.ui.selection.visible.dropdown>.text:not(.default) {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
}

/* Visible Hover */

.ui.selection.active.dropdown:hover {
  border-color: #96C8DA;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

.ui.selection.active.dropdown:hover .menu {
  border-color: #96C8DA;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Dropdown Icon */

.ui.active.selection.dropdown>.dropdown.icon,
.ui.visible.selection.dropdown>.dropdown.icon {
  opacity: '';
  z-index: 3;
}

/* Connecting Border */

.ui.active.selection.dropdown {
  border-bottom-left-radius: 0em !important;
  border-bottom-right-radius: 0em !important;
}

/* Empty Connecting Border */

.ui.active.empty.selection.dropdown {
  border-radius: 0.28571429rem !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.active.empty.selection.dropdown .menu {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.search.dropdown {
  min-width: '';
}

.ui.search.dropdown>input.search {
  background: none transparent !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: text;
  top: 0em;
  left: 1px;
  width: 100%;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: inherit;
}

/* Text Layering */

.ui.search.dropdown>input.search {
  position: absolute;
  z-index: 2;
}

.ui.search.dropdown>.text {
  cursor: text;
  position: relative;
  left: 1px;
  z-index: 3;
}

/* Search Selection */

.ui.search.selection.dropdown>input.search {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
}

/* Used to size multi select input to character width */

.ui.search.selection.dropdown>span.sizer {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  display: none;
  white-space: pre;
}

/* Active/Visible Search */

.ui.search.dropdown.active>input.search,
.ui.search.dropdown.visible>input.search {
  cursor: auto;
}

.ui.search.dropdown.active>.text,
.ui.search.dropdown.visible>.text {
  pointer-events: none;
}

.ui.search.dropdown .menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}

.ui.dropdown .menu .active.item {
  background: transparent;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.95);
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 12;
}

.ui.dropdown .menu>.item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  z-index: 13;
}

.ui.dropdown:not(.button)>.default.text,
.ui.default.dropdown:not(.button)>.text {
  color: rgba(191, 191, 191, 0.87);
}

.ui.dropdown:not(.button)>input:focus~.default.text,
.ui.default.dropdown:not(.button)>input:focus~.text {
  color: rgba(115, 115, 115, 0.87);
}


.ui.loading.dropdown>.text {
  -webkit-transition: none;
  transition: none;
}


.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}


.ui.dropdown>.filtered.text {
  visibility: hidden;
}

.ui.dropdown .filtered.item {
  display: none !important;
}

.ui.disabled.dropdown,
.ui.dropdown .menu>.disabled.item {
  cursor: default;
  pointer-events: none;
  opacity: 0.45;
}

.ui.dropdown .menu {
  left: 0px;
}

.ui.dropdown .right.menu>.menu,
.ui.dropdown .menu .right.menu {
  left: 100% !important;
  right: auto !important;
  border-radius: 0.28571429rem !important;
}

@font-face {
  font-family: 'Dropdown';
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=) format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui.dropdown>.dropdown.icon {
  font-family: 'Dropdown';
  line-height: 1;
  height: 1em;
  width: 1.23em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.ui.dropdown>.dropdown.icon {
  width: auto;
}

.ui.dropdown>.dropdown.icon:before {
  content: '\f0d7';
}

.transition {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.visible.transition {
  display: block !important;
  visibility: visible !important;
  /*  backface-visibility: @backfaceVisibility;
  transform: @use3DAcceleration;*/
}

/*Searchable Dropdown*/
/* DataRetentionPolicy Modal*/
.data-retention-header {
  background-color: #f3f4f5 !important;
  color: #5390c4 !important;
  line-height: 23px !important;
  font-size: 22px !important;
  padding: 22px 0px 22px 136px !important;
}

.label-period {
  float: left;
  margin-left: 132px;
  margin-top: -19px;
  margin-right: 380px;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.label-all-records {
  padding: 0 -1px 0 0;
  font-size: 14px;
  cursor: pointer;
}


.label-after {
  vertical-align: -6px;
  padding: 0 -1px 0 0;
  font-size: 14px;
  margin-top: 2px;
  float: left;
}

.after-number-input {
  width: 50px !important;
  height: 26px !important;
  float: left !important;
  margin-left: 7px !important;
  padding: 2px !important;
  margin-right: 8px !important;
  font-weight: normal !important;
}

.after-select-input {
  width: 80px !important;
  padding: 0 0 0 10px !important;
  height: 25px !important;
  margin-left: 5px !important;
  margin-top: 10px !important;
  font-size: 11px !important;
  font-weight: normal !important;
  cursor: pointer !important;
}

.label-period-on {
  vertical-align: -6px;
  padding: 0 -1px 0 0;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 17px;
  float: left;
}

.period-date-input {
  width: 120px;
  font-size: 11px;
  font-weight: normal;
  height: 30px;
  padding: 5px;
}

.modal-cancel-button {
  color: #2168A7;
  font-size: 12px;
  font-weight: bold;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(255, 255, 255)), to(rgba(210, 210, 210, 1)));
  padding: 4px 8px 5px 8px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
}

.modal-cancel-button:hover {
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(180, 180, 180)), to(rgba(210, 210, 210, 1)));
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 5px;
}

.modal-apply-button {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, left bottom, from(#7db7e9), to(#4d87b8));
  padding: 4px 8px 5px 8px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
}

.modal-apply-button:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#98c9f4), to(#659bc9));
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
}


.data-retention-body {
  background-color: transparent;
}

.fa-play {
  margin-right: 10px;
  font-size: 13px;
}


















.swal-inputs {
  width: 130px;
  height: 30px;
  padding: 0px;
  padding-left: 2px;
  font-size: 13px;
  display: inline;
}

.swal-inputs-checkbox {
  height: 20px !important;
  width: 20px !important;
  margin-bottom: 21px !important;
}

.swal-infos-mt-1 {
  margin-top: 1px;
}














/* data view */



.disable-formula-radio-container {
  opacity: 0.4;
}

.target-definition-icon {
  margin-left: 10px;
}

/* Show more/less text for filter */
.show-more-less-text {
  padding-left: .6rem;
  opacity: 1;
  color: #007bff;
  line-break: normal;
  white-space: pre-wrap;
  display: inline-block;
}

.show-more-less-text-none {
  opacity: 0;
}

.show-more-less-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.disabled-feature {
  background-color: #d4d2d2 !important;
  color: #636363 !important;
  cursor: not-allowed !important;
}


.display-none {
  display: none !important;
}