.new-sidenav {
    font-size: 14px;
    height: calc(100vh - 105px);
    margin-top: 9px;
    overflow-x: hidden;
    margin-right: 10px;
    background-color: #FFFFFF;
    border: 1px solid #DDDBDA;
    border-radius: 8px;
    text-align: center;
    flex: 1;

    .admin-panel-title {
        margin: 8px 0 8px 16px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #333333;
        text-align: left;
      }

    &>h1 {
        margin-left: .4rem;
        text-align: left;
    }
}

.new-admin-panel {
    display: flex;

    .premium-icon {
        display: inline;
        width: 25px;
        margin-left: 5px;
    }

    .feature-unavailable {
        position: relative;

        &:not(.relation-submenu-disabled) {
            background-color: var(--disabledGrayOverlay);
          }

          &.relation-submenu-disabled {

            .new-sub-menu-position{
                display: flex;
                margin-top: 0;

                .feature-title-text{
                    cursor: not-allowed;
                }
            }

            &:hover {
              background-color: transparent !important;
            }
          }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: inherit;
            opacity: 0.6;
            pointer-events: none;
        }

        &:hover {
            cursor: not-allowed;
        }

        .slds-popover {
            width: 50%;
        }

        &:hover .slds-popover {
            opacity: 1;
        }
    }

    .relations-submenu.feature-unavailable{
        .feature-title-text{
            margin-top: 5px;
        }
    }

    .guidance-parent-admin-folders{
        display: inline-block;
        position: absolute;
        .guidance-tip-container{
            position: absolute;
            top: -0.75em;
            margin: 0;
            .slds-icon{
                fill: var(--white);
            }
        }
    }

    .guidance-parent-new-relation{
        position: relative;
       .guidance-tip-container{
        position: absolute;
        top: -2.2em;
        z-index: 2;
        right: 0.5em;
       }
    }
}

.new-admin-main {
    height: calc(100vh - 110px);
    flex: 5;
    border-radius: .3rem;
    padding-top: 12px !important;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: -2px;
}

.new-menu-option {
    display: flex;
    align-items: center;
    height: 30px;
    text-align: left;
    border-left: 3px solid transparent;

    &:hover {
        background-color: var(--whiteSmoke);
        cursor: pointer;
    }
}

.relations {
    text-align: left;
    border-left: 5px solid transparent;

    .active {
        font-weight: 700;
    }

    .new-relations-submenu {
        display: block !important;
        padding-left: 1.5rem;
        height: 22px !important;

        &:hover {
            background-color: var(--whiteSmoke) !important;
            cursor: pointer !important;
        }
    }

    .relation-submenu-disabled {
        color: var(--lightGrayDisabledItem);
        width: 100%;

        &:hover {
            color: var(--lightGrayDisabledItem);
            cursor: default;
        }
    }
}

.de-folders {
    text-align: left;
    border-left: 5px solid transparent;

    .active {
        font-weight: 700;
    }

    .new-de-folders-submenu {
        display: block !important;
        padding-left: 1.5rem;
        height: 22px !important;
        margin-top: 7px !important;

        &:hover {
            background-color: var(--whiteSmoke) !important;
            cursor: pointer !important;
        }
    }
}

.disabled-feature {
    display: flex;
    align-items: center !important;
}

.new-selected-option {
    background-color: #D8EDFF;
    cursor: pointer;
    border-left: 3px solid #1589EE;
}

.backArrow {
    background-color: var(--whiteSmoke);
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        background-color: var(--lightOrange);
    }

    span {
        margin-left: 5px;
    }
}

.new-admin-panel {
    width: calc(100% - var(--newRightbarWidth));

    &--expanded {
        width: calc(100% - var(--newRightbarWidthExpanded));
    }
    margin: 0;
    padding: 0 16px !important;
    height: calc(100vh - 95px);
    background-color: #FFFFFF;
    border-radius: 0 0 8px 8px;
    .sidenav {
        .slds-icon {
            fill: var(--black);
        }
    }

    .disabled-navigation {
        color: var(--disabledGrayBlue);
        cursor: not-allowed;

        .backArrow {
            background-color: unset;

            &:hover {
                background-color: unset;
                cursor: not-allowed;
            }
        }


        .de-folders-submenu:hover {
            color: unset;
            cursor: not-allowed;
        }

        .new-menu-option:hover {
            background-color: unset;
            cursor: not-allowed;
        }

        .new-selected-option {
            background-color: unset;
            cursor: not-allowed;
        }
    }

    button[type="button"]:disabled {
        // Target the spinner inside a disabled button and set its color to white
        .slds-spinner {
          &::after, &::before {
            background-color: var(--white) !important;
          }

          .slds-spinner__dot-a::before,
          .slds-spinner__dot-a::after,
          .slds-spinner__dot-b::before,
          .slds-spinner__dot-b::after {
            background-color: var(--white) !important;
          }
        }
    }
}

.header-alignment {
    position: relative;

    h1 {
        position: absolute;
        top: 0.5rem;
    }
}

.new-admin-container {
    background-color: #FFFFFF;
}
.sub-text-position {
    margin-top: 5px !important;
}
span.new-sub-menu-position {
    margin-top: 2.5px;
}
.de-sub-menu{
    padding-top: 2.5px !important;
    margin-left: -6px !important;
}
