.disable-data-action-radio-container {
  opacity: 0.4;
  cursor: default;
}

#sort-limit-modal-dialog-v2 {
  .slds-modal__container {
    width: auto;

    #content {
      height: 27rem;

      .bold-text {
        font-weight: bolder;
      }

      .toggle {
        margin-top: 22px;
        margin-left: 4rem;
        display: flex;
        justify-content: space-between;

        .label {
          width: 38%;
          font-weight: bolder;
          color: #181818;
          word-wrap: break-word
        }
      }

      .fieldset-center {
        align-items: center;
        position: relative;
        margin-left: 10%;
        margin-right: 10%;
      }

      .line-height {
        line-height: 3rem;
      }

      .slds-radio {
        margin-left: 1vw;
        position: relative;
      }

      .slds-radio_faux {
        margin-right: 0.5rem;
      }

      .display-flex {
        display: flex;
        flex-direction: row;

        .slds-form-element__label {
          padding: 0;
        }
      }

      .dropdown {
        max-height: 3rem;
        line-height: .27rem;
        margin: 0;
        min-height: 0px;
        height: 2.25rem;
        padding: .5rem;
        align-self: center;

        input {
          padding: 0;
          margin: 0;
          line-height: 1.9rem;
          padding-left: 1rem;
          height: 100%;
        }

        div.text {
          text-overflow: ellipsis;
          overflow: visible;
          white-space: nowrap;
          line-height: 1rem;
          height: 1.2rem;
        }

        i {
          padding: .3rem;
        }

        .item {
          height: 2rem;
          line-height: .3rem;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            white-space: nowrap;
          }
        }

        .selected.item {
          font-weight: 400;
        }

      }

      .ui.search.dropdown .menu {
        max-height: 6rem !important;
      }

      .sort-limit-labels {
        color: #495D7A;
        font-size: 13px;
        font-family: var(--primaryFont);
        font-weight: 400;
        line-height: 19.50px;
        letter-spacing: 0.26px;
        word-wrap: break-word
      }

      .margin-left {
        margin-left: 1rem;
      }

      .margin-right {
        margin-right: 4rem;
      }

      .searchable-dropdown {
        width: 100%;
      }

      .slds-form-element__control {
        padding: 0 0.25rem;

        .slds-input {
          width: 8em;
        }
      }
    }
  }
}