.select-main-container {
  display: flex;
  font-family: var(--primaryFont);
  font-size: 15px;
  width: 100% !important;
  color: #495d7a;
  position: relative;

  .select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #dddbda;
    background-color: #ffffff;
    color: #747474;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    cursor: pointer;

    &.disabled {
      background-color: var(--disabledGrayOverlay);
      cursor: not-allowed !important;
    }
  }

  .des-tooltip{
    margin-left: -1.1rem;
    margin-top: 0.3rem;
  }

  .medium-select {
    width: 230px;
    height: 37px;
  }

  .small-select {
    // width: 163px;
    height: 29px;
  }

  .large-select {
    // width: 300px;
    height: 28px;
  }

  .x-large-select {
    // width: 262px;
    width: 100% !important;
    height: 28px;
  }

  .selected-item-text {
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .small-selected-item-text {
    width: 150px;
    font-size: 13.5px !important;
  }
  .s-small-selected-item-text {
    width: 99px;
    font-size: 13.5px !important;
  }

  .large-selected-item-text {
    width: 290px;
    font-size: 13.5px !important;
  }

  .x-large-selected-item-text {
    font-size: 13.5px !important;
  }

  .medium-selected-item-text {
    width: 220px;
  }

  .select-popup {
    position: absolute;
    z-index: 1; // This is important to show the popup over other elements
    overflow-y: auto;
    width: 332px;
    max-height: 382px; // Use max-height instead of fixed height
    border: 1px solid #dddbda;
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    box-sizing: border-box;
    overflow: auto;
  }

  .select-popup-small {
    position: absolute;
    z-index: 1; // This is important to show the popup over other elements
    overflow-y: auto;
    width: 192px !important;
    max-height: 382px; // Use max-height instead of fixed height
    border: 1px solid #dddbda;
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .select-popup-x-large {
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    width: 100% !important;
    max-height: 382px;
    border: 1px solid #dddbda;
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .select-popup-s-small {
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    width: 100% !important;
    max-height: 382px;
    border: 1px solid #dddbda;
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  /* width */
  div.select-popup::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  div.select-popup::-webkit-scrollbar-track {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #dddbda;
  }

  /* Handle */
  div.select-popup::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 10px;

    border: 2px solid transparent;
    background-clip: content-box;
  }

  /* Handle */
  div.select-popup::-webkit-scrollbar-thumb:vertical:hover {
    background: #747474;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  /* Handle on hover */
  div.select-popup::-webkit-scrollbar-thumb:hover {
    background: #747474;
  }

  .select-arrow-icon {
    display: block;
    margin-right: 12px;
  }

  .selected-item-parent-label {
    color: #495D7A;
    font-weight: 500;
  }

  .selected-item-label {
    font-style: italic;
  }

  .search-input-container {
    padding: 6px 3px;
  }

  .search-input {
    border: 0;
    padding: 0;
    margin: 0;
    color: #747474;
    width: calc(100%);
    height: 26px;
    border: 1px solid #dddbda;
    border-radius: 7px;
    font-size: 14px;
    line-height: 24px;
    padding-left: 25px;
    background: url('./img/Shape.png') no-repeat left;
    background-size: 12px;
    background-position: 10px center;
    background-color: #f4f7fb;
  }

  .search-input:focus {
    outline: none;
  }

  .all-lists-container {
    padding-top: 2px;
    max-height: 300px;
    overflow-y: auto; 
  }

  .list-container {}

  .list-items-container {}

  .list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13.5px !important;
    height: 35px;
  }

  .list-border-bottom {
    border-bottom: 1px solid #DDDBDA;
  }

  .list-title-simple {
    &:hover {
      background-color: #f4f7fb;
      cursor: pointer;
    }
  }

  .list-title {
    cursor: pointer;
  }

  .list-item {
    height: 40px;
  }

  .list-item:hover {
    background-color: #f4f7fb;
    cursor: pointer;
  }

  .list-title-left-icon,
  .list-title-right-icon {
    display: block;
    margin: 0 7px;
  }

  .list-item-left-icon {
    display: block;
    margin-left: 4px;
    margin-right: 4.5px;
  }

  .list-title-group,
  .list-item {
    display: flex;
    align-items: center;
  }

  .list-operator-title-group,
  .list-item {
    display: flex;
    margin-left: 10px !important;
    align-items: center;
  }

  body {
    background-color: #f4f7fb;
  }

  /* Select component style end */

  /* Filter Box component style start */

  .filter-box-main-container {
    font-family: var(--primaryFont);
    width: 1079px;
    border: 1px solid #DDDBDA;
    padding: 16px 16px 0px 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: rgb(0 0 0 / 3%) 0px -4px 10px;
    color: #181818;
  }

  .filter-line {
    display: flex;
    align-items: center;
    height: 39px;
  }

  .filter-type-select {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    height: 39px;
    min-width: 94px;
    padding: 6px 12px 6px 12px;
    border-radius: 5px;
    border: 1px solid #DDDBDA;
    margin-left: 8px;
  }


  .filter-type-select:hover,
  .filter-type-select:focus {
    outline: none;
  }

  .filter-input {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    height: 39px;
    min-width: 163px;
    padding: 6px 12px 6px 12px;
    border-radius: 5px;
    border: 1px solid #DDDBDA;
    margin-left: 8px;
  }

  .where-text {
    margin-right: 8px;
    font-size: 13px;
    color: #495D7A;
  }

  .delete-filter-icon {
    margin-left: 8px;
    cursor: pointer;
  }

  .filter-box-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 71px;
    border-top: 1px solid #DDDBDA;
    margin-top: 24px;
  }

  .add-filter-button,
  .create-group-button {
    display: flex;
    align-items: center;
    height: 32px;
    width: 138px;
    font-size: 15px;
    border: none;
    background: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .add-filter-plus-icon {
    margin-right: 8px;
  }

  .add-filter-button {
    padding: 8px 16px 8px 16px;
    border: 1px solid #DDDBDA;
    color: #495D7A;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 2px;
  }

  .create-group-button {
    padding: 8px 0px 8px 0px;
    width: 97px;
    margin-left: 16px;
    color: #0176D3;
  }

  .filter-line-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grouping-check-box-container {
    margin-right: 8px;
  }

  .grouping-check-box-container input {
    accent-color: #0176D3;
  }
}
.dropdown-responsive {
  width: 50%; /* Adapts to the container */
}

.select-main-container,
.select-popup,
.all-lists-container {
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.parent-container {
  overflow: visible; /* Ensure overflow is visible */
}

.select-popup {
  max-height: 400px; /* Adjust the max height to control the dropdown size */
  overflow-y: auto; /* Allow vertical scrolling */
}