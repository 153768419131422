.table-common-look  {
    background-color: #E8EEF6;
    border: 1px solid #DDDBDA;
    font-size: 14px !important;
    font-weight: 300 !important;

    .slds-scrollable {
        background-color: #FFFFFF;
    }

    .cell-common-look {
        color: #495D7A;
        background-color: #E8EEF6;
        font-size: 15px !important;
    }
}

