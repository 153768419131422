.admin-body-container {
  justify-content: center;
  width: 100%;
  height: 87%;
  background-color: var(--white);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  overflow: auto !important;

  .wrapper-v2 {
    width: 100%;
    padding: 2rem 0rem 0rem 0rem;

    .content {
      position: relative;
      padding: 0rem 2rem;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      overflow-y: auto;

      .header {
          &-title {
              font-size: 1.5rem;
              font-weight: 700;
          }

          .label {
              font-weight: 700;
              padding-bottom: 0.5rem;
              color: var(--primaryDarkGray);
          }

      }

      .info_text {
        color: var(--grayText);
        font-size: 1rem;
        position: relative;
        margin-top: 0.5rem;
        line-height: 1.3;
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background: var(--lightGrayTableHeader);
    padding: 10px 2rem 1rem 2rem;
    border-top: solid 1px var(--lightGrayBorder);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
