.selected-extension {
    grid-column: 6/25;
    min-height: 8rem;
    background-color: #F4F7FB;

    .section-title {
        white-space: nowrap;
        padding: .8rem 0;
        color: var(--blackTitle);
        border-radius: .2rem;
        font-weight: 600;
    }

    &_wrapper {
        position: relative;
        border: 1px solid var(--grayBorder);
        border-radius: .25rem;
        box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
        overflow: hidden;

        p {
            color: var(--grayBorder);
            letter-spacing: .1rem;
            line-height: 8rem;
            text-align: center;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}

.greensolid {
    border: .1rem solid #04844b !important;
}
