.collection-label-container-new-v2 {
  padding: 0.5rem 0.4rem;
  position: sticky;
  top: 0;
  margin: 5px 0 !important;
  font-family: "SF Pro", Arial, sans-serif !important;
  margin-left: -0.5rem !important;
  color: #495D7A;

  &.disabled-cv {
    background: var(--disabledOverlayOrange);
    opacity: 0.4;
  }

  .chevron-icons-container {
    align-self: center;
    cursor: pointer;
  }

  .collection-name {
    flex: 1 1;
    word-break: break-word;
    line-height: 1.1;
  }

  .collection-name>p {
    line-height: initial;
  }

  .de-name-style-v2 {
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }

  .des-lib-tde-aliases-v2 {
    color: #747474;
    font-size: 13.5px;
    font-family: "SF Pro", Arial, sans-serif !important;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
  }
}

.data-extension-name {
  display: flex;
}

.available-field {
  display: flex;
  justify-content: space-between;
  padding: .5rem;
  margin: .2rem;
  border-radius: .25rem;
  border: 1px solid var(--lightGrayColdBorder);
  background-color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #495D7A;
  font-size: 14px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
}

.drag-field {
  cursor: grab;

  &.encrypted {
    cursor: default;

    &:active {
      cursor: default;
    }
  }

  &:hover {
    background-color: var(--lightGrayishBlueHover) !important;
    color: var(--darkDesaturatedBlue);
  }

  &:active {
    cursor: grabbing;
  }

  .encrypted-lock-icon {
    color: var(--darkGray);
  }
}

.custom-values-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &>span {
    height: 1.2rem;
  }
}

.custom-field-info-button {
  margin-right: 7px;
  margin-left: 7px;
  margin-top: -3px;
}

.last-item {
  margin-left: 7px;
}

.add-new-value-button-container {
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
}

.info-button {
  cursor: default;
}

.copy-custom-value-modal::after {
  content: "* This Custom Value can be found under the Custom Values 'Standard' tab";
  font-size: small;
}

.filter-icon-padding {
  margin-left: 2px;
}

.expand-collapse-container {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.0rem;
  border-top: 1px solid var(--grayBorder);
  border-bottom: 1px solid var(--grayBorder);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
}

.slds-icon-down {
  margin-top: -33px !important;
}

.filter-card {
  width: 100%;
  display: flex;
}

.fa-1x {
  font-size: 0.8em !important;
}

.ml-10px {
  margin-left: 10px;
}

.slds-icon-size {
  width: 1rem;
  height: 1rem;
  fill: var(--darkGrayButton);
}

.add-new-value-button-container-right {
  display: flex;
  align-items: flex-start;
  flex-flow: row-reverse;
  flex-grow: 1;
}

.des-lib-add-icon {
  margin-right: 5px;
}

.collection-name-alias-v2 {
  color: #778DAC;
  font-size: 13px;
  font-family: "SF Pro", Arial, sans-serif !important;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
}

.filter-card-tabs {
  padding-left: 3px; // Add a small padding to show the left border in tabs
}
.des-all-fields {
  margin-top: 3px !important;
  margin-left: 7px !important;
}
