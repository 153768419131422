.filter-set-category-container {
  display: flex;
  max-width: 320rem !important;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 1em;
  padding-top: 0.5em;

}

.filter-set-category-container input {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1.9rem !important;
  padding-left: 1rem !important;
  height: 100% !important;
  width: 28rem !important;
}

.filter-set-category-container.saving-mode input {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1.9rem !important;
  padding-left: 1rem !important;
  height: 100% !important;
  width: 25rem !important;
}

.slds-truncate.slds-m-left_x-small.filterSet-type {
  font-size: 14px !important;
}

.slds-truncate.slds-text-align_left {
  margin-left: auto !important;
}

.th-size {
  width: 90% !important;
}

svg.slds-button__icon.check {
  color: #0F870F !important;
}

svg.slds-button__icon.close {
  color: #FF1515 !important;
}

input#filter-set-category-edit {
  width: 25em !important;
}

.input-container {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mt-5px {
  margin-top: -5px !important;
  margin-bottom: 10px !important;
}

#picklists-panel {
  min-height: 10em !important;
}

#newAutoCreatedTDE-categories {
  .slds-modal__container {
    width: 100rem !important;
  }
}
