.selection-box {
  &_header {
    background-color: transparent;
    padding: .75rem 0;
    position: relative;
    z-index: 1;
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    background-color: var(--white);
    // padding: .35rem 1rem;
    align-items: center;
    width: 98.3%;
    padding-top: 0.5rem;
  }

  &_archive_warning {
    justify-content: center;
    align-items: stretch;
    padding: .35rem 1rem;
  }
}

.selection-home-button+div {
  max-width: 350px;

  .selection-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.selection-input {
  grid-column: 1/6;
  display: flex;
  user-select: none;

  .slds-form-element {
    width: 100%;
    position: relative;
    margin-left: 1.5rem;

    .slds-form-element__control {
      display: flex;
      align-items: center;

      .slds-input {
        padding-left: 1rem;
        font-weight: normal;
      }

      .notePopUp {
        width: 15rem;
        position: absolute;

        .slds-popover {
          position: absolute;
          top: 0.3rem;
          left: 1rem;
          min-height: 1.6rem;

          &::before {
            top: 10%;
          }

          &::after {
            display: none;
          }
        }
      }

      i {
        cursor: pointer;
      }

      // folder popup icon
      .folder-popup-icon {
        margin-left: 0.5rem;
        cursor: pointer;

        svg,
        use {
          pointer-events: none;
        }
      }

      // folder popup
      .folderPopup {
        height: 25rem;
        width: 20rem;
        border: 1px solid var(--lightGrayBorder);
        background-color: var(--white);
        border-radius: 0.3rem;
        position: absolute;
        display: none;
        left: 12rem;
        top: 1rem;
        z-index: 100;
        overflow: hidden;
        padding-top: 2.2rem;
        box-shadow: 0 1px 2px 0 var(--primaryBoxShadowGray);

        &.active {
          display: block;
        }

        .currentFolderIn {
          position: absolute;
          top: 0.2rem;
          align-items: center;
          display: flex;
        }

        #get-parent-folders {
          margin-right: 10px;
          margin-left: 6px;
          transition-duration: 0.4s;
          cursor: pointer;
          background-color: var(--white);
          color: var(--black);

          &:hover {
            background-color: var(--lightGrayScrollTrack);
          }
        }

        .makeSpaceFolder {
          height: 100%;
          width: 100%;
          position: relative;
          border-top: 1px solid var(--lightGrayBorder);
        }

        .closeFolderPopup {
          position: absolute;
          right: 0.5rem;
          top: 0.55rem;
          z-index: 200;
          cursor: pointer;

          &:hover use {
            fill: var(--black);
          }

          svg,
          use {
            pointer-events: none;
            fill: var(--primaryDarkGray);
          }
        }

        .confirmPopupButton {
          position: absolute;
          bottom: .5rem;
          right: .5rem;
          z-index: 1;
        }

        .selectedFolder {
          position: absolute;
          bottom: .9rem;
          left: .8rem;
          z-index: 1;
          font-size: 1.2rem;
          width: 17rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1.4rem;
        }
      }
    }
  }

  #name-field-error {
    display: none;
  }

  .folder-container {
    margin-left: -2rem;
  }

  .folder-name {
    margin-top: 1rem !important;
    margin-bottom: -8px;
  }

  .start-name {
    margin-top: 5px;
    margin-bottom: -4px;
  }

  .sql-text {
    margin-top: 10px;
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.input-folder-icon {
  margin-right: 1rem;

  &.in-modal {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    width: 100%;
    padding: 1rem 2rem;


    input {
      width: 100%;
    }

    .input-name-comment {
      width: 100%;
      font-style: italic;
      margin-top: 0.4rem;
      margin-bottom: 0;
      height: 1.3rem;
      font-size: 0.9rem;
    }

    .span-container-in-modal {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 1rem;

      &.disabled-overlay {
        background-color: var(--disabledGrayOverlay);
        padding: 5px;
        cursor: pointer;

        .upgrade-badge-icon {
          left: 11.2em;
          bottom: 1.2em;
        }

        .folder-popup-icon,
        .selected-folder-name {
          pointer-events: none;
          opacity: 0.5;
        }

        .tooltip-container {
          position: absolute;
          left: 11em;
          bottom: 1.5em;
        }
      }
    }

    .selected-folder-name {
      margin-left: 1rem;
    }

    #folder-popup-id {
      position: fixed !important;
      top: 45%;
      left: 4.5rem;
    }

    #folder-popup-id-template {
      position: fixed !important;
      top: 42%;
      left: 4.5rem;
    }

    #folder-popup-id-sql {
      position: fixed !important;
      top: 38%;
      left: 4.5rem;
    }
  }
}

.span-container-in-nav {
  &.disabled-overlay {
    background-color: var(--disabledGrayOverlay);
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .upgrade-badge-icon {
      bottom: 1em;
      right: -1em;
    }

    .folder-popup-icon,
    .selected-folder-name {
      pointer-events: none;
      opacity: 0.5;
    }

    .tooltip-container {
      position: absolute;
      right: -15px;
      top: -3px;
    }
  }
}

.selection-choose-template {
  padding: 0 2rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.selection-choose-template-one {
  padding-left: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.sql-to-selection {
  padding-left: 1rem;
}

.radio-buttons {
  display: grid;
  padding: 0 2rem;
  font-family: var(--primaryFont);
  font-size: 14px;
}

.radio-one {
  margin-bottom: 1rem;
}

.radio-three {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.radio-button-font.radio-two {
  margin-bottom: 1.5rem;
}

.input-name {
  width: 100% !important;
  margin-top: 0.4rem;
  margin-bottom: 0;
  height: 1.3rem;
}

.link-spacing {
  margin-left: 5px !important;
}

.slds-form-element__control.slds-input-has-icon.slds-input-has-icon_left.input-folder-icon {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--primaryFont);
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 90%;
}

.des-icon-size {
  height: 19px;
  width: 19px;
}

.des-home-icon-size {
  height: 15.5px;
  width: 15.5px;

}

.slds-dropdown__item {
  font-size: 0.9375rem;
}

.selection_navigation_buttons {
  display: flex;
  grid-column: 19/25;
  justify-content: flex-end;
  position: relative;

  .save-btn-container {
    margin-right: .2rem;

    .greyed-out {
      color: var(--disabledGrayBlue) !important;
      background-color: var(--white);
    }
  }

  .selection-template-btn-container,
  .schedule-btn-container {
    &.disabled-overlay {
      .slds-icon_container {
        background-color: var(--disabledGrayOverlay) !important;
      }

      .slds-icon {
        background-color: var(--disabledGrayOverlay) !important;
        opacity: 0.6;
        fill: var(--darkGrayButton) !important;
      }

      .upgrade-badge-icon {
        right: -1em;
        top: -0.8em;
        z-index: 9;
      }

      .tooltip-container {
        position: absolute;
        top: -15px;
        z-index: 99;
        right: -15px;
      }
    }
  }

  #open-schedule-selection,
  #selection-settings-button {
    margin-right: .2rem;
    width: 2rem;
  }

  #schedule-icon,
  #selection-settings-icon {

    &.schedule,
    &.template {
      background-color: var(--white);
      fill: var(--darkBlue);
      height: 1.875rem;

      &:hover {
        background-color: var(--lightGrayHover);
      }
    }

    &.schedule-active,
    &.template-active {
      background-color: var(--greenActive);
      height: 1.875rem;

      &:hover {
        background-color: var(--primaryGreen);
      }
    }
  }

  .run-btn-container {
    margin-right: .2rem;

    #run-button {
      min-width: 6rem;
      padding: 0 0.6rem;
    }

    .guidance-tip-container {
      position: absolute;
      top: 2.2em;
      right: 6em;
    }
  }

  .cancel-btn-container {
    button {
      .slds-button__icon {
        margin-right: 0.35rem;
        margin-bottom: 0.1rem;
      }

      &.set-dark-color {
        background-color: var(--primaryBlue);
        color: var(--white);
        border-color: var(--primaryBlue);

        &:hover:not(:disabled) {
          background-color: var(--darkBlue) !important;
        }
      }

      &:disabled {
        background-color: var(--darkGrayButtonDisabled);
      }
    }

    .guidance-tip-container {
      position: absolute;
      top: 2.2em;
      right: -0.5em;
    }
  }



  .hidden {
    display: none;
  }


  .preview-loader-container {
    display: flex;
    position: relative;

    .slds-spinner {
      position: relative;
      left: -0.5rem;
      top: 1rem;
    }
  }

  .when-pressed {
    padding-left: 0.5rem;
  }
}

.dont-show {
  display: none;
}

.confirm-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #495D7A;
  width: 300px;
}

.confirm-heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #495D7A;
  text-align: center;
  font-family: var(--primaryFont);
}

.makeSpaceFolder {
  font-family: var(--primaryFont);
  font-size: 10px !important;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
}

.popupFolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  padding: 0.4rem;
  height: 86%;
  border-bottom: 1px solid var(--lightGrayBorder);

  ul {
    height: 100%;
    position: relative;
    left: 0;
  }

  &:not(:nth-child(1)) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 100%;
    transition: 0.15s;
    background-color: var(--white);
    z-index: 1;
  }

  &.open {
    left: 0%;
    top: 0%;
  }

  .selections-in-folder {
    padding: 0.2rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    width: 98%;
    background-color: var(--white) !important;
    margin-top: 0.2rem;

    &:hover {
      background-color: var(--white) !important;
    }
  }

  .empty-folder {
    font-size: 0.9rem;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--primaryFont);
  }

  li {
    padding: 0.2rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 400;
    width: 98%;
    background-color: var(--mostlyWhiteBackground);
    margin-top: 0.2rem;

    &:hover {
      background-color: var(--darkenLightOrangeActive);
    }

    &.marked {
      background-color: var(--secondaryGreen);
    }

    .popupLine {
      display: flex;
      align-items: center;
      cursor: pointer !important;
      line-height: 1.5rem;

      &.active {
        background-color: var(--lightSoftBlue);
      }

      .arrowPopupFolder {
        cursor: pointer !important;
        transition: 0.15s;
        transform: translateY(-0.08rem);
        margin-left: auto;

        &:hover use {
          fill: var(--black);
        }

        svg,
        use {
          pointer-events: none;
          cursor: pointer !important;
          fill: var(--darkGrayText);
        }

        &.side {
          position: absolute;
          top: -1.7rem;
          left: 0.4rem;
          height: 1.8rem;
          width: 1.8rem;
        }
      }

      i {
        width: 1.6rem;
        padding: 0 0.4rem;
        cursor: pointer !important;
      }

      .slds-truncate span {
        padding: 0 .5rem;
        cursor: pointer;
      }
    }
  }
}

.selection_navigation_buttons .dropdown-container {
  right: 0;
  left: auto;
}

.popup-input-name {
  margin-right: 2rem !important;
  font-size: 14px;
}

.footer-white-bg {
  background-color: white;
}

.footer.slds-popover__footer {
  background-color: white;
}

.selection {
  &_header {
    background-color: white;
    padding: .5rem 0 0 0;
    position: relative;
    margin-bottom: 0rem;
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    background-color: var(--white);
    padding: .35rem 1rem;
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    align-items: center;
    width: 98.3%;
  }

  &_archive_warning {
    justify-content: center;
    align-items: stretch;
    padding: .35rem 1rem;
  }
}

.navigation-bar {
  margin-top: 0.55rem !important;

  &_buttons_container {
    margin-left: 2rem !important;
  }
}

.buttons_container {
  float: right;
}

.schedule-waterfall-button {
  min-width: unset !important;
  margin-left: auto;
  margin-right: 5px;

  .disabled-scheduled-WF-button {
    background: var(--lightGreyedOut);
    opacity: .5;
    pointer-events: none;
  }

  .disabled-scheduled-WF-button:hover {
    background: var(--lightGreyedOut) !important;
  }
}

.selection-navbar-name {
  font-family: "SF Pro", Arial, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-left: 16px;
  margin-right: 16px;
  padding: 6px !important;
  margin-top: -6px;
}

.selection-navbar-name:hover {
  color: #0176D3;
  border-radius: 8px;
  background-color: #F4F7FB;
  align-items: center;
  display: inline-block;
  padding: 6px !important;
  margin-top: -6px;
}

.selection-navbar-saleted-name {
  color: #0176D3;
  border-radius: 8px;
  background-color: #F4F7FB;
  align-items: center;
  display: inline-block;
  padding: 6px !important;
  margin-top: -6px;
}

hr {
  display: block;
  border-top: 1px solid var(--slds-g-color-border-base-1, #e5e5e5);
  height: 1px;
  clear: both;
}

.des-header-line {
  margin: 0;
}

.selection-header-hide {
  z-index: 0;
}

.selection-home-button {
  background-color: #FFFFFF;
  border: 1px solid #CCC;
  color: #222;
  width: 5.05rem;
  height: 30.13px !important;
  margin-right: 9px !important;
  margin-bottom: -1px !important;
}

.home-btn-text {
  margin-left: 0.5rem;
}

.back-to-waterfall {
  width: 7.05rem;
  text-wrap: nowrap;
}

.schedule-active {
  fill: var(--greenActive) !important;
}

.selection-navbar {
  position: relative;
  background: white;
  background-color: #FFFFFF;

  .slds-builder-toolbar {
    background: white;
    background-color: #FFFFFF;
  }
}

.waterfall-navigation {
  position: unset;
  margin-right: auto;
  margin-left: auto;
}


.select-navigation-dropdown-option-wrapper{
  display: inline-flex;
  width: 100%;

  .slds-truncate{
    overflow: inherit;
    display: inline-flex;
  }

  &.disabled-overlay{
    background-color: var(--disabledGrayOverlay);

    .des-button{
      cursor: not-allowed;
      &:hover{
        background-color: var(--disabledGrayOverlay);
      }
    }
  }

  .des-button{
    padding: 0;
    margin-top: -5px;
  }

  .slds-popover {
    position: absolute;
    right: inherit;
    width: max-content;

    .slds-popover__body {
      word-wrap: break-word;
      overflow-wrap: break-word;
      hyphens: auto;
      white-space: normal;
    }
  }

  .create-template-btn{
    .slds-popover{
      left: 8.5rem !important;
      top: -0.3rem !important;
      max-width: 9rem;
    }
  }

  .schedule-selection-btn{
    .slds-popover{
      left: 9.5rem !important;
      top: -1.5rem !important;;
      max-width: 8rem;
    }
  }
}