.newAdminpanel {

    &_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 66px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      background-color: #F3F2F2;
      border-bottom: 1px solid #DDDBDA ;
      box-shadow: none;

      .admin-home-button {
        background-color: #FFFFFF;
        border: 1px solid #DDDBDA;
        width: 2.05rem;
        height: 2.05rem;
      }

      .admin-global-nav-container {
        margin-left: 16px;
      }

      .admin-home-button-container {
        margin-left: 10px;
      }
    }
}
