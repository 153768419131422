.virtualized-dropdown-v2 {
  position: relative;
  width: 100%;

  .virtualized-list-container {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
  }

  .virtualized-list {
    width: 100%;
    outline: none;
    padding-top: 5px;

    .slds-listbox__option {
      .slds-truncate{
        height: 20px;
      }
    }
  }

  .virtualized-list-item {
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #dddbda;
    cursor: pointer;
  }

  .virtualized-list-item:hover {
    width: 100%;
    background-color: #dddbda;
  }

  .selected_data {
    background-color: #005fb2;
    width: 100%;
    color: #ffffff;
  }

  .row-dropdown {
    display: flex;
  }

  .input-size {
    width: 100% !important;
  }


}