.selection {
  &_header {
    background-color: transparent;
    padding: .75rem 0;
    position: relative;
    z-index: auto;
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    background-color: var(--white);
    padding: .35rem 1rem;
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    align-items: center;
    width: 98.3%;
  }

  &_archive_warning {
    justify-content: center;
    align-items: stretch;
    padding: .35rem 1rem;
  }
}
