.selection-dropline-wrapper-new {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0.5rem;
    width: 100%;
    justify-content: space-between;
    background-color: var(--white);
    margin-bottom: 2rem;
    border-radius: 40px;
    border: 1px #DDDBDA solid;

    &.isError{
       background: #EF1D1D;

       .step-number-label{
        color: #FFFFFF;
       }

       .connecting-line-wrapper{
        color: #FFFFFF;
       }

       .dropline-card-new {
        background: #FFFFFF;
       }
    }

    &.isQueued {
        opacity: 0.6;
    }

    &:nth-last-child(1) {
        margin-bottom: 0rem;
    }

    &.isDragging {
        border: 1px dashed var(--blackOpacity125);
    }

    .next-step-arrow-new {
        position: absolute;
        top: 100%;
        left: 10%;
        width: 2px;
        height: 60px;
        background-color: #DDDBDA ;

        .slds-button__icon {
            width: 2rem;
            height: 2rem;
            fill: #DDDBDA;
        }
    }

    .dropline-card-new {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 40%;
        border-radius: 40px;
        border: 1px #DDDBDA solid;
        padding-left: 10px;

        .selection-icon-wrapper, .target-de-icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 99;



            &.inactive {
                background-color: var(--lightGrayBorder);
            }
        }

        span {
            font-size: 0.95rem;
            padding-bottom: 0.3rem;
            color: var(--white);
        }

        .selection-name-wrapper, p {
            font-size: 0.95rem;
            padding: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--primaryBlack);
            line-height: 1.25;
            width: 100%;
        }

        p {
            width: 100%;
            height: 2rem;
        }

        .status-warning {
            cursor: pointer;
            color: var(--primaryOrange);
            margin-left: auto;

            &:hover {
                color: var(--verySoftOrange);
            }
        }

        .selection-info {
            display: flex;
            width: 100%;

            .open-selection-container{
                margin-left: auto;
                .open-selection-btn{
                    margin-left: auto;
                    cursor: pointer;
                    width: 15px;
                    margin-right: 10px;
                    margin-top: 5px;
                    fill: #0176D3;
                }
            }


        }

        .error-icon-wrapper {
            margin-left: 0.3rem;
        }

        .status-error {
            fill: var(--primaryRed);
            margin-top: -2px;

            &:hover {
                fill: var(--darkRed);
                cursor: pointer;
            }
        }

    }

    .selection-dropped {
        .selection-icon-wrapper {
            width: 5rem;
            border-radius: 4px;

            .slds-button__icon {
                fill: var(--white);
                height: 4rem;
                width: 4rem;
            }
        }
    }

    .targetDataExtension-dropped {
        .disable-targetDE {
            color: var(--darkenLightGrayScrollThumb);
        }

        .target-de-icon-wrapper {
            border-radius: 4px;

            &.loading {
                border: 1px solid var(--lightGreyedOut);
                position: relative;
                background-color: var(--white);
                margin-right: 5px;
                margin-left: 2px;
            }

            .slds-button__icon {
                fill: var(--white);
                height: 4rem;
                width: 4rem;

            }

            &.inactive {
                background-color: var(--lightGrayBorder);
            }

        }
    }

    .connecting-line-wrapper {
        width:120px;
        z-index: 1;
        margin-top: 0.6em;
        text-align: center;
        color: #495D7A;

        &.true {
            left: 38%;
        }

        .connecting-line {
            border-top: 3px solid var(--lightGreyedOut);
            width: 100%;
            position: absolute;

            .arrow {
                position: absolute;
                top: -10.5px;
                right: 2px;
                width: 18px;
                height: 18px;
                border-right: 3px solid var(--grayBorder);
                border-bottom: 3px solid var(--grayBorder);
                transform: rotate(-45deg);
            }
        }
    }

    @keyframes showSelectionCard {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .dropline-steps-wrapper {
        margin: auto 0;
        display: flex;
        align-items: center;
        width: 15%;
        justify-content: space-between;

        &.true {
            animation: showSelectionCard 0.25s ease-in-out both;
        }

        .slds-icon-utility-announcement {
            margin-right: 1rem;

            svg {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

        .without-icon-dropline {
            margin-right: 2.25rem;
        }

        .remove-dropline {
            margin-left: 0.5rem;
            cursor: pointer;

            .remove-line {
                width: 20px;
                padding: 0;
                border: none;
                background: none;
            }
        }

        .step-number-label {
            p {
                min-width: 3.8rem;
                font-weight: 600;
            }
        }
    }

    .drag-and-drop-waterfall-selection {
        width: 2rem;
        height: 2rem;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.selection-dropline-run_details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;

    .start-time, .no-of-records {
        span{
            font-weight: 600;
        }
    }
}
