.dataExtension_multipleTabs {
  margin: 0 auto;
  width: 98.3%;
  border-radius: .3rem !important;
  background-color: var(--white);
  box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
  height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  .slds-tabs_scoped {
    border-top-left-radius: .3rem !important;
    border-top-right-radius: .3rem !important;
    border: none;

    ul {
      scrollbar-width: thin;
    }

    .slds-is-active {
      a {
        .remove-tab {
          i {
            color: var(--primaryBlack) !important;
          }
        }
      }
    }

    // Tabs
    .slds-tabs_scoped__nav {
      border-top-left-radius: .3rem !important;
      border-top-right-radius: .3rem !important;
      border: none;


      li {
        max-width: 19.4rem;

        a {
          display: flex;
          align-items: center;
          border-right: 1px solid var(--lightGrayBorder);
          border-left: 1px solid var(--lightGrayBorder);

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 1rem;
          }

          .remove-tab {
            color: gray;
            margin-left: .1rem;
            margin-right: -.3rem;
            border: none;
            background-color: transparent;
            outline: none;
            transform: translateY(0.03rem);

            :hover {
              color: var(--darkRed) !important;
            }
          }

          &.disable-mouse-events {
            pointer-events: none;
            opacity: 0.7;
          }
        }
      }

      li.active {
        a {
          font-weight: 600;
          border-right: 1px solid var(--lightGrayBorder);
          border-left: 1px solid var(--lightGrayBorder);
        }
      }

      li:last-child {
        a {
          button {
            background-color: var(--lightGrayIconBackground);
            width: 2.5rem;
            height: 2.5rem;
            border: none;
            transform: translateY(-.5px);
          }

          :hover {
            background-color: var(--lightGrayIconBackgroundHover);
            border: none;
          }
        }
      }
    }

    // Content
    .slds-tabs_scoped__content {
      border: 1px solid var(--lightGrayBorder);
      display: none;
      background-color: transparent;
      border: none;

      &.active {
        display: block;
      }
    }
  }

  .guidance-parent-dataextension_tab {
    position: absolute;

    .guidance-tip-container {
      top: -2em;
      position: absolute;
      z-index: 3;
      left: 8em;
    }
  }
}

html .swal2-noanimation .swal2-actions {
  justify-content: flex-start;
  margin: 0 0.65rem;
}

.tab-submenu {
  &.down {
    height: 4rem;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}