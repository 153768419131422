.filters_wrapper-v2 {
    .new-sticky_left_column {
        padding-left: 5px;
        overflow: auto;
        padding-right: 5px;
        scrollbar-width: thin;
        overflow-x: hidden;
        flex: 0 0 auto;
        width: 21.65rem !important;
        margin-top: 7px;
    }

    .new-sticky_left_column > :first-child {
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .new-sticky_left_column > :last-child {
        margin-top: auto;
    }

    #filters {
        margin-bottom: 1.5rem;
        box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    }
}

.priodedup-filters {
    margin-top: 0px;
}

@keyframes smooth-appear {
    from {
        opacity: 0;
        display: flex;
        margin-top: 55rem;
    }

    to {
        opacity: 1;
        visibility: unset;
        display: flex;
        flex: 1 1 auto;
    }
}

.show-filters-in-selected-filters-section {
    animation: smooth-appear 1s ease-out forwards;
    animation-delay: -0.1s;
    height: 91%;
}

.show-filters-in-selected-filters-section-immediately {
    opacity: 1;
    visibility: unset;
    display: flex;
    height: 100%;
}

.show-regular-filters {
    display: flex;
    max-height: 50vh;
}

@keyframes smooth-disappear-filters {
    from {
        display: flex;
        margin-top: -79rem;
        height: 84%;
    }

    to {
        visibility: hidden;
        display: none;
    }
}


.hide-filters {
    animation: smooth-disappear-filters 1s ease-in forwards;
    animation-delay: 0s;
}

.hidden-filters {
    opacity: 0;
    visibility: hidden;
    display: none;
}