// Whole screen
.picklist_container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: 0.3rem;

    .on-off-checkbox {
        margin-bottom: 0px;
    }

    .searchable-dropdown {
        width: 100% !important;
    }

    .data-extensions-searchable-dropdown,
    .fields-searchable-dropdown {
        margin-top: 1rem;
    }

    .picklist-values-table {
        border: 1px solid var(--lightGrayBorder);

        .slds-input{
            font-size: 1rem !important;
        }
    }

    // Only the body, it excludes the header
    .picklist_wrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem 0rem 0rem 0rem;
        min-height: calc(100vh - 270px);

        .picklist-name {
            width: 100%;
        }

    .buttons-container {
        display: flex;
        flex-flow: column;
        position: relative;
        align-items: flex-end;

        .slds-col {
            display: flex;
            align-items: center;
        }

        .last-refresh-text {
            font-weight: 500;
            font-size: 0.8rem;
            position: absolute;
            bottom: -20px;
            width: max-content;

            .refresh-status-failed {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

        // Configuration for rows
        & > .slds-grid {
            padding: 0 2rem;
            justify-content: space-between;
            // Name
            &:nth-child(2) label { padding-right: 0; }
            // Data extension -- Field
            &:nth-child(3) {
                margin-top: 2rem;
                & > .slds-col:nth-child(2) {
                    padding-left: 0.5rem;
                    & label { padding-right: 0; }
                }
            }
            // Fields below Add values
            &:nth-child(6) {
                margin-top: 2rem;
                & .slds-col_bump-left { margin-top: auto; margin-bottom: auto; display: flex; max-height: 29.39px; }
            }
            // Active & inactive values
            &:nth-child(7) { margin-top: 2rem !important; margin-bottom: 10rem !important; }
            // Cancel & save buttons
            &:last-child { margin-top: auto; }
        }

        // Body header
        .picklist_header {
            &-title {
                font-size: 15px !important;
                font-weight: 700 !important;
                color: #333333 !important;
                margin-top: -20px !important;
            }
        }

        // Some elements need to be bigger
        .title {
            font-size: 15px !important;
            font-weight: 700 !important;
            color: #333333 !important;
            margin: auto 0;
            text-align: left;
        }

        // Inputs
        .element-container {
            // Space between the label and the input
            .slds-form-element__control {
                margin: 7px 0;
            }

            label {
                font-weight: 700;
                width: 100%;
                justify-content: flex-end;
            }

            select, input {
                font-weight: normal;
            }
        }

        // Fields below Add values
        .value-label-container {
            display: flex;
            padding-right: 0.5rem;

            & > div {
                width: 50%;

                &:first-child {
                    & input {
                        border-radius: 0;
                        border-top-left-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                    }
                }
                &:nth-child(2) {
                    & input {
                        border-radius: 0;
                        border-left: none;
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }

            // Add button
            button {
                margin-left: 13px !important;
                height: calc(1.875rem + (1px * 2));
                margin:auto;

                &:disabled {
                    background: var(--disabledGrey);
                    border-color: var(--disabledGrey);
                    color: var(--white);
                }
            }
        }

        // Retrieve value button
        .retrieve-value-button {
            max-height: 29.4px;
            width: max-content;
            margin-left: 10px;

            svg { fill:var(--darkBlue); margin-bottom: 1px; }
            &:disabled {
                background: var(--disabledGray);
                border-color: var(--disabledGray);
                color: var(--white);
                svg { fill: var(--white); }
            }
        }

        // Active & inactive values
        .active-inactive-container {
            & .slds-col {
                // Active values table
                &:first-child { padding-right: 0.5rem; }
                // Inactive values table
                &:not(:first-child) { padding-left: 0.5rem; }

                // No data in table
                .no-values {
                    margin-top: 1rem;
                    text-align: center;
                }
            }
            & table {
                & .status-column { width: 50px; }

                // First table header
                & thead tr:first-child div {
                    font-size: 1.25rem;
                    text-align: center;
                }
                // Second table header
                & th {
                    // First column
                    &:first-child { padding-left: 0.5rem; width: 50% }
                    // Second column
                    &:nth-child(2) { width: 50%; }
                    // Other columns (including second)
                    &:not(:first-child) { border-left: 1px solid var(--lightGrayBorder); }
                    & .sort-icon { width: 1rem; height:1 rem; }
                }
                // Table cell
                & td {
                    vertical-align: middle;
                    // First column
                    &:first-child { display: flex; padding-bottom: 6px; padding-left: 0.5rem; }
                    // Other columns
                    &:not(:first-child) { border-left: 1px solid var(--lightGrayBorder); }
                    // Drag icon span
                    & .drag {
                        margin: auto 0;

                        cursor: move; /* Fallback if grab cursor is unsupported */
                        cursor: grab;
                        cursor: -moz-grab;
                        cursor: -webkit-grab;
                        &:active {
                            cursor: grabbing;
                            cursor: -moz-grabbing;
                            cursor: -webkit-grabbing;
                        }
                    }
                }
                & div {
                    width: 100%;
                    & input {
                        border: none;
                        border-radius: 0;
                        background-color: transparent;
                        padding-left: 0;
                        padding-right: 0;
                        width: 100%;
                    }
                }
            }
        }

            .cancel-save-picklist {
                width: 100.2%;
                margin-left: -1px;
                background: var(--lightGrayTableHeader);
                padding: 5px 2rem 1rem 0rem;
                border: solid 1px var(--lightGrayBorder);
                border-bottom-left-radius: 0.3rem;
                border-bottom-right-radius: 0.3rem;
                position: sticky !important;
                bottom: 0;
                
                .preview-loader-container {
                display: flex;
                position: relative;
            
                .slds-spinner {
                    position: absolute;
                    left: 0;
                }
            
                .when-pressed {
                    padding-left: 1rem;
                    font-style: italic;
                }
                }
            }
    }

    .not-allowed {
        cursor: not-allowed;
    }

}

.swal_custom_bold {
    font-weight: bold;
}

.error-text {
    color: var(--errorRed);
    font-size: .75rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
        color: var(--primaryDarkGray);
        position: relative;

        .fas {
            margin-left: 5px;
            color: var(--lightGrayScrollTrack);
            font-size: 20px;
            cursor: pointer;
        }

        .popover-body {
            display: inline-block;
            position: absolute;
            width: 70px;
            top: 10px;
            right: 5px;
            background-color: var(--lightGrayScrollTrack);
            padding: 5px;
            z-index: 1;
            border-radius: 5px;
            font-weight: 500;
            text-align: center;
        }
    }
}

.disabled-picklist-wrapper {
    pointer-events: none;
    opacity: 0.5;
}
.picklinst_input-margins {
    margin-bottom: -38px !important;
}
.label-space {
    margin-top: 10px !important;
}
.slds-title-position {
    margin-top: -20px !important;
    margin-bottom: -15px !important;
}
.v2-input-label-style {
    color: #222 !important;
    font-size: 13.5px !important;
}
.v2-input-position {
    color: #222 !important;
    font-size: 13.5px !important;
    margin-bottom: -10px !important;
}
.v2-h1-style {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #333333 !important;
}
.v2-h2-style {
    font-size: 13.5px !important;
    font-weight: 700 !important;
    color: #333333 !important;
}

.active-inactive-container {
  display: flex;
  gap: 1rem;
}

.table-wrapper {
  flex: 1;
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.picklist_container .picklist_wrapper .active-inactive-container .slds-col:first-child {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    padding-bottom: 0rem !important;
}
.picklist-table-width{
    width: 101.8% !important;
    margin-left: -8.5px;
    height: auto !important;
}
.picklist-edit-btn-align {
    margin-right: 50px !important;
    margin-top: 5px !important;
}

.display-colum {
    display: flex;
    flex-direction: column;
}

