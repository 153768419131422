.navigation_bar_waterfall_v2 {
  &_header {
    background-color: transparent;
    padding: .75rem 0;
    padding-top: 2px;
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    align-items: stretch;
    margin: 0 auto;
    background-color: var(--white);
    padding: .35rem 1rem;
    align-items: center;
    padding-right: 0rem;

    .waterfall_title_header {
      color: var(--darkGrayHeader);
      font-size: 1.8rem;
      font-weight: 500;
      display: flex;
      grid-column: 1/8;
    }

    .selection-input {
      grid-column: 8/14;
    }

    .selection_criteriaNavigation {
      grid-column: 14/23;
    }

    .buttons_container {
      grid-column: 24;
      display: flex;
      min-width: 16rem;

      .disabled {
        fill: var(--disabledGray);
      }

      .schedule-btn-container {
        margin: 0 0.25rem;
      }

      #open-schedule-waterfall {
        width: 2rem;
        padding: 16px;
        height: 1rem;
      }

      .active-scheduled-WF-button {
        background-color: var(--greenActive);

        &:hover {
          background-color: var(--primaryGreen);
        }
      }

      #schedule-icon {
        &.schedule {
          fill: var(--darkBlue);
          height: 1.875rem;

          &:hover {
            background-color: var(--lightGrayHover);
          }
        }

        &.schedule-active {
          height: 1.875rem;
          fill: white !important;
        }
      }


      #cancel-waterfall-button {
        .slds-button__icon {
          margin-right: 0.35rem;
          margin-bottom: 0.1rem;
        }

        background-color: var(--primaryBlue);
        color: var(--white);
        border-color: var(--primaryBlue);

        &:hover:not(:disabled) {
          background-color: var(--darkBlue) !important;
        }
      }
    }
  }
}

.des-home-icon-size-v2 {
  cursor: pointer;
  margin-top: 5px;
  width: 20px;
}

.waterfall-selection-name {
  display: flex;
  margin-left: 2rem;
  width: max-content;
}

.waterfall-selection-name-hr {
  margin-bottom: 0;
  margin-top: 3px;
}


.navigation_bar_waterfall_bottom {
  &_header {
    background-color: transparent;
    margin-top: -18px;

    .waterfall-control-btns{
      display: flex;
      &.running-btn-visible{
        margin-left: -2.5rem;
      }
    }
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    background-color: var(--white);
    padding: .35rem 1rem;
    align-items: center;
    width: 98.3%;

    .waterfall_title_header {
      color: var(--darkGrayHeader);
      font-size: 1.8rem;
      font-weight: 500;
      display: flex;
      grid-column: 1/8;
    }

    .selection-input {
      grid-column: 8/14;
    }

    .selection_criteriaNavigation {
      grid-column: 14/23;
    }

    .buttons_container {
      grid-column: 24;
      display: flex;
      min-width: 16rem;
      justify-content: flex-end;

      .disabled {
        fill: var(--disabledGray);
      }

      .schedule-btn-container {
        margin: 0 0.25rem;
      }

      #open-schedule-waterfall {
        width: 2rem;
      }

      #schedule-icon {
        &.schedule {
          background-color: var(--white);
          fill: var(--darkBlue);
          height: 1.875rem;

          &:hover {
            background-color: var(--lightGrayHover);
          }
        }

        &.schedule-active {
          background-color: var(--greenActive);
          height: 1.875rem;

          &:hover {
            background-color: var(--primaryGreen);
            ;
          }
        }
      }

      #cancel-waterfall-button {
        .slds-button__icon {
          margin-right: 0.35rem;
          margin-bottom: 0.1rem;
        }

        background-color: var(--primaryBlue);
        color: var(--white);
        border-color: var(--primaryBlue);

        &:hover:not(:disabled) {
          background-color: var(--darkBlue) !important;
        }
      }
    }
  }
}

.archived-WF-bottom {
  margin-bottom: -47px;
}

.waterfall-controls-style {
  margin-top: 0.8em;
  margin-bottom: 2.5em;
  padding-right: 0.5em;
  display: flex;

  #save-waterfall-button {
    padding-right: 2em;
    padding-left: 2em;
  }

  .mr-1rem {
    margin-left: 1.1rem;
  }

  #next-waterfall-button {
    padding-right: 2em;
    padding-left: 2em;
  }

  #run-waterfall-button {
    padding-right: 2em;
    padding-left: 2em;
  }

  .save-and-run-btns {
    width: 209px;
    margin-left: -14rem;
  }
}

.mb-05rem {
  margin-bottom: 0.4rem;
}

.swal2-container {
  z-index: 9999999999 !important;
}

.is-archived {
  margin-bottom: unset;
}

.selection-home-button-WF {
  background-color: #FFFFFF;
  border: 1px solid #CCC;
  color: #222;
  width: 5.05rem;
  height: 30.13px !important;
  margin-right: -9px !important;
  margin-bottom: -1px !important;
}