.selection_navigation_buttons {
    display: flex;
    grid-column: 19/25;
    justify-content: flex-end;
    position: relative;

    .save-btn-container  {
        margin-right: .2rem;

        .greyed-out {
            color: var(--disabledGrayBlue) !important;
            background-color: var(--white);
        }
    }

    .selection-template-btn-container, .schedule-btn-container {
        &.disabled-overlay{
            .slds-icon_container{
                background-color: var(--disabledOverlayOrange) !important;
            }
            .slds-icon{
                background-color: var(--disabledOverlayOrange) !important;
                opacity: 0.6;
                fill: var(--darkGrayButton) !important;
            }
            .upgrade-badge-icon{
                right: -1em;
                top: -0.8em;
                z-index: 9;
            }
            .tooltip-container{
                position: absolute;
                top: -15px;
                z-index: 99;
                right: -15px;
              }
        }
    }

    #open-schedule-selection, #selection-settings-button {
        margin-right: .2rem;
        width: 2rem;
    }

    #schedule-icon, #selection-settings-icon {
        &.schedule, &.template {
        background-color: var(--white);
        fill: var(--darkBlue);
        height: 1.875rem;

            &:hover {
            background-color: var(--lightGrayHover);
            }
        }

        &.schedule-active, &.template-active {
            background-color: var(--greenActive);
            height: 1.875rem;

            &:hover {
                background-color: var(--primaryGreen);
            }
        }
    }

    .run-btn-container {
        margin-right: .2rem;

        #run-button {
            min-width: 6rem;
            padding: 0 0.6rem;
        }

        .guidance-tip-container{
            position: absolute;
            top: 2.2em;
            right: 6em;
        }
    }

    .cancel-btn-container {
        button {
            .slds-button__icon{
                margin-right: 0.35rem;
                margin-bottom: 0.1rem;
            }
            &.set-dark-color {
                background-color: var(--primaryBlue);
                color: var(--white);
                border-color: var(--primaryBlue);

                &:hover:not(:disabled) {
                    background-color: var(--darkBlue) !important;
                }
            }

            &:disabled {
                background-color:var(--darkGrayButtonDisabled);
            }
        }

        .guidance-tip-container{
            position: absolute;
            top: 2.2em;
            right: -0.5em;
        }
    }



    .hidden {
        display: none;
    }


    .preview-loader-container {
        display: flex;
        position: relative;

        .slds-spinner {
            position: relative;
            left: -0.5rem;
            top: 1rem;
        }
    }
    .when-pressed {
        padding-left: 0.5rem;
    }
}
