.des-lib-custom-select {
    position: relative;
    font-weight: 500;
    width: 100%;
  }
  
  .des-lib-custom-select .selected-option {
    padding: 8px 8px;
    padding-right: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #DDDBDA;
    color: #495D7A;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--primaryFont);
    font-weight: 500;
    word-wrap: break-word;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  
  .des-lib-custom-select .placeholder {
    color: #495D7A;
    font-weight: 500;
  }
  
  .des-lib-custom-select .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px; /* Set maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    background: #FFF;
    border: 1px solid #DDDBDA;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  span.other-options {
    padding: 10px 1px;
    color: #495D7A;
    font-size: 14px;
    font-family: var(--primaryFont);
    font-weight: 500;
    word-wrap: break-word;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span.button-options {
    padding: 10px 1px;
    color: #0176D3;
    font-size: 14px;
    font-family: var(--primaryFont);
    font-weight: 500;
    word-wrap: break-word;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .des-lib-custom-select .selected-option .option:hover {
    background-color: #FFF;
  }

  .des-lib-custom-select .option:hover {
    background-color: #EFF2F7;
  }
  
  .des-lib-custom-select .option img {
    margin-right: 8px;
  }
  
  .des-lib-custom-select .button-option {
    background: #fff;
    border-top: 1px solid #DDDBDA;
  }
  
  .des-lib-custom-select .button-option:hover {
    background-color: #F4F7FB;
  }
  .des-lib-custom-select .option__content {
    display: flex;
    margin-left: 5px;
  }