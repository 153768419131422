.selected-extension-graph {
  position: relative;
  height: 44.9rem;
  border-left: 1px solid #D3D3D3;

  .react-flow__attribution.right {
    display: none;
  }

  /**
    * Data Extension Node Styles
    */

  .data-extension-node_wrapper {
    &.drag-over {
      border: 1px dotted #0176D3;
      border-radius: 8px;
      padding: 10px;
    }

    .data-extension-node {
      background-color: #fff;
      border: 1px solid #D3D3D3;
      border-radius: 7px;
      box-shadow: 0 2px 2px 0 #D3D3D3;
      width: auto;
      padding: 0.5em 1em;

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.3em 0.5em;

        img {
          margin-right: 0.3em;
        }
      }

      &__controls {
        display: flex;
        align-items: center;
        margin-top: 1em;
        margin-left: -1em;
        position: absolute;
        width: 100%;
        justify-content: center;

        button {
          display: block;
          color: var(--primaryBlue);
          border: 1px solid var(--primaryBlue);
          border-radius: 50%;
          padding: 0.5em;

          svg {
            font-weight: 900;
            width: 1em;
            height: 1em;
          }

          &.active {
            border-width: 2px;
            background-color: var(--lightGrayishBlueHover);
          }
        }
      }

      &.start-here {
        color: var(--primaryBlue);
        border-radius: 40px;
        border: 1px solid var(--primaryBlue);
      }
    }

    .data-extension-node__actions-modal {
      background-color: #fff;
      border: 1px solid #D3D3D3;
      border-radius: 7px;
      box-shadow: 0 2px 2px 0 #D3D3D3;
      position: absolute;
      z-index: 100;
      right: -210px;
      top: 0px;
      z-index: 2;
      width: 200px;

      &__actions {
        .action-item {
          padding: 0.8em 1em;
          cursor: pointer;

          &:hover {
            background-color: #F4F7FB;
          }

          .slds-icon {
            margin-right: 1em;
          }

          &.delete {
            color: var(--primaryRed);
          }
        }
      }
    }

    .data-extension-node__add-modal {
      background-color: #fff;
      border: 1px solid #D3D3D3;
      border-radius: 7px;
      box-shadow: 0 2px 2px 0 #D3D3D3;
      position: absolute;
      right: -310px;
      top: 0px;
      z-index: 2;
      width: 310px;
      max-height: 300px;
      overflow-y: hidden;
      overflow-x: hidden;

      &-title {
        padding: 0.7em 0.5em;
        text-align: left;
        font-weight: 600;
        color: #495D7A;
      }

      &-input {
        position: sticky;
        top: 0;
        /* Set the position to be 0px from the top */
        z-index: 10;
        background-color: white;
        padding-top: 1rem;

        /* Ensure it sits above other content */
        input {
          width: 18.5em;
          margin: 0.2em auto;
          display: block;
          background-color: #F4F7FB;
          margin-left: 0.1em;
          margin-right: 0.1em;
        }
      }

      &__list {
        overflow-y: auto;
        max-height: 204px;

        .add-item {
          padding: 0.4em 0.1em;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            background-color: #F4F7FB;
          }

          img {
            margin-right: 0.5em;
          }
        }
      }

      .DE-data-sets-btns {
        margin-bottom: 1rem;
        margin-left: 0.1em;
        margin-right: 0.1em;
      }
    }
  }


  /**
    * Relationship Node Styles
    */

  .relationship-node {
    background-color: #fff;
    border: 1px solid #D3D3D3;
    border-radius: 40px;
    box-shadow: 0 2px 2px 0 #D3D3D3;
    width: auto;
    padding: 0.5em 1em;

    .slds-badge {
      display: inline;
      position: absolute;
      right: 0;
      top: -10px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        margin-right: 0.3em;
      }

      &__pill {
        background-color: #F4F7FB;
        border-radius: 40px;
        padding: 0.3em 0.3em;
        margin-left: 0.5em;
        border: 1px solid #DDDBDA;
        display: flex;
        padding-right: 1.4rem;
        text-wrap: nowrap;
      }

      &__join-type {
        width: 100%;

        .join-type-select {
          width: 85%;
          border: 1px;
          padding-bottom: 3px;
          height: 2rem;
        }

        .join-type-select:focus {
          box-shadow: unset !important;
        }

        .join-type-field {
          margin-left: unset;
          width: 98.9%;
          display: block;
          padding-right: 0px;

          .join-type-input-container {
            display: flex;
            border: 1px solid #DDDBDA;
            border-radius: 40px;
            padding-left: 1rem;

            img {
              margin-top: 2px;
            }
          }

          .join-type-dropdown {
            width: 96% !important;

            .slds-select_container:after,
            .slds-select_container:before {
              right: 0.7rem;
            }
          }
        }

        img {
          height: 27px;
          width: auto;
        }

        .cross-join-img {
          height: 19px;
          margin-top: 6px !important;
        }

        span {
          color: #495D7A
        }
      }
    }
  }

  .relation-node-modal {
    margin-top: 0.3em;
    background-color: #FFFFFF;
    border: 1px solid #DDDBDA;
    padding: 3px 0px 3px 3px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #D3D3D3;
    position: absolute;
    width: max-content;

    &-div {
      max-height: 500px;
      overflow-y: auto;
      margin-right: 0.2rem;
    }

    &-header {
      position: sticky;
      /* Make the header stick to the top */
      top: 0;
      /* Set the position to be 0px from the top */
      z-index: 10;
      /* Ensure it sits above other content */
      background-color: white;
      /* Match the background with the modal */
      align-items: center;

      .header-div {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid #DDDBDA;
      }

      &-add-relation {
        button {
          display: block;
          color: var(--primaryBlue);
          border: none;
          padding-right: 0.5rem;

          svg {
            font-weight: 900;
          }
        }
      }

      &-title {
        padding-left: 0.5rem;
      }
    }

    .relation-node-join {
      padding: 0.5em 0.5em;
      border-bottom: 1px solid #DDDBDA;

      .relation-node-modal-delete-relation {
        display: flex;
        justify-content: flex-end;

        button {
          color: var(--primaryRed);
          border: none;
          display: block;
          margin-bottom: 0.5em;
          padding: 0;

          svg {
            font-weight: 900;
          }
        }
      }
    }

    &-to {
      padding-top: 0.5rem;
      margin-bottom: -0.5rem;
    }

    &-from,
    &-to {
      min-height: 3rem;
    }

    &-from,
    &-to,
    &-join {
      display: flex;
      align-items: center;

      .source {
        width: 50%;

        .de-name {
          margin-top: -1rem;
        }
      }

      .to-from-field-dropdown {
        margin-top: 0.5rem;
      }

      .source,
      .field {
        img {
          margin-right: 0.2em;
        }

        .field-select {
          border: 1px;
          height: 2rem;
        }
      }


      .source,
      .source-div,
      .source-div p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 170px;

        p {
          width: 142px;
          margin-left: 1rem;
          height: 1.5rem;
        }

        img {
          top: 0.5rem;
          position: sticky;
        }
      }

      .to-from-field-dropdown {
        .field {
          border-radius: 40px !important;
          padding: 0.3em 0.8em;
          border: 1px solid #DDDBDA;
          margin-left: 4px;
          display: flex;
        }

        .toAndFromField {
          height: 2rem;
          display: block;
          padding: 0.78571429em 2.1em 0.78571429em 1em;
          margin-right: 0;
          padding-left: 2rem;
        }

        .virtualized-dropdown {
          width: unset !important;
          position: unset !important;

          .virtualized-list {
            max-height: 17rem;

            .slds-listbox__option {
              width: 99% !important;
              align-items: center;

              .item {
                overflow: unset;
              }
            }

            .slds-listbox__option:hover {
              background-color: #F4F7FB;
            }
          }

          .db-img {
            margin-top: 0rem;
            position: absolute;
            left: 1rem;
            top: 0.5rem;
          }
        }
      }

    }

    .relationship-node__container__join-type {
      margin: 1em 0;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;
      padding-right: 0.5rem;
      padding-bottom: 0.5rem;

      button {
        margin-left: 1em;
      }
    }
  }
}


.selected-extension-v2 {
  min-height: 8rem;
  background-color: #F4F7FB;
  height: 96.6%;
  padding: 3rem;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "SF Pro", Arial, sans-serif !important;
  flex: 1 1 auto;

  .section-title {
    white-space: nowrap;
    padding: .8rem 0;
    color: var(--blackTitle);
    border-radius: .2rem;
    font-weight: 600;
  }

  .height-95 {
    height: 95%;
  }

  .height-100 {
    height: 100%;
  }

  &_wrapper {
    position: relative;
    overflow: hidden;
  }

  .drag-and-drop-text {
    color: var(--grayBorder);
    letter-spacing: .1rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-top: 1rem;
    line-height: 3rem;
  }
}

@keyframes smooth-disappear {
  from {
    visibility: unset;
  }

  to {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.hide-data-extension {
  animation: smooth-disappear 2s ease-in forwards;
  animation-delay: 0.7s;
}

.hide-data-extension-immediately {
  visibility: hidden;
  opacity: 0;
  display: none;
}

@keyframes smooth-appear-DEs {
  from {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  to {
    visibility: unset;
  }
}

.show-data-extension {
  animation: smooth-appear-DEs 1.7s ease-in forwards;
  animation-delay: 0s;
}