#container-data-retention-policy {
  position: relative;

  .disable-to-update {

    label,
    select,
    .slds-checkbox_faux {
      cursor: not-allowed !important;
    }

    input:focus+.slds-radio__label .slds-radio_faux,
    input:focus+.slds-checkbox__label .slds-checkbox_faux,
    input:focus+.slds-checkbox_faux_container .slds-checkbox_faux {
      border: 1px solid var(--lightGrayBorder);
      box-shadow: none;
    }

    input:disabled+.slds-checkbox_faux_container .slds-checkbox_faux {
      border-color: var(--disabledGrayText);
    }
  }

  // PART TWO
  .DRP-part-two {
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;

    .retention-settings-container {
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--color-stroke, #DDDBDA);
      background: #FFF;
      text-align: left;

      fieldset {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }

      .after-period-container {
        .group-slds-after {
          display: flex;
          align-items: center;
          gap: 8px;

          .slds-form-element__control {
            width: unset;

            .after-number-input {
              margin-left: 21px !important;
              font-size: 1rem
            }

            .after-period-select {
              width: 94px;
            }
          }
        }
      }

      .mb-8px {
        margin-bottom: 8px;
      }

      .delete-container {
        .slds-form-element__control {
          gap: 8px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    // Date picker
    .react-datepicker-wrapper {
      div {
        input {
          width: 11.4rem;
          font-size: 1rem !important;
        }
      }
    }

    // date picker (when it shows up)
    .react-datepicker-popper {
      left: 10rem !important;
      position: absolute !important;
      z-index: 99999999999999999 !important;
      will-change: transform !important;
      top: -7rem !important;
      transform: translate3d(0px, -175px, 0px) !important;

      div {
        .react-datepicker {
          .react-datepicker__month-container {
            .react-datepicker__header {
              .react-datepicker__current-month {
                margin-top: .3rem;
              }

              .react-datepicker__day-names {
                margin: .2rem 0;
              }
            }
          }
        }
      }
    }
  }
}

.DRP-footer {
  button:nth-child(2) {
    margin-left: .5rem !important;
  }
}

.DRP-backgroundTransparent {
  background: transparent;
}