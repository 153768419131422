.collection-label-container {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;

  .collection-name {
    flex: 1 1;
    word-break: break-word;
    line-height: 1.1;
  }

  .collection-name>p {
    line-height: initial;
  }
}

.data-extension-name {
  display: flex;
}

.drag-field {
  padding: .5rem;
  cursor: grab;
  margin: .2rem;
  border-radius: .25rem;
  border: 1px solid var(--lightGrayColdBorder);
  background-color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.encrypted {
    cursor: default;

    &:active {
      cursor: default;
    }
  }

  &:hover {
    background-color: var(--lightGrayishBlueHover) !important;
    color: var(--darkDesaturatedBlue);
  }

  &:active {
    cursor: grabbing;
  }

  &.available-field {
    display: flex;
    justify-content: space-between;

    .encrypted-lock-icon {
      color: var(--darkGray);
    }
  }
}

.custom-values-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &>span {
    height: 1.2rem;
  }
}

.custom-field-info-button {
  margin-right: 7px;
  margin-top: -3px;
}

.add-new-value-button-container {
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
}

.filter-sets-message {
  padding: 1rem;
  font-size: 1rem;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24%;
}