.available-selection-card-v2 {
    padding: .5rem;
    cursor: -webkit-grab;
    cursor: grab;
    margin: .2rem;
    border-radius: .25rem;
    border: 1px solid var(--lightGrayColdBorder);
    background-color: var(--white);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 15px;
    width: 98%;

    .selection-name-icon{
        margin-right: 10px;
    }

    &.disabled, &.disabled:hover {
        background-color: var(--modalGrayBackground);
        color: var(--grayBorder);
        cursor: default;
        display: flex;

        .slds-button__icon {
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;
        }
    }

    &:hover, &.isDragging {
         background-color: var(--lightGrayishBlueHover);
         color: var(--darkDesaturatedBlue);
    }

    &.copy {
        ~ div {
            transform: none !important;
        }
    }

    &.isDragging {
        cursor: grabbing;
    }
}
