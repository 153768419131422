.selection-list-column-new {
  min-width: 21rem;
  max-width: 21rem;

  .available-selections_count {
    color: #495D7A;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    margin-left: 0.2rem;
  }

  .available-selection_input_wrapper {
    display: flex;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-width: thin;
    overflow: hidden;
    padding: 0.03rem;
    cursor: grab;
    margin: 0.2rem;
    border-radius: 0.25rem;
    border: 1px solid var(--lightGrayColdBorder);
    background-color: var(--white);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 0.4rem;
    width: auto;

    .slds-form-element {
      width: 100%;
    }

    input {
      width: 100%;
      min-width: 0;
      border: none;
      border-top-left-radius: 0.25rem;

      &::placeholder {
        color: var(--black);
      }

      &:focus {
        outline-style: none;
        box-shadow: none;
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: 0.7rem 0.8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .make-space-for-scroll {
    height: calc(100vh - 11.8rem);
    position: relative;
    margin-right: 0.3rem;

    .pagination_container {
      background: transparent;
      position: absolute;
      bottom: 0;
    }

    .selections-container {
      height: calc(100vh - 14.8rem);
      overflow-y: auto;
      scrollbar-gutter: stable both-edges;
      margin-left: auto;
    }
  }

  .available_selection_column {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 14rem);
  }

  .disabled-selection-information {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-subtle, #747474);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
      fill: var(--grayBorder);
    }
  }
}

.create-new-selection-div {
  text-align: end;
  padding: 5px;
}