.row-number-wrapper {
    &__title{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__description{
        font-size: 15px;
        font-weight: normal;
        color: var(--grayText);
        margin-bottom: 2rem;
    }
    .dropdown-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.5rem 0;

        .item-label {
            color: var(--black);
            flex-basis: 25% !important;
        }

        .dropdown-field {
            flex-basis: 75% !important;
            .target-data-extension-dropdown {
                width: 100% !important;
                .text {
                    width: 90%;
                }
            }

            &.data_extension_field {
                .menu {
                    max-height: 7.5rem;
                }
            }

            &.data_extension {
                .menu {
                    max-height: 10.5rem;
                }
            }
        }
    }

    .subtitle {
        padding-bottom: 1rem;
        padding-left: 0.5rem;
    }

    .configuration-description {
        padding-top: 1rem;
        line-height: 1.2;
        min-height: 3.5rem;

        strong {
            font-weight: 600;
        }
    }
}
